import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {toInteger} from "lodash";

const SliderQuestionType = ({setAdditionalFields, questionObjectToEdit}) => {
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(100);

  useEffect(() => {
    setAdditionalFields({from: from, to: to})
  }, [from, to])

  useEffect(() => {
    if (questionObjectToEdit) {
      questionObjectToEdit.from && setFrom(toInteger(questionObjectToEdit.from))
      questionObjectToEdit.to && setTo(toInteger(questionObjectToEdit.to))
    }
  }, [questionObjectToEdit])

  return (
    <Grid container spacing={2} sx={{marginTop: 2}}>
      <Grid item>
        <TextField
          id="outlined-number"
          label="From"
          type="number"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          value={from}
          onChange={(e) => setFrom(e.target.value)}
        />
      </Grid>

      <Grid item>
        <TextField
          id="outlined-number"
          label="To"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          value={to}
          onChange={(e) => setTo(e.target.value)}
        />

      </Grid>
    </Grid>
  );
};

export default SliderQuestionType;
