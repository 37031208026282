import React from "react";
import { grey } from "@mui/material/colors";
import { Typography } from "@mui/material";

export const TitleDate = ({ title, date, className, big, small }) => {
  return (
    <div
      className={`title-date-wrap ${className && className} ${big ? "page-title" : "small-title"}`}
    >
      <Typography component={"span"} variant={"h3"} fontWeight={"bold"}>
        {title}
      </Typography>
      <Typography
        component={"span"}
        variant={"bodyNormal"}
        sx={{ color: "#9A9A9A", marginLeft: "8px" }}
      >
        {date && big && ` as of ${new Date(date).toLocaleDateString()}`}
      </Typography>
      <Typography component={"span"} variant={"subtitle2"} color={"#9A9A9A"}>
        {date &&
          small &&
          ` Launched on  ${new Date(date).toLocaleDateString()}`}
      </Typography>
      {/*<p>{title} {(date && big) && <span className='title-date-date'>as of {date}</span>}</p>*/}
      {/*{((date && small) && <p className='title-date-date'>{date}</p>)}*/}
    </div>
  );
};
