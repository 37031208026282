import { Button, Grid, Stack, Typography } from "@mui/material";
import { TitleDate } from "./text-formating/title-date/title-date";
import Box from "@mui/material/Box";
import { ProjectItem } from "../../pages/projects-list-page/components/project-list-item/ProjectItem";
import { green } from "@mui/material/colors";
import React from "react";

const SurveyList = ({ surveys, viewAllProjects, setViewAllProjects }) => {
  const shortProjectsList = surveys.slice(0, 10);

  return (
    <>
      <Stack
        direction={"row"}
        gap={1}
        alignItems={"center"}
        id={"survey-stack"}
      >
        <TitleDate title="My Surveys" />
        {/*<FormControl size={"small"}>*/}
        {/*    <InputLabel id="demo-simple-select-label">Sort by</InputLabel>*/}
        {/*    <Select*/}
        {/*        labelId="demo-simple-select-label"*/}
        {/*        id="demo-simple-select"*/}
        {/*        value={sortSelect}*/}
        {/*        label="Sort by"*/}
        {/*        onChange={(event) => setSortSelect(event.target.value)}*/}
        {/*    >*/}
        {/*        <MenuItem value={1}>Date</MenuItem>*/}
        {/*        <MenuItem value={2}>Name</MenuItem>*/}
        {/*    </Select>*/}
        {/*</FormControl>*/}
      </Stack>

      {!surveys ||
        (surveys.length === 0 && (
          <Box>
            <Typography variant={"h4"} sx={{ marginTop: 5 }}>
              You don't have any surveys yet...
            </Typography>
            {/*<Button className='create-survey-button' variant="contained" color='confirmation'*/}
            {/*        sx={{marginTop: 5}}*/}
            {/*        onClick={() => {*/}
            {/*            navigate('/')*/}
            {/*            dispatch({viewToShow: enums.CREATESURVEY})*/}
            {/*        }}>Create Survey</Button>*/}
          </Box>
        ))}
      <Stack
        direction={"column"}
        gap={4}
        sx={{ my: 2, width: "100%", marginTop: "30px" }}
      >
        {viewAllProjects
          ? surveys
              .sort((a, b) => b.dateCreated - a.dateCreated)
              .map((survey) => <ProjectItem key={survey.uid} survey={survey} />)
          : shortProjectsList
              .sort((a, b) => b.dateCreated - a.dateCreated)
              .map((survey, index) => (
                <ProjectItem key={survey.uid} survey={survey} />
              ))}
      </Stack>

      {surveys && surveys.length > 10 && (
        <Stack
          direction={"row"}
          justifyContent={"center"}
          sx={{ width: "100%" }}
        >
          <Button
            variant={"contained"}
            color={"success"}
            sx={{
              minWidth: "200px",
              marginTop: "50px",
              bgcolor: green[500],
              "&:hover": {
                bgcolor: green[600],
              },
            }}
            onClick={() => setViewAllProjects(!viewAllProjects)}
          >
            {viewAllProjects ? "View Less" : "View More"}
          </Button>
        </Stack>
      )}
    </>
  );
};

export default SurveyList;
