import React, {createContext, useEffect, useReducer} from 'react'
import {enums} from "./enums";
import {getAuth} from "firebase/auth";
import {getBlankSurveyObject} from "./sharedFunctions";
import {useAuthContext} from "../contexts/auth_context";
import {collection, getDocs, orderBy, query, where} from "firebase/firestore";
import {db} from "./firebaseApp";

export const AppContext = createContext();

let startMessageStack = [
  {
    "role": "system",
    "content": "You are a world expert on creating surveys and surveying people on every topic. You are to help Ella design the best possible survey for the given task."
  },
  {
    "role": "system",
    "content": "you have the following question types at your disposal: " + Object.keys(enums)
  }, {
    "role": "system",
    "content": "when answering the question 'can you provide me with a list of questions?' always provide a question type that's best suited for the question you're giving from the list you've been given"
  },
]

const initialState = {
  viewToShow: enums.DASHBOARD,
  surveyObject: getBlankSurveyObject('auth.currentUser.uid'),
  surveyObjectToDisplay: null,
  surveys: [],
  questionObjects: [],
  answerObjects: [],
  questionObjectToEdit: null,
  newSurvey:0,
  tokens:[],
  reports: [],
  messages:startMessageStack
}



const reducer = (state, action) => {
  return {...state, ...action}
};

export const AppContextProvider = props => {
  const {user} = useAuthContext();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [user])

  const fetchData = async () => {
    try {
      console.log("fetch data..", user)
      const currentUser = user;
      dispatch({currentUser: currentUser});

      const questionsQuery = query(
        collection(db, "questions"),
        where("userUid", "==", currentUser.uid),
      );
      const questionSnapshot = await getDocs(questionsQuery);

      const reportsQuery = query(
        collection(db, "reports"),
        where("userUid", "==", currentUser.uid),
      );
      const reportsSnapshot = await getDocs(reportsQuery);

      const surveyQuery = query(
        collection(db, "surveys"),
        where("userUid", "==", currentUser.uid),
      );
      const surveySnapshot = await getDocs(surveyQuery);

      const answerQuery = query(
        collection(db, "answers"),
        where("userUid", "==", currentUser.uid),
        orderBy("timestamp"),
      );
      const answerSnapshot = await getDocs(answerQuery);

      let surveys = [];
      let questionObjects = [];
      let answerObjects = [];
      let reports = [];

      questionSnapshot.forEach((doc) => {
        questionObjects.push(doc.data());
      });
      reportsSnapshot.forEach((doc) => {
        reports.push(doc.data());
      });
      surveySnapshot.forEach((doc) => {
        surveys.push(doc.data());
      });
      answerSnapshot.forEach((doc) => {
        answerObjects.push(doc.data());
      });
      dispatch({surveys: surveys?.sort((a, b) => b.dateCreated - a.dateCreated)});
      dispatch({reports: reports});
      dispatch({questionObjects: questionObjects});
      dispatch({questionObjectsPersistent: questionObjects}); // TODO: this is really uncessary but atm it's needed because create survey page uses the same questionObjects and it has to erase them
      dispatch({answerObjects: answerObjects});

    } catch (e) {
      console.log(e)
    }
  }

  return (
    <AppContext.Provider value={{state, dispatch}}>
      {props.children}
    </AppContext.Provider>
  )
};
