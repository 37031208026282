import React, { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import _ from "lodash";

export const TotalParticipantChart = ({ name, xAxisData, xAxisLabels }) => {
  const [state, setState] = useState({
    series: [
      {
        name: "Number of responses",
        data: xAxisData || [],
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        categories: xAxisLabels || [],
      },
      yaxis: {
        max: Math.max(...xAxisData) + 3,
        decimalsInFloat: 0,
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
      },
      tooltip: {
        x: {
          format: "dd/MM/yy",
        },
      },
    },
  });
  useEffect(() => {
    const newState = _.cloneDeep(state);
    newState.series[0].data = xAxisData || [];
    newState.options.xaxis.categories = xAxisLabels || [];
    setState(newState);
  }, [xAxisLabels, xAxisData]);

  return (
    <Box>
      <Stack
        direction={"row"}
        sx={{
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "flex-start", md: "center" },
        }}
        justifyContent={"space-between"}
        gap={1}
      >
        <Typography variant={"h4"}>{name}</Typography>
      </Stack>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="area"
        height={350}
      />
    </Box>
  );
};
