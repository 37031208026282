import React, {useEffect, useState} from "react";
import ReactApexChart from "react-apexcharts";

export const ColumnChart = ({open, options}) => {
  const [state, setState] = useState({
    series: [{
      name: 'Value',
      data: []
    }],
    options: {
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },
      xaxis: {
        categories: [],
        position: 'top',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            }
          }
        },
        tooltip: {
          enabled: false,
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        }

      }
    },
  });
  useEffect(() => {
    setState((prevState) => {
      return {
        ...prevState,
        series: [{name: "Value", data: Object.values(options)}],
        options: {
          ...prevState.options,
          xaxis: {
            ...prevState.options.xaxis,
            categories: Object.keys(options)
          },
        }
      }
    })
  }, [options, open])

  return (
    open && <ReactApexChart options={state.options} series={state.series} type="bar" height={350}/>
  )
}
