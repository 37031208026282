import React, {useEffect, useState} from "react";
import {Box, Button, Divider, Stack, Typography, useTheme,} from "@mui/material";
import {enums} from "../../../utils/enums";
import axios from "axios";
import {toast} from "react-toastify";
import {doc, writeBatch} from "firebase/firestore";
import {db} from "../../../utils/firebaseApp";
import {Response} from "./Response";


export const Responses = ({responses, question}) => {
    const theme = useTheme();
    const [sortBy, setSortBy] = useState(["timestamp"]);
    const [sentiment, setSentiment] = useState([]);
    const [filteredResponses, setFilteredResponses] = useState([]);

    useEffect(() => {
        if (responses) {
            setSortBy(["timestamp"]);
            setSentiment([]);
            filterData(responses);
        }
    }, [responses]);

    const filterData = (items) => {
        if (sentiment?.length > 0) {
            // TODO (Here we need to make a filter function)
        }
        if (sortBy?.length > 0) {
            //TODO (sort function)
        }
        console.log(items)
        setFilteredResponses(items);
    };

    const handleChange = (event) => {
        const {
            target: {value},
        } = event;
        setSortBy(
            typeof value === "string" ? value.split(",") : value,
        );
    };

    const handleSentimentChange = (event) => {
        const {
            target: {value},
        } = event;
        setSentiment(
            typeof value === "string" ? value.split(",") : value,
        );
    };

    async function getTopicsAndClassifyAnswers() {
        toast.info('Classifying answers by topic using AI')
        let topics;

        const createTopicsUrl = process.env.REACT_APP_RENDER_URL + 'create_topics';
        const classifyResponsesUrl = process.env.REACT_APP_RENDER_URL + 'classify_responses';
        const bearerToken = process.env.REACT_APP_RENDER_TOKEN;

        try {
            const response = await axios.post(createTopicsUrl, {
                survey_responses: filteredResponses.map((response) => response.text),
                num_topics: 5
            }, {
                headers: {
                    'Authorization': `Bearer ${bearerToken}`
                }
            });

            topics = response.data;

            const classifyResponses = await axios.post(classifyResponsesUrl, {
                survey_responses: filteredResponses.map((response) => response.text),
                topics_list: topics
            }, {
                headers: {
                    'Authorization': `Bearer ${bearerToken}`
                }
            });

            const classifiedResponses = classifyResponses.data;
            filteredResponses.forEach((response, index) => {
                response.topics = classifiedResponses[index];
            });
            setFilteredResponses([...filteredResponses])

            const batch = writeBatch(db);
            filteredResponses.forEach((response) => {
                const responseRef = doc(db, "answers", response.uid);
                batch.update(responseRef, {topics: response.topics});
            })
            await batch.commit();

        } catch (error) {
            console.log(error);
        }
    }

    return (
        question
        && question?.type
        && [enums.TEXT, enums.VOICE].includes(question.type)
        && (
            <Box>
                <Box sx={{pb: 2}}>
                    <Typography variant={'h4'}>Analyze answers</Typography>
                    <Button variant={'contained'} sx={{width: '220px', mt: 2}}
                            onClick={() => getTopicsAndClassifyAnswers()}>Classify by topic using AI</Button>
                </Box>
                <Divider/>

                <Stack direction={"column"} gap={2}>
                    {filteredResponses?.map((filteredResponse) => (
                        <Response response={filteredResponse}/>
                    ))}
                </Stack>
            </Box>
        )
    );
};
