import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {Box, Chip, IconButton, Stack, Typography, useTheme} from "@mui/material";
import moment from "moment/moment";
import AbcIcon from "@mui/icons-material/Abc";
import {grey} from "@mui/material/colors";
import LanguageIcon from "@mui/icons-material/Language";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import {playAudio} from "../../../utils/sharedFunctions";
import {EditableTextField} from "./EditableTextField";
import {doc, updateDoc, writeBatch} from "firebase/firestore";
import {db} from "../../../utils/firebaseApp";
import {toast} from "react-toastify";
import {AppContext} from "../../../utils/AppContext";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {confirm} from "react-confirm-box";
import {confirmDialogOptions} from "../../../components/ConfirmDialog";

export const Response = ({response}) => {
    const theme = useTheme()
    const {state: {answerObjects}, dispatch} = useContext(AppContext);

    async function updateAnswer(text, type) {
        toast.info("Updating answer")
        try {
            switch (type) {
                case "text":
                    await updateDoc(doc(db, "answers", response.uid), {text: text});
                    answerObjects.forEach((answer) => {
                        if (answer.uid === response.uid) {
                            answer.text = text;
                        }
                    })
                    break;
                case "translation":
                    await updateDoc(doc(db, "answers", response.uid), {translation: text});
                    answerObjects.forEach((answer) => {
                        if (answer.uid === response.uid) {
                            answer.translation = text;
                        }
                    })
                    break;
            }

            dispatch({answerObjects: answerObjects})
            toast.success("Answer updated")
        } catch (e) {
            toast.error("Error updating answer")
        }
    }

    async function removeResponse(uid) {
        toast.info("Removing response")
        const response = await confirm(
            {
                title: `Are you sure you want to delete this answer?`,
                subtitle: `This action will delete .`,
            },
            confirmDialogOptions,
        );
        if (response === false) {
            return;
        }

        const batch = writeBatch(db);
        const responseRef = doc(db, "answers", uid);
        batch.delete(responseRef);
        batch.commit().then(() => {
            const newAnswers = answerObjects.filter((answer) => answer.uid !== uid);
            dispatch({answerObjects: newAnswers})
            toast.success("Response removed")
        }).catch((e) => {
            toast.error("Error removing response")
        })
    }

    return (
        <Box sx={{borderBottom: 1, borderColor: "divider", py: 2}} key={response.uid}>
            <Stack
                direction={"row"}
                sx={{
                    flexDirection: {xs: "column", md: "row"},
                    justifyContent: {xs: "flex-start", md: "space-between"},
                    alignItems: {xs: "flex-start", md: "center"},
                }}
                gap={1}
            >
                <Typography variant={"body1"}>
                    {/*November 10, 2023*/}
                    {moment(response?.timestamp).format("MMMM DD, yyyy hh:mm")}
                </Typography>

                <Stack direction={"row"}>
                    {response?.topic && response?.topic !== "" && (
                        <Chip
                            icon={<AbcIcon fontSize={"small"}/>}
                            label={response?.topic}
                            sx={{bgcolor: grey[200], color: grey[700]}}
                        />
                    )}
                    {response.voiceUrl
                        && <IconButton onClick={() => playAudio(response.voiceUrl)}
                                       sx={{color: theme.palette.azure.main}}>
                            <PlayCircleIcon
                                fontSize={"small"}/></IconButton>
                    }



                    <Chip
                        icon={<LanguageIcon color={"#888888"}/>}
                        label={response?.languageObject?.Native}
                        sx={{
                            bgcolor: "#ffffff",
                            color: grey[700],
                            "& .MuiChip-label": {
                                // Corrected: Use space to target descendant
                                color: "#888888", // This sets the text color
                                fontSize: "0.86em", // This sets the font size
                            },
                            "& .MuiChip-icon": {
                                // Corrected: Use space to target descendant
                                color: "#888888", // This sets the text color
                                fontSize: "20px",
                            },
                        }}
                    />

                    <IconButton onClick={() => removeResponse(response.uid)}
                                sx={{color: theme.palette.error.main}}>
                        <DeleteForeverIcon fontSize={"small"}/>
                    </IconButton>

                </Stack>
            </Stack>

            <EditableTextField initialText={response?.text} onSave={updateAnswer} type={'text'}/>
            <EditableTextField initialText={response?.translation
                || <em>{response.languageObject.BCP47 === "en-US" ? null : "No translation for this response"}</em>}
                               type={'translation'}
                               onSave={updateAnswer}
            />

            {response?.topics && response?.topics.map((topic) => (
                <Chip label={topic} sx={{bgcolor: grey[200], color: grey[700], mr: 1}}/>
            ))}

        </Box>
    );
}

Response.propTypes = {
    response: PropTypes.object.isRequired,
};

export default Response;
