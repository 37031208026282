import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {IconButton, Stack, TextField, Typography} from "@mui/material";
import axios from "axios";
import SendIcon from '@mui/icons-material/Send';
import {toast} from "react-toastify";
import styled from 'styled-components';
import ellaLogo from '../../assets/logo/ella-short.png'
import personIcon1 from '../../assets/logo/person-icon.png'
import loadingGif from '../../assets/login/loading.webp'
import {useDidUpdate} from "../../utils/useDidUpdate";
import useImagePreloader from "../../utils/usePreloadImage";
import {AppContext} from "../../utils/AppContext";
import Box from "@mui/material/Box";

const ChatBubble = styled.div`
  display: flex;
  flex-direction: row; /* Row orientation */
  align-items: flex-start; /* Align children vertically center */
  max-width: 100%;
  color: ${({role}) => (role === 'user' ? '#3498db' : '#2ecc71')};
  padding-bottom: 10px;
  white-space: pre-line;
`;


const ChatWindow = () => {

    // const [messages, setMessages] = useState();
    const [isAnswering, setIsAnswering] = useState(false);
    const [userQuery, setUserQuery] = useState('');
    const [chatGptUserQuery, setChatGptUserQuery] = useState(null);
    const bottomRef = useRef(null);
    const {state:{messages}, dispatch} = useContext(AppContext)
    useImagePreloader([ellaLogo, personIcon1, loadingGif])

    useDidUpdate(() => {
        if (bottomRef.current && messages.length > 0) {
            bottomRef.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [messages]);

    useEffect(() => {
        async function getMessages() {
            setIsAnswering(true)
            axios({
                method: "POST",
                url: process.env.REACT_APP_SERVER_URL + 'getChatCompletion',
                data: {
                    messages: messages,
                }
            }).then((response) => {
                if (response.status === 200) {
                    messages.push(response.data.message)
                    dispatch({messages: [...messages]})
                    setChatGptUserQuery(null)
                    setIsAnswering(false)
                    return response.data
                } else {
                    setIsAnswering(false)
                    toast.error('Something went wrong please try again!')
                }
            }).catch((error) => {
                console.log(error)
                setIsAnswering(false)
            })
        }

        if (!chatGptUserQuery) return
        messages.push(chatGptUserQuery)
        dispatch({messages: [...messages]})
        setUserQuery('')
        getMessages()

    }, [chatGptUserQuery])

    function renderBoldText(text) {
        // Regular expression to match words wrapped in ** (e.g., **word**)
        const regex = /\*\*(.*?)\*\*/g;

        // Replace matched patterns with <strong> tags
        const boldText = text.replace(regex, '<strong>$1</strong>');

        return <span dangerouslySetInnerHTML={{__html: boldText}}/>;
    }

    const getMessageCard = useCallback((message) => {
        switch (message.role) {
            case 'user':
                return (
                    <ChatBubble role={message.role} key={message.content}>
                        <img src={personIcon1} style={{height: '40px', paddingRight: 10, paddingLeft: 2}}/>
                        <Typography variant={'body1'}
                                    sx={{
                                        whiteSpace: 'pre-line',
                                        marginTop: 1,
                                        color: '#7b7b7b'
                                    }}>{message.content}</Typography>
                    </ChatBubble>
                )
            case 'assistant':
                return (
                    <ChatBubble role={message.role} key={message.content}>
                        <img src={ellaLogo} style={{height: '40px', paddingRight: 10, paddingLeft: 2}}/>
                        <Typography variant={'body1'}
                                    sx={{marginTop: 1, color: '#505d8b'}}>{renderBoldText(message.content)}</Typography>
                    </ChatBubble>
                )
            default:
                console.log('default')
        }
    }, [messages])

    function sendChatGptPrompt() {
        setChatGptUserQuery(
            {
                "role": "user",
                "content": userQuery
            }
        )
    }

    return (
        <Stack direction={"column"} maxWidth={"lg"} gap={3} justifyContent={"space-between"} sx={{mx: {xs: 2, md: "auto"}, maxHeight: {xs: "calc(100dvh - 68px - 32px)", md: "calc(100dvh - 68px - 48px)", height: "100%"} }}>
            <Stack direction={'column'} sx={{flex: 1, overflowY: 'auto'}} className={"overflow-hidden"}>
                <Box>
                    <Typography variant={'h3'} fontWeight={'bold'} sx={{mb: 2}}> AI Survey Designer</Typography>
                    <Typography variant={'h5'} sx={{mb: 4}}> Ella is here to help you design the best possible
                        survey. Tell Ella what your survey needs are, and she will tell you what you can do to improve your
                        survey to account for biases, question quality, and question types.
                    </Typography>
                </Box>
                {messages.map(message => {
                    return (
                        getMessageCard(message)
                    )
                })}
                <Box ref={bottomRef} />
            </Stack>
            <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'center'}>
                <TextField
                  placeholder="What would be the best questions and question types to use to find out how..."
                  multiline rows={1.5} sx={{flex: 1}}
                  value={userQuery} onChange={e => setUserQuery(e.target.value)}
                />
                <Box sx={{textAlign: "right"}}>
                    {
                        isAnswering ?
                            <img style={{width: '50px'}} alt={"Loading"} src={loadingGif}/> :
                            <IconButton color="primary" variant={"outlined"} sx={{width: "50px", height: "50px"}} aria-label="add" onClick={sendChatGptPrompt}>
                              <SendIcon/>
                            </IconButton>
                    }
                </Box>
            </Stack>
        </Stack>
    );
}
export default ChatWindow;


