import React, {useEffect, useState} from "react";
import {Avatar, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {CardItem} from "../../../../components/card/CardItem";
import {blue} from "@mui/material/colors";

export const ImageQuestionPreview = ({question, answers, title, subtitle, index, secondaryAction}) => {
    const [photos, setPhotos] = useState([]);

    useEffect(() => {
        let images = [];
        if (answers) {
            for (const answer of answers) {
                if (answer.photoUrls) {
                    images = [...images, ...answer.photoUrls]
                }
            }
        }
        setPhotos(images)
    }, [answers])

    return (
        <CardItem
            // avatar={
            //     <Avatar sx={{
            //         bgcolor: blue[100],
            //         borderRadius: 3,
            //         color: blue[800],
            //         fontWeight: 'bold',
            //         fontSize: 16
            //     }}>
            //         Q{index}
            //     </Avatar>}
            title={<Typography fontSize={16} fontWeight={"bold"}>{title}</Typography>}
            subheader={<Typography fontSize={12} variant={"secondary2"}>{subtitle}</Typography>}
            secondary={secondaryAction ? secondaryAction(question.uid) : null}
        >

            <Stack direction={"row"} gap={1} sx={{my: 2}} justifyContent={"start"} alignItems={"center"} useFlexGap
                   flexWrap={"wrap"}>
                {
                    photos?.map(photo => {
                        // const url =`${process.env.REACT_APP_DOWNLOADS}${photo}`
                        // console.log(url);
                        return <img key={photo} src={photo}
                                    style={{width: "auto", maxHeight: "180px", height: "auto"}}
                                    alt={'preview img'}/>
                    })
                }
            </Stack>
        </CardItem>
    )
}
