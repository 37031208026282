export const countryCodesWithNativeLanguages = [
    {
        "Name": "English (United States)",
        "BCP47": "en-US",
        "Native": "English"
    },
    {
        "Name": "Spanish (United States)",
        "BCP47": "es-US",
        "Native": "Español"
    },
    {
        "Name": "Chinese, Mandarin (Simplified, China)",
        "BCP47": "zh",
        "Native": "中文"
    },
    {
        "Name": "Chinese, Mandarin (Traditional, Taiwan)",
        "BCP47": "zh-TW",
        "Native": "中文"
    },
    {
        "Name": "Afrikaans (South Africa)",
        "BCP47": "af-ZA",
        "Native": "Afrikaans"
    },
    {
        "Name": "Albanian (Albania)",
        "BCP47": "sq-AL",
        "Native": "Shqip"
    },
    {
        "Name": "Amharic (Ethiopia)",
        "BCP47": "am-ET",
        "Native": "አማርኛ"
    },
    {
        "Name": "Arabic (Algeria)",
        "BCP47": "ar-DZ",
        "Native": "العربية"
    },
    {
        "Name": "Arabic (Bahrain)",
        "BCP47": "ar-BH",
        "Native": "العربية"
    },
    {
        "Name": "Arabic (Egypt)",
        "BCP47": "ar-EG",
        "Native": "العربية"
    },
    {
        "Name": "Arabic (Iraq)",
        "BCP47": "ar-IQ",
        "Native": "العربية"
    },
    {
        "Name": "Arabic (Israel)",
        "BCP47": "ar-IL",
        "Native": "العربية"
    },
    {
        "Name": "Arabic (Jordan)",
        "BCP47": "ar-JO",
        "Native": "العربية"
    },
    {
        "Name": "Arabic (Kuwait)",
        "BCP47": "ar-KW",
        "Native": "العربية"
    },
    {
        "Name": "Arabic (Lebanon)",
        "BCP47": "ar-LB",
        "Native": "العربية"
    },
    {
        "Name": "Arabic (Morocco)",
        "BCP47": "ar-MA",
        "Native": "العربية"
    },
    {
        "Name": "Arabic (Oman)",
        "BCP47": "ar-OM",
        "Native": "العربية"
    },
    {
        "Name": "Arabic (Qatar)",
        "BCP47": "ar-QA",
        "Native": "العربية"
    },
    {
        "Name": "Arabic (Saudi Arabia)",
        "BCP47": "ar-SA",
        "Native": "العربية"
    },
    {
        "Name": "Arabic (State of Palestine)",
        "BCP47": "ar-PS",
        "Native": "العربية"
    },
    {
        "Name": "Arabic (Tunisia)",
        "BCP47": "ar-TN",
        "Native": "العربية"
    },
    {
        "Name": "Arabic (United Arab Emirates)",
        "BCP47": "ar-AE",
        "Native": "العربية"
    },
    {
        "Name": "Arabic (Yemen)",
        "BCP47": "ar-YE",
        "Native": "العربية"
    },
    {
        "Name": "Armenian (Armenia)",
        "BCP47": "hy-AM",
        "Native": "Հայերեն"
    },
    {
        "Name": "Azerbaijani (Azerbaijan)",
        "BCP47": "az-AZ",
        "Native": "Azərbaycanca / آذربايجان"
    },
    {
        "Name": "Basque (Spain)",
        "BCP47": "eu-ES",
        "Native": "Euskara"
    },
    {
        "Name": "Bengali (Bangladesh)",
        "BCP47": "bn-BD",
        "Native": "বাংলা"
    },
    {
        "Name": "Bengali (India)",
        "BCP47": "bn-IN",
        "Native": "বাংলা"
    },
    {
        "Name": "Bosnian (Bosnia and Herzegovina)",
        "BCP47": "bs-BA",
        "Native": "Bosanski"
    },
    {
        "Name": "Bulgarian (Bulgaria)",
        "BCP47": "bg-BG",
        "Native": "Български"
    },
    {
        "Name": "Burmese (Myanmar)",
        "BCP47": "my-MM",
        "Native": "Myanmasa"
    },
    {
        "Name": "Catalan (Spain)",
        "BCP47": "ca-ES",
        "Native": "Català"
    },
    {
        "Name": "Croatian (Croatia)",
        "BCP47": "hr-HR",
        "Native": "Hrvatski"
    },
    {
        "Name": "Czech (Czech Republic)",
        "BCP47": "cs-CZ",
        "Native": "Česky"
    },
    {
        "Name": "Danish (Denmark)",
        "BCP47": "da-DK",
        "Native": "Dansk"
    },
    {
        "Name": "Dutch (Belgium)",
        "BCP47": "nl-BE",
        "Native": "Nederlands"
    },
    {
        "Name": "Dutch (Netherlands)",
        "BCP47": "nl-NL",
        "Native": "Nederlands"
    },
    {
        "Name": "English (Australia)",
        "BCP47": "en-AU",
        "Native": "English"
    },
    {
        "Name": "English (Canada)",
        "BCP47": "en-CA",
        "Native": "English"
    },
    {
        "Name": "English (Ghana)",
        "BCP47": "en-GH",
        "Native": "English"
    },
    {
        "Name": "English (Hong Kong)",
        "BCP47": "en-HK",
        "Native": "English"
    },
    {
        "Name": "English (India)",
        "BCP47": "en-IN",
        "Native": "English"
    },
    {
        "Name": "English (Ireland)",
        "BCP47": "en-IE",
        "Native": "English"
    },
    {
        "Name": "English (Kenya)",
        "BCP47": "en-KE",
        "Native": "English"
    },
    {
        "Name": "English (New Zealand)",
        "BCP47": "en-NZ",
        "Native": "English"
    },
    {
        "Name": "English (Nigeria)",
        "BCP47": "en-NG",
        "Native": "English"
    },
    {
        "Name": "English (Pakistan)",
        "BCP47": "en-PK",
        "Native": "English"
    },
    {
        "Name": "English (Philippines)",
        "BCP47": "en-PH",
        "Native": "English"
    },
    {
        "Name": "English (Singapore)",
        "BCP47": "en-SG",
        "Native": "English"
    },
    {
        "Name": "English (South Africa)",
        "BCP47": "en-ZA",
        "Native": "English"
    },
    {
        "Name": "English (Tanzania)",
        "BCP47": "en-TZ",
        "Native": "English"
    },
    {
        "Name": "English (United Kingdom)",
        "BCP47": "en-GB",
        "Native": "English"
    },

    {
        "Name": "Estonian (Estonia)",
        "BCP47": "et-EE",
        "Native": "Eesti"
    },
    {
        "Name": "Filipino (Philippines)",
        "BCP47": "fil-PH",
        "Native": "Filipino (Philippines)"
    },
    {
        "Name": "Finnish (Finland)",
        "BCP47": "fi-FI",
        "Native": "Suomi"
    },
    {
        "Name": "French (Belgium)",
        "BCP47": "fr-BE",
        "Native": "Français"
    },
    {
        "Name": "French (Canada)",
        "BCP47": "fr-CA",
        "Native": "Français"
    },
    {
        "Name": "French (France)",
        "BCP47": "fr-FR",
        "Native": "Français"
    },
    {
        "Name": "French (Switzerland)",
        "BCP47": "fr-CH",
        "Native": "Français"
    },
    {
        "Name": "Galician (Spain)",
        "BCP47": "gl-ES",
        "Native": "Galego"
    },
    {
        "Name": "Georgian (Georgia)",
        "BCP47": "ka-GE",
        "Native": "ქართული"
    },
    {
        "Name": "German (Austria)",
        "BCP47": "de-AT",
        "Native": "Deutsch"
    },
    {
        "Name": "German (Germany)",
        "BCP47": "de-DE",
        "Native": "Deutsch"
    },
    {
        "Name": "German (Switzerland)",
        "BCP47": "de-CH",
        "Native": "Deutsch"
    },
    {
        "Name": "Greek (Greece)",
        "BCP47": "el-GR",
        "Native": "Ελληνικά"
    },
    {
        "Name": "Gujarati (India)",
        "BCP47": "gu-IN",
        "Native": "ગુજરાતી"
    },
    {
        "Name": "Hebrew (Israel)",
        "BCP47": "iw-IL",
        "Native": "Hebrew (Israel)"
    },
    {
        "Name": "Hindi (India)",
        "BCP47": "hi-IN",
        "Native": "हिन्दी"
    },
    {
        "Name": "Hungarian (Hungary)",
        "BCP47": "hu-HU",
        "Native": "Magyar"
    },
    {
        "Name": "Icelandic (Iceland)",
        "BCP47": "is-IS",
        "Native": "Íslenska"
    },
    {
        "Name": "Indonesian (Indonesia)",
        "BCP47": "id-ID",
        "Native": "Bahasa Indonesia"
    },
    {
        "Name": "Italian (Italy)",
        "BCP47": "it-IT",
        "Native": "Italiano"
    },
    {
        "Name": "Italian (Switzerland)",
        "BCP47": "it-CH",
        "Native": "Italiano"
    },
    {
        "Name": "Japanese (Japan)",
        "BCP47": "ja-JP",
        "Native": "日本語"
    },
    {
        "Name": "Javanese (Indonesia)",
        "BCP47": "jv-ID",
        "Native": "Basa Jawa"
    },
    {
        "Name": "Kannada (India)",
        "BCP47": "kn-IN",
        "Native": "ಕನ್ನಡ"
    },
    {
        "Name": "Kazakh (Kazakhstan)",
        "BCP47": "kk-KZ",
        "Native": "Қазақша"
    },
    {
        "Name": "Khmer (Cambodia)",
        "BCP47": "km-KH",
        "Native": "ភាសាខ្មែរ"
    },
    {
        "Name": "Korean (South Korea)",
        "BCP47": "ko-KR",
        "Native": "한국어"
    },
    {
        "Name": "Lao (Laos)",
        "BCP47": "lo-LA",
        "Native": "ລາວ / Pha xa lao"
    },
    {
        "Name": "Latvian (Latvia)",
        "BCP47": "lv-LV",
        "Native": "Latviešu"
    },
    {
        "Name": "Lithuanian (Lithuania)",
        "BCP47": "lt-LT",
        "Native": "Lietuvių"
    },
    {
        "Name": "Macedonian (North Macedonia)",
        "BCP47": "mk-MK",
        "Native": "Македонски"
    },
    {
        "Name": "Malay (Malaysia)",
        "BCP47": "ms-MY",
        "Native": "Bahasa Melayu"
    },
    {
        "Name": "Malayalam (India)",
        "BCP47": "ml-IN",
        "Native": "മലയാളം"
    },
    {
        "Name": "Marathi (India)",
        "BCP47": "mr-IN",
        "Native": "मराठी"
    },
    {
        "Name": "Mongolian (Mongolia)",
        "BCP47": "mn-MN",
        "Native": "Монгол"
    },
    {
        "Name": "Nepali (Nepal)",
        "BCP47": "ne-NP",
        "Native": "नेपाली"
    },
    {
        "Name": "Norwegian BokmÅ’l (Norway)",
        "BCP47": "no-NO",
        "Native": "Norsk (bokmål / riksmål)"
    },
    {
        "Name": "Persian (Iran)",
        "BCP47": "fa-IR",
        "Native": "فارسی"
    },
    {
        "Name": "Polish (Poland)",
        "BCP47": "pl-PL",
        "Native": "Polski"
    },
    {
        "Name": "Portuguese (Brazil)",
        "BCP47": "pt-BR",
        "Native": "Português"
    },
    {
        "Name": "Portuguese (Portugal)",
        "BCP47": "pt-PT",
        "Native": "Português"
    },
    {
        "Name": "Punjabi (Gurmukhi India)",
        "BCP47": "pa-Guru-IN",
        "Native": "ਪੰਜਾਬੀ / पंजाबी / پنجابي"
    },
    {
        "Name": "Romanian (Romania)",
        "BCP47": "ro-RO",
        "Native": "Română"
    },
    {
        "Name": "Russian (Russia)",
        "BCP47": "ru-RU",
        "Native": "Русский"
    },
    {
        "Name": "Serbian (Serbia)",
        "BCP47": "sr-RS",
        "Native": "Српски"
    },
    {
        "Name": "Sinhala (Sri Lanka)",
        "BCP47": "si-LK",
        "Native": "සිංහල"
    },
    {
        "Name": "Slovak (Slovakia)",
        "BCP47": "sk-SK",
        "Native": "Slovenčina"
    },
    {
        "Name": "Slovenian (Slovenia)",
        "BCP47": "sl-SI",
        "Native": "Slovenščina"
    },
    {
        "Name": "Spanish (Argentina)",
        "BCP47": "es-AR",
        "Native": "Español"
    },
    {
        "Name": "Spanish (Bolivia)",
        "BCP47": "es-BO",
        "Native": "Español"
    },
    {
        "Name": "Spanish (Chile)",
        "BCP47": "es-CL",
        "Native": "Español"
    },
    {
        "Name": "Spanish (Colombia)",
        "BCP47": "es-CO",
        "Native": "Español"
    },
    {
        "Name": "Spanish (Costa Rica)",
        "BCP47": "es-CR",
        "Native": "Español"
    },
    {
        "Name": "Spanish (Dominican Republic)",
        "BCP47": "es-DO",
        "Native": "Español"
    },
    {
        "Name": "Spanish (Ecuador)",
        "BCP47": "es-EC",
        "Native": "Español"
    },
    {
        "Name": "Spanish (El Salvador)",
        "BCP47": "es-SV",
        "Native": "Español"
    },
    {
        "Name": "Spanish (Guatemala)",
        "BCP47": "es-GT",
        "Native": "Español"
    },
    {
        "Name": "Spanish (Honduras)",
        "BCP47": "es-HN",
        "Native": "Español"
    },
    {
        "Name": "Spanish (Mexico)",
        "BCP47": "es-MX",
        "Native": "Español"
    },
    {
        "Name": "Spanish (Nicaragua)",
        "BCP47": "es-NI",
        "Native": "Español"
    },
    {
        "Name": "Spanish (Panama)",
        "BCP47": "es-PA",
        "Native": "Español"
    },
    {
        "Name": "Spanish (Paraguay)",
        "BCP47": "es-PY",
        "Native": "Español"
    },
    {
        "Name": "Spanish (Peru)",
        "BCP47": "es-PE",
        "Native": "Español"
    },
    {
        "Name": "Spanish (Puerto Rico)",
        "BCP47": "es-PR",
        "Native": "Español"
    },
    {
        "Name": "Spanish (Spain)",
        "BCP47": "es-ES",
        "Native": "Español"
    },
    {
        "Name": "Spanish (Uruguay)",
        "BCP47": "es-UY",
        "Native": "Español"
    },
    {
        "Name": "Spanish (Venezuela)",
        "BCP47": "es-VE",
        "Native": "Español"
    },
    {
        "Name": "Sundanese (Indonesia)",
        "BCP47": "su-ID",
        "Native": "Basa Sunda"
    },
    {
        "Name": "Swahili (Kenya)",
        "BCP47": "sw-KE",
        "Native": "Kiswahili"
    },
    {
        "Name": "Swahili (Tanzania)",
        "BCP47": "sw-TZ",
        "Native": "Kiswahili"
    },
    {
        "Name": "Swedish (Sweden)",
        "BCP47": "sv-SE",
        "Native": "Svenska"
    },
    {
        "Name": "Tamil (India)",
        "BCP47": "ta-IN",
        "Native": "தமிழ்"
    },
    {
        "Name": "Tamil (Malaysia)",
        "BCP47": "ta-MY",
        "Native": "தமிழ்"
    },
    {
        "Name": "Tamil (Singapore)",
        "BCP47": "ta-SG",
        "Native": "தமிழ்"
    },
    {
        "Name": "Tamil (Sri Lanka)",
        "BCP47": "ta-LK",
        "Native": "தமிழ்"
    },
    {
        "Name": "Telugu (India)",
        "BCP47": "te-IN",
        "Native": "తెలుగు"
    },
    {
        "Name": "Thai (Thailand)",
        "BCP47": "th-TH",
        "Native": "ไทย / Phasa Thai"
    },
    {
        "Name": "Turkish (Turkey)",
        "BCP47": "tr-TR",
        "Native": "Türkçe"
    },
    {
        "Name": "Ukrainian (Ukraine)",
        "BCP47": "uk-UA",
        "Native": "Українська"
    },
    {
        "Name": "Urdu (India)",
        "BCP47": "ur-IN",
        "Native": "اردو"
    },
    {
        "Name": "Urdu (Pakistan)",
        "BCP47": "ur-PK",
        "Native": "اردو"
    },
    {
        "Name": "Uzbek (Uzbekistan)",
        "BCP47": "uz-UZ",
        "Native": "Ўзбек"
    },
    {
        "Name": "Vietnamese (Vietnam)",
        "BCP47": "vi-VN",
        "Native": "Việtnam"
    },
    {
        "Name": "Zulu (South Africa)",
        "BCP47": "zu-ZA",
        "Native": "isiZulu"
    }]
