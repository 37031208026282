const config = {
  api: {
    host: '',
    port: 0,
  },
  serverUrl: process.env.REACT_APP_SERVER_URL,
  clientUrl: process.env.REACT_APP_CLIENT_URL,
  environment: process.env.REACT_APP_ENVIRONMENT,
  navItems: [
    // {
    //   title: "Snapshot",
    //   path: '/snapshot',
    // },
    {
      title: "Survey List",
      path: '/projects-list',
    },
    // {
    //   title: "Create Survey",
    //   path: '/create-project',
    // },
    {
      title: "Survey Summaries",
      path: '/project-summaries',
    },
    // {
    //   title: "Analysis",
    //   path: '/analysis',
    // },

  ],
  questions: [
    {
      questionText: "Que suministros necesitas1?",
      questionId: '93939'
    },
    {
      questionText: "Que suministros necesitas2?",
      questionId: '93939'
    },
    {
      questionText: "Que suministros necesitas3?",
      questionId: '93939'
    },
    {
      questionText: "Que suministros necesitas4?",
      questionId: '93939'
    },
  ],
  projectTypeOptions: [{
    name: "option 1",
    value: '1'
  }, {
    name: "option 2",
    value: '2'
  }, {
    name: "option 3",
    value: '3'
  }],
  selectSurveyOptions: [{
    name: "option 1",
    value: '1'
  }, {
    name: "option 2",
    value: '2'
  }, {
    name: "option 3",
    value: '3'
  }],
  projectsOverview: {
    completeResponses: '20',
    incompleteResponses: '25'
  },
  projectsList: [
    {
      title: "MACDT",
      launched: 'June 3, 2021',
      expired: true,
      numResponses: '55',
      numQuestions: '11',
      numLanguages: '4',
      projectDetails: [
        {title: "Green Spaces Commitee October meeting"},
        {projectDescription: "This project is for the October 2021 green spaces committee meeting"},
        {description: "lalala"},
        {
          languagesUsed: [{
            name: "English",
            usedBy: "31"
          },
            {
              name: "Spanish",
              usedBy: "312"
            },
            {
              name: "Italian",
              usedBy: "3"
            },
            {
              name: "Chinese",
              usedBy: "9"
            },]
        },
        {
          responses: {
            total: 71,
            completed: 70,
            incomplete: 1,
            responsesList: [
              {
                name: "Moon Chen",
                date: "November 10, 2021",
                originalLang: "Chinese",
                geoLocLat: "-29",
                geoLocLng: "-39.393",
                responseText: "939292dncskncijanianieqhrfionc0iqefocmoijadncoancoab0fuqaciam",
                translation: "cjnaodcojadncvouadnvc0oqiunevouansdvou asncvoadibnvbcv0uadocbvuad0jno bcaudcjbuhfnoacjsz ibsu9havbdiuhcnosajbiv cuhsachsaducjn",
              }
            ]
          }

        },
        {
          themes: [{
            name: "green space",
            mentions: '105',
          }, {
            name: "community",
            mentions: '105',
          }, {
            name: "streetscape",
            mentions: '105',
          }, {
            name: "programming",
            mentions: '105',
          }, {
            name: "next year",
            mentions: '105',
          },]
        }
      ]
    },
    {
      title: "Green Spaces Committee",
      launched: 'October 1, 2021',
      expired: false,
      numResponses: '78',
      numQuestions: '11',
      numLanguages: '4',
    },
    {
      title: "Historic Perservation",
      launched: 'June 1, 2021',
      expired: false,
      numResponses: '29',
      numQuestions: '139',
      numLanguages: '1',
    },
    {
      title: "Basil Design Associates",
      launched: 'June 3, 2021',
      expired: true,
      numResponses: '225',
      numQuestions: '11',
      numLanguages: '4',
    },
    {
      title: "Home City Development",
      launched: 'September 3, 2021',
      expired: true,
      numResponses: '19992',
      numQuestions: '441',
      numLanguages: '10',
    },
  ],
  projectSummaryResponses: [{
    author: 'Moon Chen',
    date: 'November 11, 2021',
    properties: [{
      name: "Original Language",
      value: "Chinese",
    },
      {
        name: "Location",
        value: '-71, 84.3192'
      }],
    responses: [{
      language: "Chinese",
      value: "我为明年的项目感到激动。我认为该计划对该地区的家庭有利，特别是因为疫情。我不确定我对这位先生所说的户外空间在某些特定日子将关闭的感觉如何，但我希望其他人也能提到这一点。"
    },
      {
        language: "English",
        value: 'I’m excited for next year’s programming. I think the agenda will be good for families in the area, especially because of the pandemic. I’m not sure how I feel about what the gentleman was saying about outdoors spaces closing on certain days, but I hope that this is something other people also mention.'
      }]
  },
    {
      author: 'Sunny Smith',
      date: 'November 11, 2021',
      properties: [{
        name: "Location",
        value: '-71, 84.3192'
      }],
      responses: [
        {
          language: "English",
          value: 'I’m excited for next year’s programming. I think the agenda will be good for families in the area, especially because of the pandemic. I’m not sure how I feel about what the gentleman was saying about outdoors spaces closing on certain days, but I hope that this is something other people also mention.'
        }]
    }]

}
export default config;
