import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../../utils/AppContext";
import {useNavigate, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import {Header} from "../../components/main-header/HeaderElla";
import {TitleDate} from "../../components/shared-components/text-formating/title-date/title-date";
import {collection, doc, getDoc, getDocs, orderBy, query, where} from "firebase/firestore";
import {db} from "../../utils/firebaseApp";
import {removeDuplicatesByProperty, sortArrayByNumericObjectProperty} from "../../utils/sharedFunctions";
import uuid from "react-uuid";
import {toast} from "react-toastify";
import LoadingScreen from "../../components/shared-components/LoadingScreen";
import {QuestionGraphWrapper} from "../analysis-page/components/question-graphs/QuestionGraphWrapper";
import * as activeSurvey from "countries-list";

const PublicReportPage = () => {
    let {uid} = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [report, setReport] = useState(null);
    const [questionObjects, setQuestionObjects] = useState([]);
    const [answerObjects, setAnswerObjects] = useState([]);
    const {state: {currentUser}, dispatch} = useContext(AppContext);

    useEffect(() => {
        fetchSurveyData(uid)
    }, [uid])

    async function fetchSurveyData(reportUid) {
        if (!reportUid) navigate('/404')
        setLoading(true);
        console.log(uid)
        try {
            const reportRef = doc(db, "reports", uid);
            const reportSnapshot = await getDoc(reportRef);
            const report = reportSnapshot.data();
            if (!reportSnapshot.exists()) navigate('/404')

            const questionQuery = query(collection(db, "questions"), where("surveyUid", "==", report.surveyUid));
            const questionSnapshot = await getDocs(questionQuery);
            let questionObjects = []
            let answerObjects = []
            questionSnapshot.forEach((doc) => {
                questionObjects.push(doc.data())
            });

            const answerQuery = query(collection(db, "answers"), where("surveyUid", "==", report.surveyUid), orderBy("timestamp"),);
            const answerSnapshot = await getDocs(answerQuery);
            answerSnapshot.forEach((doc) => {
                answerObjects.push(doc.data());
            });

            setReport(report)
            setQuestionObjects(sortArrayByNumericObjectProperty(questionObjects, "orderNumber"))
            setAnswerObjects(answerObjects)
            dispatch({answerObjects: answerObjects})

        } catch (error) {
            console.log(error)
            if (error.response) {
                toast.error("error", "Something went wrong, please try again!")
            }
        } finally {
            setLoading(false);
        }
    }

    if (loading || !report) return <LoadingScreen/>

    return (
        <>
            <Header/>
            <Box sx={{p: 5, maxWidth: '1000px', marginTop: 5}}>
                <TitleDate title={report.name}/>
                {questionObjects
                    .sort((a, b) => a.orderNumber - b.orderNumber)
                    ?.map((item, index) =>
                        <QuestionGraphWrapper
                            key={item.uid || item?.id || index}
                            question={item}
                            index={index + 1}
                        />
                    )}
            </Box>
        </>
    );
};

export default PublicReportPage;
