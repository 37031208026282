import React from 'react';
import {MainLayout} from "../../layout/main/MainLayout";
import ChatWindow from "./ChatWindow";

const SurveyDesignerPage = () => {
    return (
        <MainLayout>
            <ChatWindow/>
        </MainLayout>
    );
};

export default SurveyDesignerPage;
