import React, {useContext, useState} from 'react';
import {LongSelect} from '../../components/shared-components/form-components/long-select/long-select';
import {LongInput} from '../../components/shared-components/form-components/long-input/long-input';
import {QuestionSection} from '../../components/shared-components/form-components/question-section/question-section';
import {AddBox} from '../../components/shared-components/button/add-box/add-box';

import {OutlinedSelect} from '../../components/shared-components/form-components/outlined-select/outlined-select';
import {ConfigContext} from '../../contexts/config-context';
import {CheckboxOption} from '../../components/shared-components/form-components/checkbox-option/checkbox-option';
import {ColoredButton} from '../../components/shared-components/colored-button/colored-button';

export const EditSurvey = () => {
  const {projectTypeOptions} = useContext(ConfigContext);
  const {selectSurveyOptions} = useContext(ConfigContext);
  const [selectSurvey, setSelectSurvey] = useState("");
  const [geolocation, setGeolocation] = useState(1);
  const [audioRecording, setAudioRecording] = useState(1);
  const selectGeolocation = (value) => {
    setGeolocation(value);
  }

  return (
    <div className='edit-survey-wrap  page-content'>
      <h2>Edit Survey</h2>
      <LongSelect
        id='edit-select-survey'
        title="Select Survey"
        labelId='select-survey-label'
        value={selectSurvey}
        onChange={(e) => setSelectSurvey(e.target.value)}
        label='Select Survey'
        menuItems={selectSurveyOptions}/>

      <div className='edit-survey-section flex row a-start jc-sb'>
        <div className='edit-survey-title-desc-wrap'>
          <LongInput title='Title (external)' label="This is the title that participants will see." width="730px"/>
          <LongInput title='Description (external)' label="This is the description that participants will see."
                     width="730px"/>
          <p className='small-subtitle'>Type of project</p>

          <OutlinedSelect width="730" value={""} label={"Project type goes here"} options={projectTypeOptions}/>
        </div>
        <div className='edit-survey-side-title flex row a-end jc-end'>
          <h3 className='edit-survey-subtitle'>Items for bookkeeping</h3>
        </div>
      </div>
      <div className='edit-survey-section flex row a-start jc-sb'>
        <div className='edit-survey-questions-wrap'>
          <QuestionSection number='1' label="This is the question that participants will see."
                           addBoxLabel='Add a translation' width="730px"/>
          <div className='edit-survey-add-question flex row a-center jc-center'>
            <AddBox size={'big'} label={'Add another question'}/>
          </div>
        </div>
        <div className='edit-survey-side-title flex row a-end jc-end'>
          <h3 className='edit-survey-subtitle'>Questions that partipcipants will see</h3>
        </div>
      </div>
      <div className='edit-survey-section flex row a-start jc-sb'>
        <div className='edit-survey-deployment'>
          <div>
            <p className='edit-survey-deployment-question'>
              Ask respondents for their geolocation
              <span
                className='edit-survey-deployment-explanation'>Do you want participants to give their location?</span>
            </p>
            <CheckboxOption
              optionText={"Yes, ask participants for their geolocation."}
              selected={geolocation === 1 ? true : false}
              onClick={() => selectGeolocation(1)}/>
            <CheckboxOption
              optionText={"No, do not ask participants for their geolocation."}
              selected={geolocation === 1 ? false : true}
              onClick={() => selectGeolocation(2)}/>
          </div>
          <div className='edit-survey-deployment-section'>
            <p className='edit-survey-deployment-question'>
              Save an audio recording of responses
              <span
                className='edit-survey-deployment-explanation'>Do you want to save an audio recording of comments?</span>
            </p>
            <CheckboxOption
              optionText={"Yes, save an audio recording of each comment."}
              selected={audioRecording === 1 ? true : false}
              onClick={() => setAudioRecording(1)}/>
            <CheckboxOption
              optionText={"No, do not save an audio recording. Transcribe to text and erase the audio recording."}
              selected={audioRecording === 1 ? false : true}
              onClick={() => setAudioRecording(2)}/>

          </div>
        </div>
        <div className='edit-survey-side-title flex row a-end jc-end'>
          <h3 className='edit-survey-subtitle'>Project deployment settings</h3>
        </div>
      </div>
      <div className='edit-survey-save flex row a-center jc-center'>
        <ColoredButton label='Save this survey' color='#21DD3F' width="196px" fontSize='18px' onClick={() => {
        }}/>
      </div>
    </div>
  )
}
