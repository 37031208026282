import React, {useEffect, useRef, useState} from 'react';
import {Chip, Grid, IconButton, Stack, Typography} from "@mui/material";
import {Map, Marker} from "react-map-gl";
import PulsingDot from "../../assets/PulsingDot/PulsingDot";
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Box from "@mui/material/Box";
import MyLocationOutlinedIcon from '@mui/icons-material/MyLocationOutlined';
import "mapbox-gl/dist/mapbox-gl.css";
import {colors} from "./mapIcons";
import {green} from "@mui/material/colors";
import PropTypes from "prop-types";

const MapComponent = ({showUserDot, questionObject, userCanSetPOIs, pois, setPois}) => {
    const token = 'pk.eyJ1IjoidXJvc3JhbiIsImEiOiJjbGc0YzIzem0wbzdtM2VxZXF1cnphZ3VkIn0.enXGQJh4-YIVVndbjXsKyA'
    const [locateUserPressed, setLocateUserPressed] = useState(false);
    const [selectedChip, setSelectedChip] = useState(questionObject.options ? questionObject.options[0].option : null)
    const [viewport, setViewport] = useState({
        longitude: -74.0060,
        latitude: 40.7128,
        zoom: 8
    });
    const [userLocation, setUserLocation] = useState({
        longitude: -74.0060,
        latitude: 40.7128,
    })
    const mapRef = useRef();

    useEffect(() => {
        setSelectedChip(questionObject.options ? questionObject.options[0].option : null)
    }, [questionObject])

    useEffect(() => {
        if (pois.length === 0) return
        const midpoint = calculateMidpointFromJsonList(pois)
        //TODO: condition showUserDot used only because in display mode we don't want to fly to the user location
        mapRef.current?.flyTo({center: [midpoint.lng, midpoint.lat], duration: 2000});
    }, )

    function locateUser() {
        if (!locateUserPressed) {
            navigator.geolocation.getCurrentPosition(function (position) {
                setViewport({longitude: position.coords.longitude, latitude: position.coords.latitude, zoom: 10})
                setUserLocation({longitude: position.coords.longitude, latitude: position.coords.latitude})
                mapRef.current?.flyTo({center: [position.coords.longitude, position.coords.latitude], duration: 2000});
                setLocateUserPressed(true);
            });
        }
    }

    function calculateMidpointFromJsonList(jsonList) {
        if (jsonList.length === 0) {
            return null; // No coordinates to calculate midpoint
        }

        // Calculate average latitude and longitude
        const sumLat = jsonList.reduce((sum, jsonObj) => sum + jsonObj.coordinates.lat, 0);
        const sumLng = jsonList.reduce((sum, jsonObj) => sum + jsonObj.coordinates.lng, 0);

        const avgLat = sumLat / jsonList.length;
        const avgLng = sumLng / jsonList.length;

        return {lat: avgLat, lng: avgLng};
    }

    function handleAddPOI(e) {
        let coordinates = e.lngLat
        let shouldAddPoi = true

        pois.map(poi => {
            if (poi.coordinates.lng === coordinates.lng && poi.coordinates.lat === coordinates.lat) shouldAddPoi = false
        })

        if (shouldAddPoi) setPois([...pois, {coordinates: coordinates, option: selectedChip}])

    }

    function removeMarker(coords) {
        const filteredPois = pois.filter(poi => {
            return poi.coordinates.lng !== coords.lng && poi.coordinates.lat !== coords.lng
        })
        setPois([...filteredPois])
    }

    function CustomMarker(props) {
        const {longitude, latitude, icon, coords} = props;

        return (
            <Marker onClick={(e) => {
                e.originalEvent.stopPropagation();
                removeMarker(coords)
            }} latitude={latitude} longitude={longitude} offsetLeft={-20} offsetTop={-10}>
                <div>{icon}</div>
            </Marker>
        );
    }


    function generateRandomColorHex() {
        // Generate a random number between 0 and 16777215 (FFFFFF in hexadecimal)
        const randomColor = Math.floor(Math.random() * 16777216);

        // Convert the random number to a hexadecimal string
        return '#' + randomColor.toString(16).padStart(6, '0');
    }

    function getindexByProperty(array, propertyValue, propertyToLookFor) {
        let index = -1
        array.map((opt, i) => {
            if (opt[propertyToLookFor] === propertyValue) index = i
        })
        return index
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'flex-start'}}>
            <Grid item xs={12}>
                <Map mapboxApiAccessToken={token}
                     ref={mapRef}
                     mapboxAccessToken={token}
                     scrollZoom={true}
                     initialViewState={viewport}
                     style={{width: "100%", height: 'auto', minHeight: '300px'}}
                     onClick={(e) => userCanSetPOIs ? handleAddPOI(e) : null}
                     mapStyle={'mapbox://styles/urosran/clgxw85q1008801qt5gswf9la'}
                     attributionControl={false}
                     position={'top-right'}

                >
                    {pois?.map((poi, index) => {
                        // console.log(poi)
                        // console.log(getindexByProperty(questionObject.options, poi.option, 'option'))
                        //find index of the object in array of objects based on one of the properties
                        return (
                            <CustomMarker key={poi.coordinates.lng + poi.coordinates.lat}
                                          latitude={parseFloat(poi.coordinates.lat)}
                                          longitude={parseFloat(poi.coordinates.lng)}
                                          viewport={viewport}
                                          coords={poi.coordinates}
                                          setViewport={setViewport}
                                          icon={<PulsingDot pulse={false}
                                                            fill={questionObject.options ?
                                                                colors[getindexByProperty(questionObject.options, poi.option, 'option')]
                                                                : generateRandomColorHex()}/>}
                                          locationObject={poi}
                            />)
                    })}


                    {showUserDot ? <CustomMarker
                        latitude={parseFloat(userLocation.latitude)}
                        longitude={parseFloat(userLocation.longitude)}
                        name={"You Are Here"}
                        viewport={viewport}
                        icon={<PulsingDot pulse={true} fill={'#0860b0'}/>}
                        setViewport={setViewport}
                        locationObject={null}
                    /> : null}
                    <IconButton
                        size={'large'}
                        sx={{
                            backgroundColor: '#14AAFE',
                            position: 'absolute',
                            bottom: 10,
                            right: 10,
                            zIndex: 10,
                            "&:disabled": {
                                backgroundColor: green[300]
                            }
                        }}
                        classes={{disabled: {backgroundColor: green[300]}}}
                        aria-label="add"
                        disabled={locateUserPressed}
                        onClick={() => locateUser()}>
                        <MyLocationOutlinedIcon sx={{color: locateUserPressed ? '#f1f1f1' : '#fff'}}/>
                    </IconButton>
                </Map>

                {userCanSetPOIs &&
                    <Stack direction={'row'} justifyContent={"center"}>
                        <InfoIcon sx={{color: 'gray', marginRight: 0.5, fontSize: 12}}/>
                        <Typography sx={{color: 'gray', fontSize: 12}}>Click the item and touch the map where it is
                            located</Typography>
                    </Stack>}

                <Box sx={{marginTop: 0.5}}>
                    {questionObject.options?.map((option, index) => {
                        return <Chip label={option.option} disabled={false} name={option.option}
                                     sx={{
                                         backgroundColor: (selectedChip === option.option || !userCanSetPOIs) ? colors[index] : "gray",
                                         margin: 1,
                                         color: 'white',
                                         '&:hover': {
                                             backgroundColor: selectedChip === option.option ? colors[index] : "gray"
                                         }
                                     }}
                                     key={option.option}
                                     clickable
                                     onClick={(e) => {
                                         setSelectedChip(e.currentTarget.getAttribute('name'))
                                     }}/>
                    })}
                </Box>
            </Grid>
        </Box>
    )
}


export default MapComponent;

MapComponent.propTypes = {
    pois: PropTypes.any,
    questionObject: PropTypes.any,
    setPois: PropTypes.any,
    userCanSetPOIs: PropTypes.any
}
