import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "14px",
  boxShadow: 24,
  p: 4,
};

export default function PasswordResetModal({
  open,
  setOpen,
  actionIfYes,
  actionIfNo,
  title,
  description,
  yesBtnText,
  closeBtnText,
  value,
  setValue,
}) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={actionIfNo}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography id="transition-modal-title" variant="h4" component="h2">
            {title}
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 2 }}>
            {description}
          </Typography>

          {setValue && (
            <TextField
              value={value}
              onChange={(e) => setValue(e.target.value)}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              // autoFocus
            />
          )}
          <Stack direction={"row"} spacing={2} sx={{ marginTop: 2 }}>
            <Button variant={"outlined"} onClick={() => actionIfNo()}>
              <Typography variant={"body1"}>{closeBtnText}</Typography>
            </Button>
            <Button
              className={"gradient-btn"}
              onClick={() => actionIfYes()}
              sx={{}}
            >
              <Typography variant={"body1"} sx={{ color: "white" }}>
                {yesBtnText}
              </Typography>
            </Button>
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );
}

PasswordResetModal.propTypes = {
  actionIfYes: PropTypes.any,
  open: PropTypes.any,
  setOpen: PropTypes.any,
};
