import React, {useContext, useEffect, useState} from "react";
import {MainLayout} from "../../layout/main/MainLayout";
import Stack from "@mui/material/Stack";
import {Button, Grid, IconButton, InputAdornment, TextField, Typography,} from "@mui/material";
import {ContentCopy, Visibility, VisibilityOff} from "@mui/icons-material";
import {v4 as uuid} from "uuid";
import {toast} from "react-toastify";
import {animals, colors, uniqueNamesGenerator} from "unique-names-generator";
import {AppContext} from "../../utils/AppContext";
import {saveAsCsv, updateAPIkeysInDB} from "../../utils/sharedFunctions";
import CodeSampleTabs from "./CodeSamples";
import {TitleDate} from "../../components/shared-components/text-formating/title-date/title-date";
import SurveySelectorDropdowns from "../../components/shared-components/SurveyQuestionSelectorDrodowns";
import {collection, doc, getDocs, query, where, writeBatch} from "firebase/firestore";
import {db} from "../../utils/firebaseApp";

export const ApiPage = () => {
    const {state: {currentUser, surveys, tokens}, dispatch} = useContext(AppContext);
    const [apiList, setApiList] = useState([]);
    const [surveyList, setSurveyList] = useState([]);
    const [activeSurvey, setActiveSurvey] = useState(null);
    const [numberOfLinks, setNumberOfLinks] = useState(10);
    const [usedTokensForCurrentSurvey, setUsedTokensForCurrentSurvey] = useState(0);
    const [unusedTokensForCurrentSurvey, setUnusedTokensForCurrentSurvey] = useState(0);

    useEffect(() => {
        if (currentUser?.apiKeys) setApiList(currentUser.apiKeys);
    }, [currentUser]);

    useEffect(() => {
        async function getTokens() {
            console.log('get tokens')
            const tokensFromServer = []
            const tokensQuery = query(
                collection(db, "tokens"),
                where("surveyUid", "==", activeSurvey.uid)
            );
            const tokensSnapshot = await getDocs(tokensQuery);
            if (tokensSnapshot.empty) return;
            tokensSnapshot.forEach((doc) => {
                tokensFromServer.push(doc.data());
            });
            await dispatch({tokens: [...tokensFromServer, ...tokens]})
        }

        if (!activeSurvey) return;

        if (!tokens || !tokens.some(token => token.surveyUid === activeSurvey.uid)) {
            getTokens()
        }

    }, [activeSurvey]);

    useEffect(() => {
        if (!tokens && !activeSurvey) return
        console.log('should execute after await')
        console.log(tokens)
        const surveyTokens = tokens.filter(token => token.surveyUid === activeSurvey.uid)
        setUsedTokensForCurrentSurvey((surveyTokens.filter(token => token.used === true)).length)
        setUnusedTokensForCurrentSurvey((surveyTokens.filter(token => token.used === false)).length)
    }, [tokens, activeSurvey])

    const generateApi = async () => {
        const name = uniqueNamesGenerator({
            dictionaries: [colors, animals],
            style: "lowerCase",
            separator: "-",
        });

        const apiKey = {
            name: name,
            key: uuid(),
        };

        try {
            const apiListUpdated = [...apiList, apiKey];
            await updateAPIkeysInDB(apiListUpdated, currentUser);
            setApiList(apiListUpdated);
            currentUser.apiKeys = apiListUpdated;
            dispatch({currentUser: currentUser});
            toast.success("Your api key has been generated successfully");
        } catch (error) {
            console.log(error);
        }
    };

    const deleteApiKey = (item) => {
        const items = [...apiList];
        const apiListFiltered = items.filter((i) => i?.key !== item?.key);
        setApiList(apiListFiltered);
        currentUser.apiKeys = apiListFiltered;
        dispatch({currentUser: currentUser});
        toast.success("Your api key has been deleted successfully");
    };

    async function generateUniqueLinksAndDownloadCsv() {
        if (!activeSurvey) {
            toast.error("Please select a survey")
            return;
        }
        const survey = surveys.find((s) => s.uid === activeSurvey.uid);
        if (!survey) return;
        toast.info('Generating...')
        const surveyUrl = `${window.location.origin}/survey/${survey.uid}`;
        const csvData = [];
        const newTokens = []
        try {
            const batch = writeBatch(db);

            for (let i = 0; i < numberOfLinks; i++) {
                const token = uuid()
                batch.set(doc(db, "tokens", token), {surveyUid: survey.uid, token: token, used: false});
                newTokens.push({surveyUid: survey.uid, token: token, used: false})
                const uniqueLink = `${surveyUrl}?token=${token}`;
                csvData.push({link: uniqueLink});
            }
            const csv = "Link\n" + csvData.map((d) => d.link).join("\n");
            const blob = new Blob([csv], {type: "text/csv"});
            await batch.commit();
            dispatch({tokens: [...tokens, ...newTokens]})
            saveAsCsv(blob, `unique_links_${survey.surveyName}.csv`);
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <MainLayout>
            <Grid
                container
                direction={"column"}
                sx={{maxWidth: "1200px", paddingBottom: "90px"}}
                spacing={2}
            >
                <Grid item xs={12} md={12} lg={10}>
                    <TitleDate title="Mailing"/>
                    <Stack
                        direction={"column"}
                        gap={2}
                        flexWrap={"wrap"}
                        sx={{width: "100%", py: 2, marginTop: "10px", marginBottom: '20px'}}
                    >
                        <Typography variant={'h4'}>One-time survey links</Typography>
                        <Typography variant={'body1'}>Generate survey links that can be completed only once.
                            You can use mail merge to send these en-masse to your desired crowd. </Typography>
                        <SurveySelectorDropdowns surveys={surveys}
                                                 setActiveSurvey={setActiveSurvey}
                                                 activeSurvey={activeSurvey}
                                                 showQuestionSelect={false}/>
                        <Typography variant={'body1'}>
                            Used: {usedTokensForCurrentSurvey} Unused: {unusedTokensForCurrentSurvey}
                        </Typography>

                        {/*<Stack direction={'row'}>*/}
                            <TextField
                                value={numberOfLinks}
                                onChange={(e) => {
                                    setNumberOfLinks(e.target.value)
                                }}
                                // error={}
                                variant={'standard'}
                                label={'Number of unique links to generate'}
                                placeholder={'100'}
                                sx={{fontSize: 15, color: "#5271FF", marginRight: 2}}
                            />
                            <Button size={'small'}
                                    sx={{bgcolor: "#FFF", height: '35px'}}
                                    variant={"outlined"}
                                    onClick={() => generateUniqueLinksAndDownloadCsv()}>Generate &
                                download links</Button>
                        {/*</Stack>*/}
                    </Stack>


                    <TitleDate title="APIs"/>

                    <Stack
                        direction={"column"}
                        gap={2}
                        flexWrap={"wrap"}
                        sx={{width: "100%", py: 2, marginTop: "10px", marginBottom: '20px'}}
                    >
                        <Typography variant={'h4'}>API Keys</Typography>
                        <Typography variant={'body1'}>Generate API keys to access your data
                            programmatically </Typography>
                        <Button
                            sx={{bgcolor: "#FFF", height: '35px'}}
                            onClick={generateApi}
                            variant={"outlined"}
                        >
                            Generate API key
                        </Button>
                        {apiList.map((item) => {
                            return (
                                <ApiItem
                                    key={item?.key}
                                    api={item}
                                    onDelete={() => deleteApiKey(item)}
                                />
                            );
                        })}
                    </Stack>
                    <Typography variant={'h4'}>Code Samples</Typography>
                    <Typography variant={'body1'}>Use your API keys by following the code samples below</Typography>
                    {currentUser?.apiKeys && (
                        <CodeSampleTabs
                            apiKey={
                                currentUser.apiKeys.length > 0
                                    ? currentUser.apiKeys[0].key
                                    : "API_KEY"
                            }
                            surveyUid={
                                currentUser.surveys.length > 0
                                    ? currentUser.surveys[0]
                                    : "SURVEY_UID"
                            }
                        />
                    )}
                </Grid>
            </Grid>
        </MainLayout>
    );
}

const ApiItem = ({api, onDelete}) => {
    const [showApi, setShowApi] = useState(false);

    const copyToClipboard = () => {
        try {
            navigator.clipboard.writeText(api?.key);
            toast.success("Your api key has been copied successfully");
        } catch (error) {
            toast.success("Error copying api key");
        }
    };

    return (
        <Stack
            gap={{xs: 2, sm: 4}}
            sx={{
                borderRadius: 2,
                bgcolor: "#FFF",
                alignItems: {xs: "flex-start", sm: "center"},
                justifyContent: "flex-start",
            }}
            direction={{xs: "column", sm: "row"}}
            className={"no-shadow"}
        >
            <Typography
                fontWeight={"bold"}
                sx={{textWrap: "nowrap", minWidth: "200px"}}
            >
                {api?.name}
            </Typography>
            <TextField
                label={"Your API key"}
                type={showApi ? "text" : "password"}
                value={api?.key}
                sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                        margin: "0px!important",
                    },
                }}
                className={"api-key-box"}
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <Stack direction={"row"} gap={2} sx={{px: 1}}>
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowApi(!showApi)}
                                    edge="end"
                                >
                                    {showApi ? <Visibility/> : <VisibilityOff/>}
                                </IconButton>
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={copyToClipboard}
                                    edge="end"
                                >
                                    <ContentCopy/>
                                </IconButton>
                            </Stack>
                        </InputAdornment>
                    ),
                }}
            />
            <Button
                sx={{
                    textTransform: "uppercase !important",
                    width: "fit-content",
                    height: "fit-content",
                }}
                variant={"outlined"}
                color={"error"}
                onClick={onDelete}
            >
                Delete
            </Button>
        </Stack>
    );
};
