import {useEffect, useRef} from "react";

export function useDidUpdate(fn, dependencies) {
    const hasRendered = useRef(false);

    useEffect(() => {
        if (!hasRendered.current) {
            hasRendered.current = true;
        } else {
            fn();
        }
    }, dependencies);
}
