import React, {useContext, useEffect, useState} from 'react';
import {MainLayout} from "../../layout/main/MainLayout";
import {AppContext} from "../../utils/AppContext";
import {Button, Stack, Typography} from "@mui/material";
import {TitleDate} from "../../components/shared-components/text-formating/title-date/title-date";
import Box from "@mui/material/Box";
import CreateReportModal from "./CreateReportModal";
import {ShareQrCodeModal} from "../../components/survey-qrcode/ShareQrCodeModal";
import LoadingScreen from "../../components/shared-components/LoadingScreen";
import ReportItem from "./ReportItem";
import {deleteDoc, doc} from "firebase/firestore";
import {db} from "../../utils/firebaseApp";
import {toast} from "react-toastify";
import {confirm} from "react-confirm-box";
import {confirmDialogOptions} from "../../components/ConfirmDialog";

const ReportsPage = () => {
    const {state: {currentUser, surveys, reports}, dispatch} = useContext(AppContext);
    const [openCreateReportModal, setOpenCreateReportModal] = useState(false);
    const [activeSurvey, setActiveSurvey] = useState(null);
    const [openSurveyQrCode, setOpenSurveyQrCode] = useState(false);
    const [activeReport, setActiveReport] = useState(null);

    useEffect(() => {
        // fetchReports()
        // console.log('reports page')
        // console.log(reports)
    }, [reports])

    async function deleteReport(reportUid) {
        try {
            const response = await confirm(
                {
                    title: `Are you sure you want to delete this report?`,
                    // subtitle: `This action will delete all questions in other languages that were created as a translation of this one.`,
                },
                confirmDialogOptions,
            );
            if (response === false) return;

            await deleteDoc(doc(db, "reports", reportUid));
            const newReports = reports.filter(report => report.uid !== reportUid);
            dispatch({reports: [...newReports]})
            toast.success('Report removed')
        } catch (e) {
            console.error("Error removing document: ", e);
            toast.error('Error removing document')
        }
    }

    function renderReports(survey) {
        if (!reports || reports.length < 1) return;
        // const reportsForSurvey = reports.find(report => report.surveyUid === survey.uid);
        console.log(reports)
        const reportsForSurvey = reports?.filter(report => report.surveyUid === survey.uid)
        return (
            <Box sx={{mt: 1}}>
                {reportsForSurvey.map((report, index) => {
                    return (
                        <ReportItem report={report} setActiveReport={setActiveReport}
                                    setOpenSurveyQrCode={setOpenSurveyQrCode} deleteReport={deleteReport}/>
                    )
                })}
            </Box>)
    }

    if (!surveys) return (<LoadingScreen/>)
    if (!reports) return (<LoadingScreen/>)

    return (
        <>
            <MainLayout>
                <TitleDate title="Reports"/>
                <Stack sx={{mt: 1}}>
                    {surveys?.map((survey, index) => {
                        return (
                            <Box key={survey?.uid} sx={{py: 2, borderBottom: 1, borderColor: "divider"}}>
                                <Typography variant={'h4'}>{survey.surveyName}</Typography>
                                {renderReports(survey, index)}
                                  <Button
                                    onClick={() => {
                                      setActiveSurvey(survey)
                                      setOpenCreateReportModal(!openCreateReportModal)
                                    }}
                                    variant={'outlined'} sx={{maxWidth: 140, marginTop: 2}}
                                  >
                                      Create report
                                  </Button>
                            </Box>
                        );
                    })}
                </Stack>
            </MainLayout>
            <CreateReportModal open={openCreateReportModal} setOpen={setOpenCreateReportModal}
                               activeSurvey={activeSurvey}/>
            <ShareQrCodeModal
                open={openSurveyQrCode}
                handleClose={() => setOpenSurveyQrCode(false)}
                urlToDisplay={`${window.location.origin}/report/${activeReport?.uid}`}
                title={activeReport?.name}
                subtitle={"By sharing this QR code/link, you are sharing the report with the public."}
            />
        </>
    );
}

export default ReportsPage;
