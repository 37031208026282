import React, {useState} from "react";
import {
  Box,
  Button,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText, Stack,
  Toolbar,
  Tooltip,
} from "@mui/material";
import {Link, NavLink, useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import {auth} from "../../utils/firebaseApp";
import {signOut} from "firebase/auth";
import {toast} from "react-toastify";
import {Header} from "../../components/main-header/HeaderElla";
import ScienceIcon from '@mui/icons-material/Science';
import {Logout as LogoutIcon} from "@mui/icons-material"

export const drawerWidth = 260;

const navLinks = [
    {path: "/dashboard", name: "Dashboard"},
    {path: "/surveys", name: "My surveys"},
    {path: "/summaries", name: "Summaries"},
    {path: "/reports", name: "Reports"},
    {path: "/analysis", name: "Responses"},
    {path: "/survey-designer", name: "AI Survey Designer"},
    {path: "/api", name: "Advanced Access"},
    {path: "/create-survey", name: "Create Survey"},
];

export const MainLayout = ({children}) => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const logout = () => {
        signOut(auth)
            .then(() => {
                navigate("/login");
            })
            .catch((error) => {
                toast.error("Something went wrong");
            });
    };

    return (
        <Box
            sx={{
                display: "flex",
                height: "100dvh",
                // overflowX: "hidden",
                // pt: 1,
            }}
            // id={"parent-main-layout-container"}
        >
            <CssBaseline/>
            <Header toggleMenu={setIsOpen} open={isOpen} Logout={logout}/>
            <Drawer
                variant={"permanent"}
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    border: 0,
                    height: "100%",
                    display: {xs: "none", md: "flex"},
                    flexDirection: "column", gap: 3,
                    [`& .MuiDrawer-paper`]: {
                        width: drawerWidth,
                        boxSizing: "border-box",
                        border: 0,
                    },
                }}
            >
                <DrawerItems logoutHandle={logout}/>
            </Drawer>
            <Drawer
                variant={"temporary"}
                onClose={() => setIsOpen(false)}
                open={isOpen}
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    border: 0,
                    height: "100%",
                    display: {xs: "flex", md: "none"},
                    flexDirection: "column", gap: 3,
                    [`& .MuiDrawer-paper`]: {
                        width: drawerWidth,
                        boxSizing: "border-box",
                        border: 0,
                    },
                }}
            >
                <DrawerItems logoutHandle={logout}/>
            </Drawer>
            <Stack
                direction={"column"}
                component="main"
                sx={{
                    flexGrow: 1,
                    // ms: 1,
                    // mt: 2,
                    height: "100%",
                    maxHeight: "100%",
                    marginTop: "0px",
                }}
            >
                <Toolbar sx={{height: "68px"}}/>
                <Box
                    sx={{
                        height: "100%",
                        // maxWidth: `100vw`,
                        // overflowY: "auto",
                        p: {xs: 2, md: 3},
                    }}
                    id={"box-with-children"}
                >
                    {children}
                </Box>
            </Stack>
        </Box>
    );
};

const DrawerItems = ({logoutHandle}) => {
    return (
        <>
            <Toolbar/>

            <Box sx={{mx: 2, mt: 3}}>
                          <Button
                              component={Link}
                              to={"/create-survey"}
                              fullWidth
                              sx={{p: 1, display: {xs: "inline-flex", md: "none"}}}
                              variant={"contained"}
                              className={"gradient-btn"}
                          >
                              <AddIcon/> <Typography>Create Survey</Typography>
                          </Button>
                      </Box>
            <List sx={{border: 0, flex: 1}}>
                          {navLinks.map((item, index) => (
                              <ListItem
                                  key={item.name}
                                  sx={
                                      item.name === "Create Survey"
                                          ? {display: {xs: "none", md: "inline-flex"}}
                                          : null
                                  }
                                  disablePadding
                              >
                                  <ListItemButton component={NavLink} to={item.path}>
                                      <ListItemText
                                          primaryTypographyProps={{
                                              sx: {
                                                  fontWeight: 600,
                                                  padding: "0px",
                                                  fontSize: "1.02em",
                                                  width: 'auto',
                                                  display: 'inline-block'
                                              },
                                          }}
                                          primary={item.name}
                                      />
                                      {item.path === '/survey-designer' ? <ListItemIcon sx={{minWidth: 'auto'}}>
                                          <Tooltip title={'Experimental feature'}>
                                              <ScienceIcon/>
                                          </Tooltip>
                                      </ListItemIcon> : null}
                                  </ListItemButton>
                              </ListItem>
                          ))}

            </List>
            <List>
              <ListItem sx={{mt: "auto !important"}} disablePadding>
                <ListItemButton onClick={logoutHandle}>
                  <ListItemIcon><LogoutIcon /></ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      sx: {
                        fontWeight: 600,
                        padding: "0px",
                        fontSize: "1.02em",
                        width: 'auto',
                        display: 'inline-block'
                      },
                    }}
                    primary={"Logout"}
                  />
                </ListItemButton>
              </ListItem>
            </List>
        </>
    );
};

