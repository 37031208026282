import React, {useEffect, useMemo} from 'react';
import {LongSelect} from "./form-components/long-select/long-select";
import Button from "@mui/material/Button";
import {saveSelectedSurveyAsCSV} from "../../utils/sharedFunctions";
import {useLocation} from "react-router-dom";
import {enums} from "../../utils/enums";

const SurveySelectorDropdowns = ({
                                     surveys,
                                     questionObjects,
                                     setActiveSurvey,
                                     setActiveQuestion,
                                     activeSurvey,
                                     activeQuestion,
                                     showQuestionSelect
                                 }) => {
        const location = useLocation();
        const surveyUid = location.state?.surveyUid;

        useEffect(() => {
            console.log('active question1111', activeQuestion)
        }, [activeQuestion])

        function deduceActiveSurvey(e) {
            const activeSurvey = surveys.filter(survey => survey.surveyName === e)[0]
            setActiveSurvey(activeSurvey)
        }

        function deduceActiveQuestion(e) {
            const activeQuestion = questionObjects.filter(question => question.question === e)[0]
            console.log('activeQuestion deduced')
            console.log(activeQuestion)
            setActiveQuestion(activeQuestion)
        }

        const menuItems = useMemo(() => {
          return questionObjects
            .filter(question => question.surveyUid === activeSurvey.uid)
            .filter(question => ![enums.LOCATE_USER, enums.SECTION].includes(question.type))
            .map((question, index) => {
              console.log(question);
              return {
                name: question.question,
                value: question.question
              }
            })
            .sort((q1, q2) => q1?.name?.trim()?.slice(0,1)?.localeCompare(q2?.name?.trim()?.slice(0,1)))
        }, [questionObjects, activeSurvey])

        return (
            <>
                <LongSelect
                    id='analysis-select-survey'
                    title="Select Survey"
                    labelId='select-survey-label'
                    label='Select Survey'
                    variant={"filled"}
                    menuItems={surveys.map(survey => {
                        return {
                            name: survey.surveyName,
                            value: survey.surveyName
                        }
                    })}
                    selectedOption={activeSurvey?.surveyName}
                    onChange={deduceActiveSurvey}
                    setSelectedOption={deduceActiveSurvey}
                />
                {/*<Button onClick={answersToCsv}>Download Answers</Button>*/}

                {showQuestionSelect && activeQuestion &&
                    <LongSelect
                        id='analysis-select-question'
                        title="Select Question"
                        labelId='select-question-label'
                        label='Select Question'
                        variant={"filled"}
                        selectedOption={activeQuestion?.question}
                        menuItems={menuItems}
                        setSelectedOption={deduceActiveQuestion}
                    />}
            </>

        );
    }
;

export default SurveySelectorDropdowns;

