import React, {useContext, useEffect, useState} from "react";
import uuid from "react-uuid";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl, Grid,
    MenuItem,
    Stack,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import {
    addUpdateQuestionToDb,
    getArrayOfObjectsWithRemovedItem,
    sortArrayByNumericObjectProperty,
} from "../../utils/sharedFunctions";
import {getAuth} from "firebase/auth";
import {AppContext} from "../../utils/AppContext";
import {toast} from "react-toastify";
import {enums} from "../../utils/enums";

import {SurveyVoiceQuestionType} from "./components/survey-voice-question-type/survey-voice-question-type";
import {SurveyTextQuestionType} from "./components/survey-text-question-type/survey-text-question-type";
import {SurveySectionQuestionType} from "./components/survey-section-question-type/SurveySectionQuestionType";
import MultipleOptionsDraggable from "./components/MultipleOptionsDraggable";
import _ from "lodash";
import SliderQuestionType from "./components/SliderQuestionType";
import {getFunctions, httpsCallableFromURL} from "firebase/functions";
import {firebaseApp} from "../../utils/firebaseApp";
import {questionItems} from "../../utils/question-items";
import {Circle} from "@mui/icons-material";

const AddQuestionModal = ({
                              setOpenAddQuestionModal,
                              openModal,
                              saveSurvey,
                          }) => {
    const auth = getAuth();
    const [question, setQuestion] = useState("");
    const [questionType, setQuestionType] = useState(enums.TEXT);
    const {
        state: {questionObjects, surveyObject, questionObjectToEdit},
        dispatch,
    } = useContext(AppContext);
    const user = auth.currentUser;
    const [isCreating, setIsCreating] = useState(false);
    const [orderNumber, setOrderNumber] = useState(1);
    const [additionalFields, setAdditionalFields] = useState(null);
    const [isRequired, setIsRequired] = useState(false);
    const [textFieldActive, setTextFieldActive] = useState(false);
    const [questionTypeActive, setQuestionTypeActive] = useState(false);
    const functions = getFunctions(firebaseApp);
    const getTranslation = httpsCallableFromURL(functions, process.env.REACT_APP_SERVER_URL + "getTranslation");

    // console.log('questionObjects')
    // console.log(questionObjects)

    // set fields if question is being edited
    useEffect(() => {
        if (openModal) {
            if (questionObjectToEdit) {
                console.log("questionObjectToEdit");
                console.log(questionObjectToEdit);
                setQuestion(questionObjectToEdit.question);
                setQuestionType(questionObjectToEdit.type);
                setOrderNumber(questionObjectToEdit.orderNumber);
                setIsRequired(questionObjectToEdit.isRequired);
                console.log(questionObjectToEdit.isRequired);
            } else {
                setQuestionType(enums.TEXT)
            }
        }
    }, [questionObjectToEdit, openModal]);

    // set order number
    useEffect(() => {
        if (questionObjects && questionObjects.length > 0 && surveyObject) {
            const questionsForCurrentSurvey1 = getQuestionsForCurrentSurvey();
            if (questionsForCurrentSurvey1.length === 0) return;
            const newOrderNumber =
                parseInt(
                    sortArrayByNumericObjectProperty(
                        questionsForCurrentSurvey1,
                        "orderNumber",
                    )[questionsForCurrentSurvey1.length - 1].orderNumber,
                ) + 1;
            setOrderNumber(
                questionsForCurrentSurvey1.length >= 1 ? newOrderNumber : 1,
            );
        }
    }, [openModal]);

    const handleSetQuestion = (e) => {
        setQuestion(e.target.value);
    };

    const handleSetQuestionType = (e) => {
        setQuestionType(e.target.value);
    };

    function countOfVoiceQuestionsForCurrentSurvey() {
        return questionObjects.filter((questionObject) => {
            return (
                questionObject.surveyUid === surveyObject.uid &&
                questionObject.type === "voice"
            );
        }).length;
    }

    function getQuestionsForCurrentSurvey() {
        return questionObjects.filter((questionObject) => {
            return questionObject.surveyUid === surveyObject.uid;
        });
    }

    function createQuestionObject() {
        let questionObjectTemp = {
            uid: questionObjectToEdit ? questionObjectToEdit.uid : uuid(),
            question: question,
            orderNumber: questionObjectToEdit
                ? questionObjectToEdit.orderNumber
                : orderNumber,
            type: questionType,
            userUid: auth.currentUser.uid,
            surveyUid: surveyObject.uid,
            // surveyName: surveyObject.surveyName,
            languageObject: questionObjectToEdit
                ? questionObjectToEdit.languageObject
                : {
                    Name: "English (United States)",
                    BCP47: "en-US",
                    Native: "English",
                },
            isRequired: false,
        };
        questionObjectTemp = Object.assign(questionObjectTemp, additionalFields);
        return questionObjectTemp;
    }

    async function copyNewQuestionToEachLanguage(questionObject) {
        const originalUid = _.cloneDeep(questionObject.uid);

        if (questionObjectToEdit) {
            let questionObjectsUpdated = getArrayOfObjectsWithRemovedItem(
                questionObjects,
                "uid",
                questionObject.uid,
            );
            await addUpdateQuestionToDb(questionObject);
            dispatch({
                questionObjects: sortArrayByNumericObjectProperty(
                    [...questionObjectsUpdated, questionObject],
                    "orderNumber",
                ),
            });
        } else {
            let updatedObjects = [];
            let i = 0;
            for (const language of surveyObject.languages) {
                console.log(language);
                console.log(i);
                questionObject.uid = i === 0 ? originalUid : uuid();
                questionObject.originalReferenceUid = originalUid;
                questionObject.languageObject = language;

                if (language.BCP47 !== "en-US") {
                    questionObject.question = (await getTranslation({
                        text: questionObject.question,
                        targetLanguageISOCode: language.BCP47
                    })).data;
                }
                await addUpdateQuestionToDb(questionObject);
                console.log("questionObj  ect lng");
                console.log(questionObject);
                updatedObjects.push(_.cloneDeep(questionObject));
                i += 1;
            }
            dispatch({
                questionObjects: sortArrayByNumericObjectProperty(
                    [...questionObjects, ...updatedObjects],
                    "orderNumber",
                ),
            });
        }
    }

    async function handleAddQuestion() {
        setIsCreating(true);
        if (questionType === "" || !orderNumber) {
            toast.error("Please fill out all of the fields");
        } else if (countOfVoiceQuestionsForCurrentSurvey() > 10) {
            toast.error("You cannot have more than 10 voice questions in one survey");
        } else {
            closeModal();
            toast.info(questionObjectToEdit ? 'Updating question...' : 'Creating question...')
            saveSurvey();
            let questionObject = createQuestionObject();
            if (
                questionObject.type === enums.SECTION ||
                enums.LOCATE_USER === questionObject.type
            )
                questionObject.question = additionalFields.title;
            questionObject.isRequired = isRequired;
            console.log("questionObject pre copy");
            console.log(questionObject);
            try {
                await copyNewQuestionToEachLanguage(questionObject);
                // closeModal();
                setIsCreating(false);
                toast.success(`Question added!`);
                // toast.info(`Want to add another question? Copy the parameters by clicking copy icon next to edit `)
            } catch (e) {
                toast.error('Adding failed, please try again a bit later!')
                console.log(e);
            }
        }
    }

    function resetFields() {
        dispatch({questionObjectToEdit: null});
        setQuestion("");
        setQuestionType("");
        setAdditionalFields(null);
        setIsRequired(false);
    }

    function closeModal() {
        setOpenAddQuestionModal(false);
        resetFields();
    }

    return (
        <Dialog
            open={openModal}
            aria-labelledby="form-dialog-title"
            className="add-question-to-survey-modal"
            onBackdropClick={() => closeModal()}
        >
            {/*<DialogTitle id="form-dialog-title">{editMode ? "Edit a question" : <strong>Add a segment</strong>}</DialogTitle>*/}
            <DialogContent
                sx={{
                    width: "90%",
                    marginTop: 2,
                    margin: "auto!important",
                    display: "block",
                    maxWidth: "700px",
                    padding: "34px 10px",
                    position: "relative",
                }}
            >
                <Stack direction="column">
                    {/*<Grid container>*/}
                    {/*    <Grid item xs={12}>*/}
                    {/*        <span className={`question-type-label  ${questionTypeActive && "active"}`}>Type</span>*/}
                    {/*    </Grid>*/}
                    {/*    {questionItems?.map(item => {*/}
                    {/*        const Icon = item?.icon || null;*/}
                    {/*        return (*/}
                    {/*          <Grid item xs={3} key={item?.value} sx={{p: 1.5, display: "flex", flexDirection: "column"}}>*/}
                    {/*              <Stack*/}
                    {/*                direction={"column"} gap={1} alignItems={"center"} justifyContent={"center"}*/}
                    {/*                sx={{border: 1, borderColor: "divider", borderRadius: 4, py: 1.5, flex: 1}}*/}
                    {/*              >*/}
                    {/*                  {Icon ? <Icon /> : null}*/}
                    {/*                  <Typography fontSize={12} align={"center"}>{item?.name}</Typography>*/}
                    {/*              </Stack>*/}
                    {/*          </Grid>*/}
                    {/*        )*/}
                    {/*    })}*/}
                    {/*</Grid>*/}
                    <div>
                        <FormControl style={{display: "flex", minWidth: "-webkit-fill-available"}}>
                            <span className={`question-type-label  ${questionTypeActive && "active"}`}>Type</span>
                            <TextField
                                InputProps={{padding: "10px"}}
                                SelectProps={{padding: "10px"}}
                                labelId="demo-simple-select-label"
                                id="select"
                                value={questionType}
                                onChange={handleSetQuestionType}
                                required
                                select
                                margin={"normal"}
                                onFocus={() => setQuestionTypeActive(true)}
                                onBlur={() => setQuestionTypeActive(false)}
                            >
                                {questionItems?.map(item => {
                                    const Icon = item?.icon || null;
                                    return (
                                          <MenuItem key={item.value} value={item.value}>
                                                <Stack direction={"row"} gap={1} alignItems={"center"}>
                                                    {Icon ? <Icon /> : null}
                                                    <Typography>{item?.name}</Typography>
                                                </Stack>
                                          </MenuItem>
                                    )
                                })}
                            </TextField>
                        </FormControl>
                        {![enums.SECTION, enums.LOCATE_USER].includes(questionType) ? (
                            <div style={{marginTop: "15px"}}>
                <span
                    className={`question-type-label ${textFieldActive && "active"}`}
                >
                  Question
                </span>
                                <TextField
                                    sx={{height: "auto"}}
                                    margin="dense"
                                    id="question"
                                    // label="Question"
                                    InputProps={{padding: "10px"}}
                                    type="text"
                                    fullWidth autoFocus
                                    value={question}
                                    onChange={handleSetQuestion}
                                    required
                                    onFocus={() => setTextFieldActive(true)}
                                    onBlur={() => setTextFieldActive(false)}
                                    multiline // Enable multiline input
                                    maxRows={4} // Optional: Set a maximum number of rows
                                />
                            </div>
                        ) : null}
                    </div>
                </Stack>

                {(questionType === enums.MULTICHOICE ||
                    questionType === enums.DROPDOWN ||
                    questionType === enums.MAPINPUT ||
                    enums.CHECKBOXES === questionType) && (
                    <MultipleOptionsDraggable
                        setAdditionalFields={setAdditionalFields}
                        questionObjectToEdit={questionObjectToEdit}
                        shouldDrag={false}
                    />
                )}

                {enums.SLIDER === questionType && (
                    <SliderQuestionType
                        questionObjectToEdit={questionObjectToEdit}
                        setAdditionalFields={setAdditionalFields}
                    />
                )}

                {enums.VOICE === questionType && (
                    <SurveyVoiceQuestionType
                        doNotShowOptions={true}
                        setAdditionalFields={setAdditionalFields}
                        questionObjectToEdit={questionObjectToEdit}
                    />
                )}
                {enums.TEXT === questionType && (
                    <SurveyTextQuestionType
                        doNotShowOptions={true}
                        questionObjectToEdit={questionObjectToEdit}
                        setAdditionalFields={setAdditionalFields}
                    />
                )}

                {/*{enums.MULTICHOICE === questionType*/}
                {/*&& <SurveyMultipleChoiceQuestionType additionalFields={additionalFields}*/}
                {/*                                     questionObjectToEdit={questionObjectToEdit}*/}
                {/*                                     setAdditionalFields={setAdditionalFields}/>}*/}
                {enums.SECTION === questionType && (
                    <SurveySectionQuestionType
                        questionObjectToEdit={questionObjectToEdit}
                        setAdditionalFields={setAdditionalFields}
                        setIsCreating={setIsCreating}
                    />
                )}
                {enums.LOCATE_USER === questionType && (
                    <SurveySectionQuestionType
                        questionObjectToEdit={questionObjectToEdit}
                        setAdditionalFields={setAdditionalFields}
                        locateUser={true}
                        setIsCreating={setIsCreating}
                    />
                )}

                <Stack direction={"row"} alignItems="center"
                       sx={{visibility: questionType === enums.SECTION ? 'hidden' : 'visible'}}>
                    <Switch
                        color="azure"
                        checked={isRequired}
                        onChange={() => setIsRequired(!isRequired)}
                    />
                    <Typography variant={"body1"} className="regular">
                        Required
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => closeModal()} color="secondary">
                    Cancel
                </Button>
                <Button
                    onClick={handleAddQuestion}
                    variant={"contained"}
                    color={"confirmation"}
                    disabled={isCreating}
                    sx={{
                        backgroundColor: "#2FA642",
                        "&:hover": {
                            backgroundColor: "#219333",
                            transition: "all 0.3s ease-in-out",
                            webkitTransition: "0.3s",
                            transform: "translateY(-4px)",
                            boxShadow: "none",
                        },
                    }}
                >
                    {questionObjectToEdit
                        ? isCreating
                            ? "Saving..."
                            : "Save"
                        : isCreating
                            ? "Creating..."
                            : "Create"}
                </Button>

                {/*{editMode*/}
                {/*&& <Button onClick={handleEditQuestion} sx={{backgroundColor: '#1DAEFF'}}>*/}
                {/*  Edit*/}
                {/*</Button>}*/}
            </DialogActions>
        </Dialog>
    );
};

export default AddQuestionModal;
