import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {App} from './App';
import reportWebVitals from './reportWebVitals';
import ConfigContextProvider from './contexts/config-context';
import {BrowserRouter as Router} from 'react-router-dom';
import {theme} from "./utils/Theme";
import {ThemeProvider} from "@mui/material/styles";
import {AuthContextProvider} from "./contexts/auth_context";
import {AppContextProvider} from "./utils/AppContext";
import InterfaceContextProvider from "./contexts/interface-context";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ConfigContextProvider>
        <ThemeProvider theme={theme}>
          <AuthContextProvider>
            <AppContextProvider>
              <InterfaceContextProvider>
                <App/>
              </InterfaceContextProvider>
            </AppContextProvider>
          </AuthContextProvider>
        </ThemeProvider>
      </ConfigContextProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
