import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Typography} from "@mui/material";
import chartIcon from "../../../../assets/icons/chart.png";
import {AppContext} from "../../../../utils/AppContext";

export const ViewSummaryButton = ({surveyUid}) => {
    const {state: {surveys}, dispatch} = useContext(AppContext);
    const navigate = useNavigate()

    return (
        <Button
            size={"large"}
            variant={"contained"}
            fullWidth
            sx={{
                marginTop: "8px",
                bgcolor: "#6271FF",
                borderRadius: 2,
                padding: "12px 20px",
                boxShadow: "none",
                "&:hover": {
                    bgcolor: "#5161ff",
                    transition: "all 0.3s ease-in-out",
                    webkitTransition: "0.3s",
                    transform: "translateY(-4px)",
                    boxShadow: "none",
                },
            }}
            onClick={() => {
                dispatch({surveyObjectToDisplay: surveys.some(survey => survey.uid === surveyUid) ? surveys.filter(survey => survey.uid === surveyUid)[0] : null})
                navigate('/summaries')
            }}
        >
            <img
                src={chartIcon}
                alt={"chart icon"}
                style={{width: "18px", height: "18px", marginRight: "8px"}}
            />
            <Typography
                className={"white-text"}
                sx={{fontWeight: 600, fontSize: "1em"}}
            >
                View Survey Summary
            </Typography>
        </Button>
    );
};
