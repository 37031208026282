import {Button, Divider, Stack, TextField, Typography} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {enums} from "../../../../utils/enums";
import {storage} from "../../../../utils/firebaseApp";
import uuid from "react-uuid";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";


export const SurveySectionQuestionType = ({setAdditionalFields, questionObjectToEdit, locateUser, setIsCreating}) => {
    const [title, setTitle] = useState('');
    const [subTitle, setSubTitle] = useState('');
    const [text, setText] = useState('');
    const [link, setLink] = useState('');
    const [imgUrl, setImgUrl] = useState('');
    const inputRef = useRef(null);
    const [isUploading, setIsUploading] = useState(false)
    useEffect(() => {
        if (questionObjectToEdit) {
            questionObjectToEdit.title && setTitle(questionObjectToEdit.title)
            questionObjectToEdit.subTitle && setSubTitle(questionObjectToEdit.subTitle)
            questionObjectToEdit.text && setText(questionObjectToEdit.text)
            questionObjectToEdit.link && setLink(questionObjectToEdit.link)
            questionObjectToEdit.imgUrl && setImgUrl(questionObjectToEdit.imgUrl)
        }
    }, [questionObjectToEdit])

    useEffect(() => {
    }, [inputRef.current]);

    useEffect(() => {
        let additionalFields = {
            title: title,
            subTitle: subTitle,
            text: text,
            link: link,
            imgUrl: imgUrl,
            question: title
        }
        if (locateUser) additionalFields['type'] = enums.LOCATE_USER

        setAdditionalFields(additionalFields)
    }, [title, subTitle, text, link, imgUrl])

    function openSelectAndUpload(img) {
        setIsCreating(true)
        const storageRef = ref(storage, `${uuid()}.jpeg`);
        uploadBytes(storageRef, img).then((snapshot) => {
            getDownloadURL(snapshot.ref).then(async function (downloadURL) {
                console.log(downloadURL)
                setImgUrl(downloadURL)
            });
        });
        setIsCreating(false)
    }

    return (
        <div>
            <TextField
                sx={{height: '56px'}}
                margin="dense"
                id="question"
                label="Title"
                InputProps={{padding: '10px'}}
                type="text"
                fullWidth
                value={title}
                onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
                sx={{height: '56px'}}
                margin="dense"
                id="question"
                label="Description"
                InputProps={{padding: '10px'}}
                type="text"
                fullWidth
                value={subTitle}
                onChange={(e) => setSubTitle(e.target.value)}
            />
            <Typography variant={'h5'} sx={{marginTop: 2}}>Optional</Typography>
            <Divider sx={{marginBottom: 2}}/>
            {/*<Typography sx={{fontSize: 14}}>Upload photo</Typography>*/}
            <Stack gap={2} justifyItems={'center'} alignItems={'center'} justifyContent={'center'}>
            <Button variant={"outlined"} sx={{marginBottom: 2}} fullWidth>
                <label htmlFor={'upload-button'}>
                    {/*<AddAPhoto/>*/}
                    {!imgUrl ? "Upload image" : "Reupload"}
                    <input type="file" accept="image/*" id="upload-button" style={{display: 'none'}}
                           onChange={(e) => openSelectAndUpload(e.target.files[0])} key={'addImage'}/>
                </label>
            </Button>
            {imgUrl ? <img src={imgUrl} style={{width: '70px'}} alt={'uploaded image'}/> : null}
            </Stack>

            <Divider sx={{marginTop: 2}}/>
            <Typography sx={{fontSize: 14, marginTop: 2}}>
                In case you have terms&conditions or some external additional materials you want
                people to know about you can link it below </Typography>
            <TextField
                // sx={{height: '30px'}}
                margin="dense"
                id="question"
                label="Bottom explainer text"
                InputProps={{padding: '5px'}}
                type="text"
                fullWidth
                value={text}
                onChange={(e) => setText(e.target.value)}
            />
            <TextField
                // sx={{height: '30px'}}
                margin="dense"
                id="question"
                label="Bottom explainer url"
                InputProps={{padding: '5px'}}
                type="text"
                fullWidth
                error={link === "" ? false : !link.includes('https://')}
                helperText={link !== "" && !link.includes('https://') ? "Link must include https://" : null}
                value={link}
                onChange={(e) => setLink(e.target.value)}
            />
        </div>
    )
}
