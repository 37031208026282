import { Stack, Switch, Typography } from '@mui/material';
import React from 'react';


export const ToggleSwitchButton = ({onToggle, title, value, className}) => {

    return(
        <Stack direction={'row'}  
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        className={`toggle-switch-button ${className && className}`}>

            <Switch className='toggle-switch' value={value} onChange={() => onToggle()} color="azure"/> 
            <Typography variant={"body1"} className='toggle-option-title'>{title}</Typography>

        </Stack>
    )

}