import React, {useState} from "react";
import {Card, CardContent, CardHeader, Fade, IconButton, Stack} from "@mui/material";
import {grey} from "@mui/material/colors";
import {KeyboardArrowDownOutlined} from "@mui/icons-material";

export const CardItem = ({ title, avatar, subheader, secondary, children }) => {
    const [isOpen, setIsOpen] = useState(true);
    return (
        <Card sx={{
            borderRadius: 2,
            border: 1,
            borderColor: grey[200],
            bgcolor: "#FFF",
            boxShadow: 5,
        }}>
            <CardHeader
                sx={{ p: 2, borderBottom: isOpen ? 1 : 0, borderColor: "divider" }}
                avatar={avatar}
                title={title}
                subheader={subheader}
                action={
                    <Stack direction={"row"} gap={1} alignItems={"center"}>
                        {secondary}
                        <IconButton aria-label="settings" onClick={() => setIsOpen(!isOpen)}>
                            <KeyboardArrowDownOutlined />
                        </IconButton>
                    </Stack>
                }
            />
            {
                isOpen ?
                    <Fade in={isOpen} timeout={500}>
                        <CardContent sx={{ p: 2, height: "100%" }}>
                            {children}
                        </CardContent>
                    </Fade> : <></>
            }

        </Card>
    )
}