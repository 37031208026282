import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {QuestionGraphWrapper} from "../analysis-page/components/question-graphs/QuestionGraphWrapper";
import {AppContext} from "../../utils/AppContext";
import {Button, Stack, TextField, useTheme} from "@mui/material";
import {TitleDate} from "../../components/shared-components/text-formating/title-date/title-date";
import uuid from "react-uuid";
import SaveIcon from "@mui/icons-material/Save";
import {toast} from "react-toastify";
import {auth, db} from "../../utils/firebaseApp";
import {ref} from "firebase/storage";
import {doc, setDoc} from "firebase/firestore";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '98vw',
    height: '98vh',
    bgcolor: 'background.paper',
    p: 4,
    overflowY: 'scroll',
};

export default function CreateReportModal({open, setOpen, activeSurvey}) {
    const {
        state: {reports, questionObjects, answerObjects},
        dispatch
    } = useContext(AppContext);
    const [uniqueQuestionObjectsForSurveyToDisplay, setUniqueQuestionObjectsForSurveyToDisplay] = useState([]);
    const [questionsToDisplayInAReport, setQuestionsToDisplayInAReport] = useState([]);
    const [reportName, setReportName] = useState("New Report " + new Date().toLocaleDateString());
    const theme = useTheme();
    const handleClose = () => {
        setOpen(false)
    };

    useEffect(() => {
        if (!activeSurvey) return;
        let allQuestionObjectsForSurvey = questionObjects.filter(
            (question) => question?.surveyUid === activeSurvey?.uid,
        );
        let uniqueQuestionObjectsForSurvey = allQuestionObjectsForSurvey.filter(
            (question) =>
                question?.languageObject.BCP47 === activeSurvey?.languages[0].BCP47,
        ).filter(question => question?.type !== "section");

        setUniqueQuestionObjectsForSurveyToDisplay(uniqueQuestionObjectsForSurvey);
        setQuestionsToDisplayInAReport(uniqueQuestionObjectsForSurvey)
    }, [activeSurvey, answerObjects])

    function getRemoveFromReport(questionUid) {
        function removeVisualFromReport() {
            setQuestionsToDisplayInAReport(questionsToDisplayInAReport.filter(question => question.uid !== questionUid))
        }

        return (
            <Button variant={'text'} key={questionUid} sx={{color: theme.palette.error.main}}
                    onClick={() => removeVisualFromReport()}>
                remove from report
            </Button>
        )
    }

    async function saveReport() {
        try {
            let report = {
                surveyUid: activeSurvey.uid,
                name: reportName,
                questions: questionsToDisplayInAReport.map(question => question.uid),
                uid: uuid(),
                userUid: auth.currentUser.uid
            }
            reports.push(report)
            await setDoc(doc(db, "reports", report.uid), report)
            dispatch({reports: [...reports]})

            setOpen(false)
        } catch (e) {
            console.log(e)
            toast.error("Error saving report")
        }
    }

    function resetReport() {
        setQuestionsToDisplayInAReport(uniqueQuestionObjectsForSurveyToDisplay)
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <Box>
                        <TitleDate title="Create Report"/>
                        <Typography variant={'body1'}>
                            Choose the visualizations you want to have on this report and remove all the ones you don't
                            need.
                        </Typography>
                    </Box>
                    <Stack direction={'row'} spacing={2}>
                        <Button sx={{color: theme.palette.confirmation.main}} onClick={() => saveReport()}>
                            <SaveIcon/> Save</Button>
                        <Button sx={{color: theme.palette.error.main}} onClick={() => resetReport()}> Reset</Button>
                    </Stack>
                </Stack>
                <Box sx={{marginTop: 2, marginBottom: 2}}>
                    <TextField
                        id="standard-basic"
                        variant="standard"
                        value={reportName}
                        onChange={(e) => {
                            setReportName(e.target.value)
                        }}
                        inputProps={{
                            sx: {fontSize: 30, color: "#5271FF"},
                            className: "survey-title",
                        }}
                        sx={{fontSize: 30, color: "#5271FF"}}
                    />
                </Box>
                {questionsToDisplayInAReport
                    .sort((a, b) => a.orderNumber - b.orderNumber)
                    ?.map((item, index) =>
                        <QuestionGraphWrapper
                            key={item.id}
                            question={item}
                            index={index + 1}
                            removeFromReport={getRemoveFromReport}
                        />
                    )}
            </Box>
        </Modal>
    );
}
