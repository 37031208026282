import {AddBoxOutlined} from '@mui/icons-material';
import React from 'react';

export const AddBox = ({label, size, onClick, className, icon, disabled}) => {

  return (
    <div className={`add-box-wrap ${size && size} ${onClick && !disabled && 'clickable'} ${disabled && 'disabled'} ${className && className}`} onClick={onClick && !disabled && onClick}>
      {icon ? icon : <AddBoxOutlined className='icon-wrap'/>}
      <p>{label}</p>
    </div>
  )
}
