import {ArrowDownward, ArrowUpward} from '@mui/icons-material';
import React, {useContext, useState} from 'react';
import {ColoredButton} from '../../components/shared-components/colored-button/colored-button';
import {AudioQuestionForm} from './components/audio-question-form/audio-question-form';
import {AppContext} from "../../utils/AppContext";


export const QuestionnairePage = () => {
  const {state: {surveys, surveyObject, questionObjects}, dispatch} = useContext(AppContext);
  const [questionIndex, setQuestionIndex] = useState(0);
  const changeIndex = (a) => {
    console.log(a);
    console.log('index', questionIndex + a);
    if ((questionIndex + a) < 0 || (questionIndex + a) > questionObjects.length - 1) {

    } else {
      setQuestionIndex(questionIndex + a);
    }
  }

  if (!questionObjects || questionObjects.length === 0) return null

  return (
    <div className=' questionnaire-page-wrap flex column a-center jc-sb'>

      <AudioQuestionForm questionText={questionObjects[questionIndex].question} number={questionIndex + 1}
                         key={`questionnaire-page-question-${questionIndex}`}/>
      <div className='questionnaire-page-navigation-wrap flex row a-center jc-sb'>
        {questionIndex > 0 &&
        <ColoredButton className='back-button' icon={<ArrowUpward/>} color="#C4C4C4" width="40px" height="40px"
                       onClick={() => changeIndex(-1)}/>}
        <ColoredButton icon={<ArrowDownward/>} color="#36DF1B" width="235px" height="40px"
                       onClick={() => changeIndex(1)}/>
      </div>
    </div>
  )
}
