import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {grey} from "@mui/material/colors";


export const LongSelect = ({
                             id,
                             attribute,
                             title,
                             labelId,
                             value,
                             variant,
                             label,
                             menuItems,
                             selectedOption,
                             setSelectedOption,
                             disabled
                           }) => {
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className='long-select-wrap'>
      <FormControl variant={variant || "standard"} sx={{minWidth: 120, width: '100%'}}>
        <InputLabel id={id}>{title}</InputLabel>
        <Select
          attribute={attribute}
          labelId={labelId}
          id={id}
          value={selectedOption}
          sx={{ bgcolor: "transparent", "&:hover": { bgcolor: grey[100]} }}
          onChange={(e) => handleChange(e)}
          label={label}
          disabled={disabled}
          MenuProps={{
            sx: {
              maxWidth: {xs: "calc(100vw - 68px)", md: "calc(100vw - 68px - 260px)"}
            }
          }}
        >
          {
            menuItems.map((item, index) => (
              <MenuItem key={`menu-item-select-${item}-${index}`} sx={{wordBreak: "break", textWrap: "wrap"}} value={item.value}>{item.name}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </div>
  );
}
