import {Button, Tooltip} from "@mui/material";
import {DeleteOutline, DriveFileRenameOutline, InsertLink, QrCodeScanner,} from "@mui/icons-material";
import {deleteSurvey, getObjectFromArrayThatMatchesValue,} from "../../../../utils/sharedFunctions";
import React, {useContext, useMemo, useState} from "react";
import {blue, grey} from "@mui/material/colors";
import {toast} from "react-toastify";
import {AppContext} from "../../../../utils/AppContext";
import {enums} from "../../../../utils/enums";
import {useNavigate} from "react-router-dom";
import {ShareQrCodeModal} from "../../../../components/survey-qrcode/ShareQrCodeModal";
import {confirm} from "react-confirm-box";
import {confirmDialogOptions} from "../../../../components/ConfirmDialog";
import ViewIcon from "../../../../assets/icons/view-survey.png";

export function ShowIcon({iconPath, width = "28px", height = "28px"}) {
    return (
        <img
            src={iconPath}
            alt="icon"
            style={{width, height}} // Correct usage here
        />
    );
}

export const SurveyActionButtons = ({survey, showSettings}) => {
    const {
        state: {surveys, answerObjects, questionObjects, reports},
        dispatch,
    } = useContext(AppContext);
    const navigate = useNavigate();
    const [openSurveyQrCode, setOpenSurveyQrCode] = useState();

    const url = useMemo(() => {
        if (survey)
            return `${process.env.REACT_APP_CLIENT_URL}survey/${survey?.uid}`;
        return null;
    }, [survey]);

    const copyToClipboard = (url) => {
        try {
            navigator.clipboard.writeText(url);
            toast.success("URL copied successfully");
        } catch (error) {
            toast.success("Error copying URL");
        }
    };

    const handleEdit = (surveyUid) => {
        // const surveyUid = e.currentTarget.getAttribute("survey-uid");
        dispatch({
            surveyObject: getObjectFromArrayThatMatchesValue(
                surveys,
                "uid",
                surveyUid,
            ),
        });
        dispatch({viewToShow: enums.CREATESURVEY});
        dispatch({edit: true});
        navigate("/");
    };

    return (
        <>
            <ShareQrCodeModal
                open={openSurveyQrCode}
                handleClose={() => setOpenSurveyQrCode(false)}
                urlToDisplay={`${window.location.origin}/survey/${survey?.uid}`}
                title={survey.surveyName}
                subtitle={"By sharing this link you're sharing it with the public"}
            />
            <Tooltip title={"View Survey"}>
                <Button sx={primaryStyle} component={"a"} href={url} target={"_blank"}>
                    <ShowIcon iconPath={ViewIcon}/>
                </Button>
            </Tooltip>
            <Tooltip title={"Copy link to survey"}>
                <Button sx={primaryStyle} onClick={() => copyToClipboard(url)}>
                    <InsertLink sx={{transform: `rotateY(0deg) rotate(-45deg)`}}/>
                </Button>
            </Tooltip>
            <Tooltip title={"View and download QR code"}>
                <Button sx={primaryStyle} onClick={() => setOpenSurveyQrCode(true)}>
                    <QrCodeScanner/>
                </Button>
            </Tooltip>
            <Tooltip title={"Edit survey"}>
                <Button
                    survey-uid={survey.uid}
                    onClick={() => handleEdit(survey.uid)}
                    sx={primaryStyle}
                >
                    <DriveFileRenameOutline />
                </Button>
            </Tooltip>
            <Tooltip title={"Delete survey"}>
                <Button
                    survey-uid={survey.uid}
                    sx={{...secondaryStyle, bgcolor: "none", borderWidth: 0}}
                    onClick={async () => {
                        const response = await confirm(
                            {
                                title: `Are you sure you want to delete this survey?`,
                                subtitle: `This action will delete everything you have pertaining to this survey.`,
                            },
                            confirmDialogOptions,
                        );
                        if (response === false) {
                            return;
                        }
                        await deleteSurvey(
                            survey.uid,
                            answerObjects,
                            questionObjects,
                            surveys,
                            dispatch,
                            survey,
                            reports
                        );
                    }}
                >
                    <DeleteOutline />
                    {/*<ShowIcon iconPath={DeleteIcon} width={21} height={24}/>*/}
                </Button>
            </Tooltip>
            {/*{*/}
            {/*  showSettings &&*/}
            {/*  <Button sx={secondaryStyle} onClick={() => copyToClipboard(url)}>*/}
            {/*    <MoreVert/>*/}
            {/*  </Button>*/}
            {/*}*/}
        </>
    );
};

const primaryStyle = {
    bgcolor: blue[50],
    color: blue[300],
    borderRadius: 2,
    border: 1,
    borderColor: blue[100],
    width: "auto !important",
    minWidth: "auto !important",
    "&:hover": {
        bgcolor: blue[100],
        color: blue[400],
    },
};
const secondaryStyle = {
    bgcolor: grey[200],
    color: grey[500],
    border: 1,
    borderColor: grey[300],
    borderRadius: 2,
    width: "auto !important",
    minWidth: "auto !important",
    "&:hover": {
        // bgcolor: grey[300],
        color: grey[600],
    },
};
