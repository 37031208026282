import {AddBox, IndeterminateCheckBox} from '@mui/icons-material';
import {Input, Stack} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';


export const IncreaseDecreaseValue = ({value, disabled = false}) => {
  const valueRef = useRef(value || 0);
  const [inputValue, setInputValue] = useState(value || 0);
  const onIncrease = () => {
    // console.log("VR",valueRef.current);
    valueRef.current += 1;
    setInputValue(valueRef.current);
    // console.log(valueRef.current);

  }
  const onDecrease = () => {
    valueRef.current -= 1;
    setInputValue(valueRef.current);
    // console.log(valueRef.current);

  }
  useEffect(() => {
  }, [inputValue]);
  return (
    <Stack
      className='increase-decrease-value-wrap'
      direction={'row'}
      justifyContent="flex-start"
      alignItems="center"
      spacing={0}>
      <IndeterminateCheckBox className='increase-decrease-value-button clickable' onClick={() => onDecrease()}/>
      <Input
        // ref={valueRef}
        value={inputValue}
        type='integer'
        sx={{width: '30px'}}
        // value={valueRef.current}
        className='increase-decrease-input'
        onChange={(e) => {
          valueRef.current = parseInt(e.target.value);
          setInputValue(valueRef.current)
        }}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            // console.log("enter",event.target.value);
            valueRef.current = parseInt(event.target.value);
            setInputValue(valueRef.current);
          }
        }
        }
        onBlur={(e) => {
          valueRef.current = parseInt(e.target.value);
          setInputValue(valueRef.current)
        }}
        disabled={disabled}/>
      <AddBox className='increase-decrease-value-button clickable' onClick={() => onIncrease()}/>
    </Stack>
  )

}