import * as React from "react";
import {Marker} from "react-map-gl";

export function CustomMarker(props) {
  const [showPopup, togglePopup] = React.useState(false);

  const {longitude, latitude, viewport, name, setViewport, setMarkerToDisplay, icon, locationObject} = props;

  return (
    // <div style={{transition: "ease-in", zIndex: 1}}>
    <Marker latitude={latitude} longitude={longitude} offsetLeft={-20} offsetTop={-10}>
      <div
        onClick={() => {
          togglePopup(!showPopup)
          // setViewport({longitude: locationObject.long,
          // latitude: locationObject.lat,
          // zoom: viewport.zoom})
          setMarkerToDisplay(locationObject)
        }}>{icon}</div>
    </Marker>
  );
}
