import React from 'react';
import {Box, Grid, Stack, Typography} from "@mui/material";

const QuestionTitle = ({question, orderNumber, surveyObject}) => {
  return (
    <Box sx={{my: 2}}>
      <Grid item xs={12} sx={{padding: 2}}>
        <Stack direction="row" justifyContent={"flex-start"} gap="10px">
          {/*<Typography variant={"h3"}*/}
          {/*            sx={{color: "#5271FF", marginLeft: 4}}*/}
          {/*            className={`accent bold`}><strong>{orderNumber}.</strong></Typography>*/}
          <Typography variant={"h3"}>{question}</Typography>
        </Stack>
      </Grid>
    </Box>
  );
};

export default QuestionTitle;

