import React from 'react';
import {Divider, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import {DeleteOutline, DriveFileRenameOutline, QrCodeScanner} from "@mui/icons-material";
import PropTypes from "prop-types";

const ReportItem = ({report, setOpenSurveyQrCode, setActiveReport, deleteReport }) => {
    return (
        <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography
                fontWeight={"bold"}
                sx={{textWrap: "nowrap", minWidth: "300px"}}
            >
                {report?.name}
            </Typography>
            <Stack direction={"row"} gap={1}>
                <Tooltip title={"View and Share Report"}>
                    <IconButton
                      color={"primary"}
                      onClick={() => {
                        setOpenSurveyQrCode(true)
                        setActiveReport(report)
                      }}
                    >
                      <QrCodeScanner />
                    </IconButton>
                </Tooltip>
              <Tooltip title={"Edit Report"}>
                  <IconButton>
                      <DriveFileRenameOutline />
                  </IconButton>
              </Tooltip>
              <Tooltip title={"Delete Report"}>
                  <IconButton onClick={()=> deleteReport(report.uid)}>
                      <DeleteOutline />
                  </IconButton>
              </Tooltip>
            </Stack>
            <Divider/>
        </Stack>
    );
};

export default ReportItem;

ReportItem.propTypes = {
    report: PropTypes.object,
    setOpenSurveyQrCode: PropTypes.func,
    setActiveReport: PropTypes.func
}
