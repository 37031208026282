import {Input} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {CheckButton} from '../../../../components/shared-components/check-button/check-button';
import {ChipTags} from '../../../../components/shared-components/chip-tags/chip-tags';
import {ToggleSwitchButton} from '../../../../components/shared-components/toggle-switch-button/toggle-switch-button';


export const SurveyTextQuestionType = ({setAdditionalFields, doNotShowOptions, questionObjectToEdit}) => {
  const [limitResponses, setLimitResponses] = useState(false);
  const [googleReview, setGoogleReview] = useState(false);
  const [googleReviewPositiveCheck, setGoogleReviewPositiveCheck] = useState(false);
  const [forwardNegative, setForwardNegative] = useState(false);
  const [addSuggestedTopicVisible, setAddSuggestedTopicVisible] = useState(true);
  const inputRef = useRef(null);

  useEffect(() => {
  }, [inputRef.current]);

  if (doNotShowOptions) return null

  return (
    <div className='survery-text-question-type question-type-wrap'>
      <ToggleSwitchButton
        title={['Limit negative responses',
          limitResponses && <Input className='survey-text-question-input-word-count' type='number' placeholder='Number'
                                   sx={{maxWidth: '80px', margin: "0 10px"}}
                                   onChange={(e) => inputRef.current = e.target.value}/>,
          limitResponses && 'words']}
        onToggle={() => setLimitResponses(!limitResponses)}
        value={forwardNegative}/>

      <ToggleSwitchButton
        title="Ask respondent to post their response to Google Reviews"
        onToggle={() => setGoogleReview(!googleReview)}
        value={googleReview}/>

      {googleReview && <CheckButton
        className={'move-right'}
        checked={googleReviewPositiveCheck}
        onUnCheck={() => setGoogleReviewPositiveCheck(false)}
        onCheck={() => setGoogleReviewPositiveCheck(true)}
        title="Only prompt users with positive sentiments in response"/>}

      <ToggleSwitchButton
        title="Forward negative responses to my e-mail"
        onToggle={() => setForwardNegative(!forwardNegative)}
        value={forwardNegative}/>

      <ToggleSwitchButton
        title="Add suggested topics list for users"
        onToggle={() => setAddSuggestedTopicVisible(!addSuggestedTopicVisible)}
        value={forwardNegative}/>

      {addSuggestedTopicVisible && <div className='survery-voice-question-chips'>
        <ChipTags inputActive placeholder="Enter suggested topic..." onDeleteSingle/>
      </div>}
    </div>
  )
}
