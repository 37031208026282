import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../../utils/AppContext";
import {enums} from "../../../../utils/enums";
import {VoiceQuestionGraph} from "./VoiceQuestionGraph";
import {Avatar, Stack, Typography} from "@mui/material";
import MultichoiceQuestion from "../../../survey-page/components/MultichoiceQuestion";
import {ImageQuestionPreview} from "./ImageQuestionPreview";
import {MultipleChoiceGraph} from "./MultipleChoiceGraph";
import {MapOfResponses} from "../../../project-summaries-page/components/MapOfResponses";
import {CardItem} from "../../../../components/card/CardItem";
import {blue} from "@mui/material/colors";

export const QuestionGraphWrapper = ({question, index, setOpenTopicsModal, setActiveQuestion, removeFromReport}) => {
    const {state: {answerObjects}, dispatch} = useContext(AppContext);
    const [answers, setAnswers] = useState([]);

    useEffect(() => {
        let questionAnswers = [];
        if (question) {
            const filteredQuestionAnswers = answerObjects?.filter(answer => (answer?.questionUid === question?.uid) || (answer?.originalReferenceUid === question?.uid))
            // questionAnswers.push(filtered)
            questionAnswers = [...questionAnswers, ...filteredQuestionAnswers]
        }
        setAnswers(questionAnswers);
    }, [question, answerObjects]);

    const GraphNotFound = <Stack direction={"column"} height={'390px'}>
        <Typography fontWeight={"bold"} variant={"h4"}>{question?.question}</Typography>
        <Typography variant={"subtitle2"}>No chart for this type of question ({question?.type})</Typography>
    </Stack>


    return (
        question
        && answers
        && <Stack direction={"column"} gap={1} sx={{my: 2}}>
            {
                {
                    "voice": <VoiceQuestionGraph setActiveQuestion={setActiveQuestion}
                                                 setOpenTopicsModal={setOpenTopicsModal} title={question?.question}
                                                 subtitle={"Voice question"} question={question}
                                                 answers={answers} index={index} secondaryAction={removeFromReport}/>,
                    "text": <VoiceQuestionGraph setActiveQuestion={setActiveQuestion}
                                                setOpenTopicsModal={setOpenTopicsModal} title={question?.question}
                                                subtitle={"Text question"} question={question}
                                                answers={answers} index={index} secondaryAction={removeFromReport}/>,
                    "multichoice": <MultipleChoiceGraph title={question?.question} subtitle={"Multiple choice question"}
                                                        question={question} answers={answers} index={index}
                                                        secondaryAction={removeFromReport}/>,
                    "checkboxes": <MultipleChoiceGraph title={question?.question} subtitle={"Checkbox question"}
                                                       question={question} answers={answers} index={index}
                                                       secondaryAction={removeFromReport}/>,
                    "dropdown": <MultipleChoiceGraph title={question?.question} subtitle={"Dropdown question"}
                                                     question={question}
                                                     answers={answers} index={index}
                                                     secondaryAction={removeFromReport}/>,
                    "image": <ImageQuestionPreview title={question?.question} subtitle={"Image question"}
                                                   question={question}
                                                   answers={answers} index={index} secondaryAction={removeFromReport}/>,
                    "locateuser": <MapOfResponses title={question?.question} subtitle={"Geo-locate user question"}
                                                  question={question} answers={answers} index={index}
                                                  secondaryAction={removeFromReport}/>,
                    "mapInput": <MapOfResponses title={question?.question} subtitle={"Map question"} question={question}
                                                answers={answers} index={index} secondaryAction={removeFromReport}/>
                    // "section": GraphNotFound,
                }[question?.type]
            }
        </Stack>
    )
}
