import {Stack, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {TitleDate} from "../../components/shared-components/text-formating/title-date/title-date";
import {AppContext} from "../../utils/AppContext";
import {MainLayout} from "../../layout/main/MainLayout";
import SurveyQuestionSelectorDrodowns from "../../components/shared-components/SurveyQuestionSelectorDrodowns";
import {countDistinctObjectsByDay, countTopics, saveSelectedSurveyAsCSV,} from "../../utils/sharedFunctions";
import {TotalParticipantChart} from "./components/TotalParticipantChart";
import {QuestionGraphWrapper} from "../analysis-page/components/question-graphs/QuestionGraphWrapper";
import {CardItem} from "../../components/card/CardItem";
import {Actions} from "./components/Actions";
import {collection, doc, getDocs, orderBy, query, updateDoc, where} from "firebase/firestore";
import {db} from "../../utils/firebaseApp";
import {toast} from "react-toastify";

export const ProjectSummariesPage = ({survey}) => {
    const {
        state: {edit, surveys, currentUser, surveyObjectToDisplay, questionObjects, answerObjects},
        dispatch
    } = useContext(AppContext);

    const [openTopicsModal, setOpenTopicsModal] = useState(false);
    const [topics, setTopics] = useState([]);
    const [selectedSurvey, setSelectedSurvey] = useState(surveys[0] ? surveys[0] : null);
    const [questionResponses, setQuestionResponses] = useState([]);
    const [totalParticipantsOverTime, setTotalParticipantsOverTime] = useState(null);
    const [totalResponses, setTotalResponses] = useState(null);
    const [languageResponses, setLanguageResponses] = useState(null);

    const [activeQuestion, setActiveQuestion] = useState(null);
    const [activeSurvey, setActiveSurvey] = useState(surveyObjectToDisplay ? surveyObjectToDisplay : surveys[0]);
    const [surveyQuestions, setSurveyQuestions] = useState([]);

    useEffect(() => {
        console.log("UPDATE ANSWERS");
        updateAnswers();
    }, []);

    useEffect(() => {
        if (!activeSurvey) return;
        console.log(activeSurvey)
        console.log(questionObjects)
        let allQuestionObjectsForSurvey = questionObjects.filter(
            (question) => question?.surveyUid === activeSurvey?.uid,
        );
        console.log(answerObjects)
        let uniqueQuestionObjectsForSurvey = allQuestionObjectsForSurvey.filter(
            (question) =>
                question?.languageObject.BCP47 === activeSurvey?.languages[0].BCP47,
        );

        setSurveyQuestions(uniqueQuestionObjectsForSurvey);

        const answerObjectsForSurvey = answerObjects.filter(
            (answerObject) => answerObject.surveyUid === activeSurvey.uid,
        );

        const uniquePplWhoResponded = [
            ...new Map(
                answerObjectsForSurvey.map((item) => [item["respondentUid"], item]),
            ).values(),
        ];
        const countOfPplWhoRespondedByDay = countDistinctObjectsByDay(
            uniquePplWhoResponded,
            "timestamp",
        );
        setTotalParticipantsOverTime(countOfPplWhoRespondedByDay);

        const countOfPplWhoRespondedTotal = Object.values(
            countOfPplWhoRespondedByDay,
        ).reduce((a, b) => a + b, 0);
        setTotalResponses(countOfPplWhoRespondedTotal);

        const uniqueLanguages = [
            ...new Map(
                uniquePplWhoResponded.map((item) => [
                    item["languageObject"]["native"],
                    item,
                ]),
            ).values(),
        ];

        const numberOfResponsesPerLanguage = {};

        // Loop through each object in list 1
        uniqueLanguages.forEach((obj1) => {
            // Check if the object appears in list 2
            if (
                uniquePplWhoResponded.some(
                    (obj2) => obj2.languageObject.Native === obj1.languageObject.Native,
                )
            ) {
                // Increment the count for this object
                numberOfResponsesPerLanguage[obj1.languageObject.Native] =
                    (numberOfResponsesPerLanguage[obj1.languageObject.Native] || 0) + 1;
            }
        });
        setLanguageResponses(numberOfResponsesPerLanguage);
    }, [activeSurvey, answerObjects]);

    useEffect(() => {
        let answers = [];
        if (activeQuestion) {
            //TODO: this fails with multiple languages
            answers = answerObjects.filter(
                (answerObject) =>
                    answerObject.surveyUid === activeSurvey.uid &&
                    answerObject.questionUid === activeQuestion?.uid,
            );
        }
        setQuestionResponses(answers);
    }, [activeQuestion, answerObjects]);

    const updateAnswers = async () => {
        const answerQuery = query(
            collection(db, "answers"),
            where("userUid", "==", currentUser.uid),
            orderBy("timestamp"),
        );
        const answerSnapshot = await getDocs(answerQuery);
        let updatedAnswerObjects = [];
        answerSnapshot.forEach((doc) => {
            updatedAnswerObjects.push(doc.data());
        });

        dispatch({answerObjects: [...updatedAnswerObjects]});
    };

    async function saveTopics(topics) {
        setOpenTopicsModal(false)
        console.log('topics')
        console.log(topics)
        try {
            // await db.collection('questions').doc(activeQuestion.uid).update({topics: topics})
            const activeQuestionRef = doc(db, "questions", activeQuestion.uid);

            // Set the "capital" field of the city 'DC'
            await updateDoc(activeQuestionRef, {
                topics: topics
            });

            toast.success('Topics saved!')

        } catch (e) {
            console.log(e)
            toast.error('Error saving topics')
        }
    }

    function answersToCsv() {
        let surveyObject = {
            answers: answerObjects,
            ...activeSurvey,
            questions: questionObjects,
        };
        saveSelectedSurveyAsCSV(surveyObject);
    }

    function getTopics() {
        let answersWithTopics = answerObjects.filter((answer) =>
            answer.hasOwnProperty("topic"),
        );
        console.log(answersWithTopics);

        return countTopics(answersWithTopics);
    }

    function getXaxisLabels() {
        return Object.keys(
            countDistinctObjectsByDay(
                [
                    ...new Map(
                        answerObjects
                            .sort((a, b) => a.timestamp - b.timestamp)
                            .filter(
                                (answer) => answer.surveyUid === activeSurvey.uid,
                            )
                            .map((item) => [item["respondentUid"], item]),
                    ).values(),
                ],
                "timestamp",
            ),
        )
    }

    function getYaxisData() {
        return Object.values(
            countDistinctObjectsByDay(
                [
                    ...new Map(
                        answerObjects
                            .sort((a, b) => a.timestamp - b.timestamp)
                            .filter(
                                (answer) => answer.surveyUid === activeSurvey.uid,
                            )
                            .map((item) => [item["respondentUid"], item]),
                    ).values(),
                ],
                "timestamp",
            ),
        )
    }

    function getHeadingForSummariesPage() {
        return (
            <>
                <TitleDate title="Survey Summary" big/>
                <SurveyQuestionSelectorDrodowns
                    showQuestionSelect={false}
                    answerObjects={answerObjects}
                    questionObjects={questionObjects}
                    setActiveSurvey={setActiveSurvey}
                    activeSurvey={activeSurvey}
                    setActiveQuestion={setActiveQuestion}
                    surveys={surveys}
                    activeQuestion={activeQuestion}
                />
                <div style={{marginTop: "8px"}}>
                    <Actions
                        downloadAnswers={answersToCsv}
                        activeSurvey={activeSurvey}
                    />
                </div>
            </>
        )
    }

    if (surveys.length === 0)
        return (
            <MainLayout>
                <Typography variant={"h4"} sx={{marginTop: 10}}>
                    You have no surveys yet, create a survey to access insights...{" "}
                </Typography>
            </MainLayout>
        );

    return (
        <MainLayout>
            <Stack
                direction={"column"}
                gap={2}
                sx={{maxWidth: "100%", margin: "0px"}}
            >
                {/*//When we pass in the parameter survey we don't need the survey selectors and all that jazz*/}
                {!survey && getHeadingForSummariesPage()}

                <CardItem title={<Typography fontSize={14} fontWeight={"bold"}>Total # of Participants</Typography>}>
                    <TotalParticipantChart xAxisLabels={getXaxisLabels()} xAxisData={getYaxisData()}/>
                </CardItem>

                {surveyQuestions
                    .sort((a, b) => a.orderNumber - b.orderNumber)
                    ?.map((item, index) =>
                        item.type !== "section" ? (
                            <QuestionGraphWrapper
                                setActiveQuestion={setActiveQuestion}
                                setOpenTopicsModal={setOpenTopicsModal}
                                key={item.uid}
                                question={item}
                                index={index + 1}
                            />
                        ) : null,
                    )}
            </Stack>
        </MainLayout>
    );
};
