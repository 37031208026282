import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {
  Button,
  Chip,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import AddQuestionToSurveyModal from "./AddQuestionToSurveyModal";
import {AppContext} from "../../utils/AppContext";
import {toast} from "react-toastify";
import {
  addUpdateQuestionToDb,
  getArrayOfObjectsWithRemovedItem,
  getBlankSurveyObject,
  getListOfQuestionsForSurvey,
  getObjectFromArrayThatMatchesValue,
} from "../../utils/sharedFunctions";
import {getAuth} from "firebase/auth";
import ListOfQuestions from "./ListOfQuestions";
import config from "../../config";
import {doc, setDoc, updateDoc} from "firebase/firestore";
import {db} from "../../utils/firebaseApp";
import SurveySettings from "./SurveySettings";
import {ArrowBackIos, ArrowForwardIos, Close, Settings,} from "@mui/icons-material";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import SaveIcon from "@mui/icons-material/Save";
import {MainLayout} from "../../layout/main/MainLayout";
import ViewIcon from "./../../assets/icons/view-survey.png";
import {colors, uniqueNamesGenerator} from "unique-names-generator";

const auth = getAuth();

export const CreateSurvey = () => {
  const {
    state: {
      edit,
      newSurvey,
      surveys,
      surveyObject,
      questionObjects,
      questionObjectToEdit,
    },
    dispatch,
  } = useContext(AppContext);
  const [openAddQuestionModal, setOpenAddQuestionModal] = useState(false);
  const [lastSaved, setLastSaved] = useState(null);
  const [activeLanguage, setActiveLanguage] = useState(
    surveyObject.languages[0],
  );
  const [saving, setSaving] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [currentMinHeight, setCurrentMinHeight] = useState(0);
  const containerRef = useRef(null);


  useEffect(() => {
    const updateMinHeight = () => {
      if (containerRef.current) {
        const height = containerRef.current.offsetHeight;
        setCurrentMinHeight(height);
      }
    };

    updateMinHeight();

    const resizeObserver = new ResizeObserver(() => {
      updateMinHeight();
    });

    resizeObserver.observe(containerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    console.log(newSurvey);
    if (!edit && !questionObjectToEdit) {
      let newSurveyObject = getBlankSurveyObject(auth.currentUser.uid);
      dispatch({ surveyObject: newSurveyObject });
      // TODO: this is dumb cause it resets the questions and until you refresh you basically don't have them
      dispatch({ questionObjects: [] });
    }
    dispatch({ edit: false });
  }, [newSurvey]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        getListOfQuestionsForSurvey(questionObjects, surveyObject).length > 0
      ) {
        saveSurvey(true);
      }
    }, 30000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  async function updateSurveysInUserArray(userUid, surveys) {
    try {
      console.log(userUid);
      const userRef = doc(db, "users", userUid);
      await updateDoc(userRef, {
        surveys: surveys.map((surveyObject) => surveyObject.uid),
      });
    } catch (e) {
      console.log(e);
    }
  }

  async function recordSurveyInDb(surveyObject) {
    try {
      const ref = doc(db, "surveys", surveyObject.uid);
      await updateDoc(ref, surveyObject);
    } catch (e) {
      if (e.code === "not-found") {
        try {
          await setDoc(doc(db, "surveys", surveyObject.uid), surveyObject);
        } catch (e) {
          toast.error("Something went wrong, couldn't save the survey!")
        }
      }
    }
  }

  async function saveSurvey(automatic) {
    if (!surveyObject?.surveyName){
      return;
    }
    const surveysTemp = getArrayOfObjectsWithRemovedItem(
      surveys,
      "uid",
      surveyObject.uid,
    );
    surveysTemp.push(surveyObject);
    try {
      setSaving(true);
      await updateSurveysInUserArray(auth.currentUser.uid, surveysTemp);
      if (
        !getObjectFromArrayThatMatchesValue(surveys, "uid", surveyObject.uid)
      ) {
        for (const questionObject of questionObjects) {
          await addUpdateQuestionToDb(questionObject);
        }
      }
      await recordSurveyInDb(surveyObject);
      setLastSaved(Date.now());
      dispatch({ surveys: [...surveysTemp] });
      dispatch({ surveyObject: surveyObject });
      if (!automatic) toast.success("Survey saved!");
    } catch (e) {
      toast.error("Something went wrong, couldn't save the survey!");
    } finally {
      setSaving(false)
    }
  }

  const reorder = async (list, startIndex, endIndex) => {
    const reorderedQuestionsArray = Array.from(list);
    const [removed] = reorderedQuestionsArray.splice(startIndex, 1);
    reorderedQuestionsArray.splice(endIndex, 0, removed);
    reorderedQuestionsArray.forEach((question, index) => {
      question.orderNumber = index;
    });
    dispatch({ questionObjects: [...reorderedQuestionsArray] });
    for (const reorderedQuestionsArrayElement of reorderedQuestionsArray) {
      await addUpdateQuestionToDb(reorderedQuestionsArrayElement);
    }
  };

  const moveQuestion = (fromIndex, toIndex) => {
    if (fromIndex === toIndex) {
      return;
    }
    reorder(questionObjects, fromIndex, toIndex);
  };
  const [surveySettingsVisible, setSurveySettingsVisible] = useState(false);

  function moveLanguageCursor(number) {
    setQuestionIndex(questionIndex + number);
    setActiveLanguage(surveyObject.languages[questionIndex + number]);
  }

  const uniqueName = useMemo(() => {
    return 'New '+ uniqueNamesGenerator({
          dictionaries: [colors],
          style: 'lowerCase',
          separator: "-"
      }) + " survey"
  }, [])

  return (
    <MainLayout>
      <Grid container>
        <Grid xs={12} md={12} lg={10} sx={{ mx: "auto" }}>
          <Stack direction={"row"}>
            {surveySettingsVisible ? (
              <Drawer
                anchor={"left"}
                open={surveySettingsVisible}
                onClose={() => setSurveySettingsVisible(false)}
                elevation={0}
                variant={"temporary"}
              >
                <Toolbar />
                <SurveySettings
                  activeLanguage={activeLanguage}
                  setActiveLanguage={setActiveLanguage}
                  surveyObject={surveyObject}
                  recordSurveyInDb={saveSurvey}
                  onClose={() => setSurveySettingsVisible(false)}
                />
              </Drawer>
            ) : null}

            <Grid
              container
              direction={"column"}
              spacing={2}
              sx={{ padding: 0 }}
            >
              <Grid item>
                <Stack
                  direction="row"
                  alignItems={"flex-start"}
                  justifyContent={"flex-start"}
                  gap="10px"
                >
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    justifyContent="flex-start"
                    gap="5px"
                    sx={{ flex: 1 }}
                  >
                    <IconButton
                      onClick={() => moveLanguageCursor(-1)}
                      disabled={questionIndex <= 0}
                      className="gray-text"
                      sx={{ color: "#828282" }}
                    >
                      <ArrowBackIos style={{ height: "22px" }} />
                    </IconButton>
                    {/*{console.log(activeLanguage)}*/}
                    <Chip
                      label={activeLanguage.Name}
                      disabled={false}
                      name={activeLanguage.Name}
                      deleteIcon={<Close />}
                      variant="outlined"
                      attr={activeLanguage.Name}
                      sx={{
                        padding: 0,
                        margin: 1,
                        color: "#24B23B",
                        border: "unset",
                        fontSize: "1.04em",
                        "&:hover": {
                          opacity: "0.8",
                        },
                      }}
                      key={activeLanguage.Name}
                    />
                    <IconButton
                      onClick={() => moveLanguageCursor(+1)}
                      disabled={
                        questionIndex >= surveyObject.languages.length - 1
                      }
                      className="gray-text"
                      sx={{ color: "#828282" }}
                    >
                      <ArrowForwardIos style={{ height: "22px" }} />
                    </IconButton>
                  </Stack>
                </Stack>
              </Grid>

              <Grid item sx={{ marginBottom: 1, marginTop: 2 }}>
                <Stack gap={"20px"}>
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    autoFocus
                    placeholder={uniqueName}
                    value={surveyObject.surveyName}
                    onChange={(e) => {
                      surveyObject.surveyName = e.target.value;
                      dispatch(surveyObject);
                    }}
                    inputProps={{
                      sx: { fontSize: 30, color: "#5271FF" },
                      className: "survey-title",
                      autocomplete: "off"
                    }}
                    sx={{ fontSize: 30, color: "#5271FF" }}
                  />
                </Stack>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  gap={"20px"}
                  className="save-survey-actions-wrap"
                  // sx={{ maxWidth: 400 }}
                >
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    id={"settings-row"}
                    sx={{
                      marginTop: "15px",
                      gap: "9px",
                    }}
                  >
                    <Tooltip title={"Advanced survey settings"}>
                      <IconButton
                        variant={"contained"}
                        size={"small"}
                        disabled={!surveyObject?.surveyName}
                        onClick={() =>
                          setSurveySettingsVisible(!surveySettingsVisible)
                        }
                      >
                        <Settings sx={{ color: surveyObject?.surveyName ? "#14AAFE" : "#9b9999" }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={"Save survey"}>
                      <IconButton
                        variant={"contained"}
                        size={"small"}
                        disabled={!surveyObject?.surveyName}
                        onClick={saveSurvey}
                      >
                        <SaveIcon sx={{ color: surveyObject?.surveyName ? "#14AAFE" : "#9b9999" }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={"View live survey"}>
                      <IconButton
                        variant={"contained"}
                        size={"small"}
                        disabled={!surveyObject?.surveyName}
                        onClick={() =>
                          window.open(
                            config.clientUrl + "survey/" + surveyObject.uid,
                            "_blank",
                          )
                        }
                      >
                        <img
                          src={ViewIcon}
                          alt={"View survey"}
                          style={{ width: "24px", filter: surveyObject?.surveyName ? undefined : "grayscale(100%)" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                  <Stack direction={"row"} alignitems={"center"} gap={1}>
                    {saving && <CircularProgress size={14} color={"secondary"} />}
                    <Typography
                      variant={"body1"}
                      className="gray-text"
                      sx={{ fontSize: 10 }}
                    >
                      Last saved:{" "}
                      <span className="bold">
                      {lastSaved &&
                        new Date(lastSaved).toLocaleTimeString("en-US")}
                    </span>
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>

              <Grid item>
                <DragDropContext
                  onDragEnd={(result) =>
                    moveQuestion(result.source.index, result.destination.index)
                  }
                >
                  <div
                    id={"question-drop-container"}
                    ref={containerRef}
                    style={{ minHeight: currentMinHeight }}
                  >
                    <Droppable droppableId="question-drop">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          <ListOfQuestions
                            activeLanguage={activeLanguage}
                            openAddQuestionModal={openAddQuestionModal}
                            setOpenAddQuestionModal={setOpenAddQuestionModal}
                          />
                        </div>
                      )}
                    </Droppable>
                  </div>
                </DragDropContext>
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems="center"
                  gap="10px"
                  sx={{ width: "100%", marginBottom: 10 }}
                >
                  <Button
                    onClick={() => setOpenAddQuestionModal(true)}
                    variant={"outlined"}
                    sx={{
                      marginTop: 5,
                      borderRadius: "8px",
                      border: "1.6px solid var(--Gradient, #7F52FF)",
                      background: surveyObject?.surveyName ? "var(--Gradient, linear-gradient(90deg, #7F52FF -2.44%, #5271FF 50.38%, #5DAFFB 100%))" : "#9b9999",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      fontWeight: 700,
                      fontSize: "1em",
                      minWidth: "160px",
                      "&:hover": {
                        transition: "all 0.3s ease-in-out",
                        border: "1.6px solid var(--Gradient, #7F52FF)",
                        transform: "translateY(-4px)",
                      },
                    }}
                    disabled={!surveyObject?.surveyName}
                  >
                    + Add new question
                  </Button>
                  <Button
                    onClick={() => saveSurvey(true)}
                    // sx={{
                    //   marginTop: 5,
                    //   borderRadius: "8px",
                    //   border: "1.6px solid var(--Gradient, #2FA642)",
                    //   backgroundColor: "#2FA642",
                    //   fontWeight: 700,
                    //   fontSize: "1em",
                    //   color: "white",
                    //   minWidth: "105px",
                    //   "&:hover": {
                    //     backgroundColor: "#228f34",
                    //     transition: "all 0.3s ease-in-out",
                    //     transform: "translateY(-4px)",
                    //   },
                    // }}
                    color={"success"} variant={"contained"}
                    startIcon={saving ? <CircularProgress size={14} color={'secondary'} /> : <SaveIcon />}
                    sx={{
                      color: "white", px: 2.5, marginTop: 5, borderRadius: 1, fontWeight: 700,
                      "&:hover": {
                        transform: "translateY(-4px)"
                      }
                    }}
                    disabled={!surveyObject?.surveyName}
                  >
                    Save
                  </Button>
                </Stack>
                <AddQuestionToSurveyModal
                  setOpenAddQuestionModal={setOpenAddQuestionModal}
                  openModal={openAddQuestionModal}
                  saveSurvey={saveSurvey}
                />
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </MainLayout>
  );
};
