import './new-globals.css';
import React, {lazy, Suspense, useContext, useState} from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import {ConfigContext} from './contexts/config-context';
import {LoginPage} from './pages/login-page/login-page';
import {MainPage} from './pages/main-page/main-page';
import {DashboardPage} from './pages/snapshot-page/dashboard-page';
import {Surveys} from './pages/projects-list-page/surveys';
import {ProjectSummariesPage} from './pages/project-summaries-page/project-summaries-page';
import {EditSurvey} from './pages/edit-survey/edit-survey';
import {QuestionnairePage} from './pages/questionnaire-page/questionnaire-page';
import {AnalysisPage} from './pages/analysis-page/analysis-page';
import {auth} from "./utils/firebaseApp";
import {onAuthStateChanged} from "firebase/auth";
import {AppContextProvider} from "./utils/AppContext";
import {Stack, ThemeProvider, Typography} from "@mui/material";
import {theme} from "./utils/Theme";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import InterfaceContextProvider from './contexts/interface-context';
import {MainLayout} from "./layout/main/MainLayout";
import {CreateSurvey} from "./pages/create-project-page/create-survey";
import LoadingScreen from "./components/shared-components/LoadingScreen";
import {ApiPage} from "./pages/api-page/ApiPage";
import ThankYouPage from "./pages/ThankYouPage";
import SurveyDesignerPage from "./pages/survey-designer/SurveyDesignerPage";
import ReportsPage from "./pages/reports-page/ReportsPage";
import PublicReportPage from "./pages/public-report-page/PublicReportPage";
import {AuthContextProvider, useAuthContext} from "./contexts/auth_context";
import {PageNotFound} from "./pages/not-found/PageNotFound";

const SurveyNew = lazy(() => import( "./pages/survey-page/NewSurvey/SurveyNew"))


export const App = () => {
  const {projectsList} = useContext(ConfigContext);
  const {isAuth} = useAuthContext();

  return (
    <div className='App'>
      <ToastContainer/>
      <Suspense fallback={<LoadingScreen/>}>
        <Routes>
          <Route path="/survey/:uid" element={<SurveyNew/>}/>
          <Route path='/report/:uid' element={<PublicReportPage/>}/>
          {isAuth === null ? <LoadingScreen /> : !isAuth ?
            <>
              <Route path="/login" index element={<LoginPage/>}/>
              <Route path="/thankyou" index element={<ThankYouPage/>}/>
              <Route path="*" element={<Navigate to="/login"/>}/>
            </>
            :
            <>
              <Route exact path="/" element={<MainPage/>}/>
              <Route path="/dashboard"
                     element={<DashboardPage projectsList={projectsList}/>}/>
              <Route path="/surveys" element={<Surveys projectsList={projectsList}
                                                       key={document.location.href}/>}/>
              <Route path='/summaries' element={<ProjectSummariesPage/>}/>
              <Route path='/survey-designer' element={<SurveyDesignerPage/>}/>
              <Route path='/edit-survey' element={<EditSurvey/>}/>
              <Route exact path='/create-survey' element={<CreateSurvey/>}/>
              {/*<Route path="/survey/:uid" element={<SurveyNew/>}/>*/}
              <Route path='/report/:uid' element={<PublicReportPage/>}/>
              <Route path='/analysis' element={<AnalysisPage/>}/>
              <Route path='/questionnaire-page' element={<QuestionnairePage/>}/>
              <Route path='/reports' element={<ReportsPage/>}/>
              <Route path='/api' element={<ApiPage/>}/>
              <Route path="/thankyou" index element={<ThankYouPage/>}/>
              <Route path="*" element={<Navigate to="/dashboard"/>}/>

              <Route path={'/404'}
                     element={<PageNotFound />}/>
            </>}
        </Routes>
      </Suspense>
    </div>
  );
}
