import React from 'react';
import {Grid} from "@mui/material";
import loadingGif from '../../assets/login/loading.webp'

const LoadingScreen = () => {
  return (
    <div>
      <Grid container justify={'center'} direction={"column"} alignContent={"center"}
            alignItems={"center"}>
        <Grid item>
          <img src={loadingGif} alt={'loading gif'}/>
        </Grid>
      </Grid>
    </div>
  );
};

export default LoadingScreen;
