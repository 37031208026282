import React, { useContext } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { ProgressBar } from "../../../../components/shared-components/graphical/progress-bar/progress-bar";
import Box from "@mui/material/Box";
import { ViewSummaryButton } from "./ViewSummaryButton";
import { SurveyActionButtons } from "./SurveyActionButtons";
import { SurveyTitle } from "./SurveyTitle";
import { AppContext } from "../../../../utils/AppContext";
import { useNavigate } from "react-router-dom";

export const ProjectItem = ({ survey }) => {
  const {
    state: { answerObjects, questionObjects },
    dispatch,
  } = useContext(AppContext);
  const navigate = useNavigate();
  return (
    survey && (
      <Grid container className={"project-item-container"}>
        <Grid item xs={12} md={6} lg={7} sx={{ p: 1 }}>
          <Stack
            direction={"column"}
            sx={{ width: "100%", minHeight: "140px" }}
            gap={3}
            justifyContent={"space-between"}
            alignItems={"flex-start"}
          >
            <Box
              onClick={() => {
                dispatch({ surveyObject: survey });
                navigate("/summaries");
              }}
              sx={{ cursor: "pointer" }}
            >
              <SurveyTitle
                title={survey.surveyName}
                dateCreated={survey.dateCreated}
              />
            </Box>

            <Stack
              direction={"column"}
              gap={1}
              sx={{ width: "100%" }}
              className={"project-item"}
            >
              <div style={{ width: "90%", margin: "8px 0px 0px 0px" }}>
                <Stack
                  direction={"row"}
                  gap={1}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  flexWrap={"wrap"}
                >
                  <Box className={"tooltip-box"}>
                    <Typography className={"tooltips"}>
                      {
                        answerObjects.filter(
                          (answer) => answer.surveyUid === survey.uid,
                        ).length
                      }
                    </Typography>
                    <Typography className={"tooltips light"}>
                      Questions Answered
                    </Typography>
                  </Box>
                  <Box className={"tooltip-box"}>
                    <Typography className={"tooltips"}>
                      {
                        questionObjects.filter(
                          (question) => question.surveyUid === survey.uid && question.languageObject.Native === survey.languages[0].Native
                        ).length
                      }
                    </Typography>
                    <Typography className={"tooltips light"}>
                      Questions
                    </Typography>
                  </Box>
                  <Box className={"tooltip-box"}>
                    <Typography className={"tooltips"}>
                      {survey.languages.length}
                    </Typography>
                    <Typography className={"tooltips light"}>
                      Languages
                    </Typography>
                  </Box>
                </Stack>
              </div>
              {/*<ProgressBar colorTop='#5271FF' colorBottom="rgba(181, 162, 255, 0.2)" percent="20"*/}
              {/*             height='15px'/>*/}
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} lg={5} sx={{ p: 1 }}>
          <Stack
            direction={"column"}
            sx={{ width: "100%", height: "100%" }}
            gap={2}
            justifyContent={"space-between"}
            alignItems={"flex-start"}
          >
            <ViewSummaryButton surveyUid={survey.uid}/>

            <Stack
              direction={"row"}
              gap={1}
              justifyContent={"space-between"}
              sx={{ width: "100%" }}
              flexWrap={"wrap"}
            >
              <SurveyActionButtons survey={survey} showSettings />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    )
  );
};
