import { Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";

export const SurveyTitle = ({ title, dateCreated }) => {
  return (
    <div className={"project-item"}>
      <Typography className={"title"}>{title}</Typography>
      <Typography className={"date-under"}>
        Launched on {new Date(dateCreated).toLocaleDateString()}
      </Typography>
    </div>
  );
};
