import createTheme from "@mui/material/styles/createTheme";
import {deepPurple} from "@mui/material/colors";

export const theme = createTheme({
    root: {
        position: "absolute",
        height: "100%",
    },
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: "#5271FF",
            contrastText: "#ffffff",
            white: "#ffffff",
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: "#4F4F4F",
        },
        neutral: {
            main: "#ffffff",
            gray: "#f4f6f6",
            gray2: "#adadad",
        },
        azure: {
            main: "#1DAEFF",
        },
        confirmation: {
            main: "#09D92A",
            contrastText: "#ffffff",
            dark: "#2FA642",
        },
        error: {
            main: "rgba(150,41,41,0.87)",
        },
        purple: {
            main: deepPurple[500],
            light: deepPurple[200],
        },
        success: {
            main: "#2FA642"
        }
    },
    typography: {
        // fontFamily: "Poppins, serif",
        fontFamily: "Poppins",
        color: "#505050",
        h1: {
            // fontFamily: "Poppins, serif",
            fontFamily: "Poppins",
            fontSize: "3em",
            color: "#505050",
        },
        h2: {
            fontFamily: "Poppins",
            fontSize: "2.2rem",
        },
        h3: {
            fontFamily: "Poppins",
            fontSize: "2em",
            color: "#11263C",
        },
        h4: {
            fontFamily: "Poppins",
            fontSize: "1.2em",
            color: "#11263C",
            fontWeight: 600,
        },
        h5: {
            fontFamily: "Poppins",
            fontSize: "1rem",
        },
        body1: {
            fontFamily: "Poppins",
            fontSize: "1em",
            color: "#818181",
        },
        bodyNormal: {
            fontFamily: "Poppins",
            fontSize: "1em",
            color: "#A0A0A0",
        },
        subtitle2: {
            fontFamily: "Poppins",
            fontSize: "1em",
            color: "#505050",
            fontWeight: 400,
        },
    },
    action: {
        hover: "#1B8EB1",
        active: "#1B8EB1",
    },
});
