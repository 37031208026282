import React, {useState} from 'react';
import {TextField, Typography} from '@mui/material';
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

export const EditableTextField = ({initialText, onSave, type}) => {
    const [text, setText] = useState(initialText);
    const [isEditing, setIsEditing] = useState(false);

    const handleSave = () => {
        if (text !== initialText) onSave(text, type)
        setIsEditing(false);
    };

    return (
        <Box sx={{mt: 1}}>
            {isEditing ? (
                <TextField
                    autoFocus
                    fullWidth
                    multiline
                    value={text}
                    variant={'standard'}
                    onChange={(e) => setText(e.target.value)}
                    onBlur={handleSave}
                    helperText={"Click outside to save"}
                />
            ) : (
                <Typography
                    component="p"
                    variant="body1"
                    sx={{mt: 2, fontSize: "1.1em"}}
                    onClick={() => setIsEditing(true)}
                >
                    {text}
                </Typography>
            )}
        </Box>
    );
};


EditableTextField.propTypes = {
    initialText: PropTypes.string,
    onSave: PropTypes.func,
    type: PropTypes.string,
}
