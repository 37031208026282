import React, {useContext} from "react";
import {Autocomplete, Box, Chip, Grid, Stack, Switch, TextField, Typography,} from "@mui/material";
import {AppContext} from "../../utils/AppContext";
import {countryCodesWithNativeLanguages} from "../../utils/country-codes-google";
import _ from "lodash";
import uuid from "react-uuid";
import {and, collection, doc, getDocs, query, where, writeBatch,} from "firebase/firestore";
import {auth, db, firebaseApp} from "../../utils/firebaseApp";
import {getObjectFromArrayThatMatchesValue} from "../../utils/sharedFunctions";
import {toast} from "react-toastify";
import {Close} from "@mui/icons-material";
import {getFunctions, httpsCallableFromURL} from "firebase/functions";

const SurveySettings = ({
                            onClose,
                            setActiveLanguage,
                            recordSurveyInDb,
                            activeLanguage,
                            surveyObject,
                        }) => {
    const {state: {questionObjects, currentUser}, dispatch,} = useContext(AppContext);
    const functions = getFunctions(firebaseApp);
    const getTranslation = httpsCallableFromURL(functions, process.env.REACT_APP_SERVER_URL + "getTranslation");

    async function addLanguage(e, newValue) {
        toast.info('Adding a language, please wait...')
        if (getObjectFromArrayThatMatchesValue(surveyObject.languages, "Name", newValue.Name)) {
            return toast.error("Cannot add same language twice");
        }

        surveyObject.languages = [...surveyObject.languages, newValue];
        dispatch({surveyObject: surveyObject});
        await recordSurveyInDb(surveyObject, auth.currentUser);
        const newQuestions = [];
        for (const questionObject of questionObjects) {
            if (
                questionObject.surveyUid === surveyObject.uid &&
                questionObject.languageObject.Name === surveyObject.languages[0].Name
            ) {
                let translatedQuestion = _.cloneDeep(questionObject);
                translatedQuestion.originalReferenceUid = questionObject.uid;
                translatedQuestion.uid = uuid();
                translatedQuestion.languageObject = newValue;
                translatedQuestion.question = (await getTranslation({
                    text: questionObject.question,
                    targetLanguageISOCode: newValue.BCP47
                })).data;
                newQuestions.push(translatedQuestion);
            }
        }
        try {
            const batch = writeBatch(db);
            newQuestions.forEach((questionObject) => {
                batch.set(doc(db, "questions", questionObject.uid), questionObject);
            });
            await batch.commit();
            dispatch({questionObjects: [...questionObjects, ...newQuestions]});
            toast.success('Language added')
        } catch (e) {
            console.log(e);
        }
    }

    async function deleteLanguage(languageToDelete) {
        console.log('deleting language', languageToDelete)
        if (surveyObject.languages.length === 1 || languageToDelete === "English (United States)")
            return toast.error("You cannot delete the only language or English language.");
        try {
            await recordSurveyInDb(surveyObject);
            const q = query(
                collection(db, "questions"),
                and(
                    where("surveyUid", "==", surveyObject.uid),
                    where("languageObject", "array-contains", languageToDelete),
                ),
            );

            const batch = writeBatch(db);
            const questionsToDelete = await getDocs(q);
            questionsToDelete.forEach((doc) => {
                batch.delete(doc.ref);
            });

            await batch.commit();
            const filteredLanguages = surveyObject.languages.filter((language) => {
                return language.Name !== languageToDelete;
            });
            surveyObject.languages = filteredLanguages;
            dispatch({
                questionObjects: questionObjects.filter(
                    (object) => languageToDelete.Name !== object.languageObject.Name,
                ),
            });
            dispatch({surveyObject: surveyObject});
            setActiveLanguage(surveyObject.languages[0]);
            toast.success("Language deleted", {type: "error"});
        } catch (e) {
            console.log(e);
        }
    }

    function updateSurvey(surveyObject) {
        try {
            recordSurveyInDb(surveyObject);
            dispatch({surveyObject: surveyObject});
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <Stack
            className="survey-settings-panel"
            direction="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            gap="40px"
            fontSize="16px"
            sx={{padding: "18px", marginTop: "20px"}}
        >
            <Grid container direction={"column"}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent={"space-between"}
                    gap="10px"
                    marginBottom="20px"
                >
                    <Typography variant={"h4"}>
                        <span>Survey Settings</span>
                    </Typography>
                    <Close className="clickable gray-text" onClick={() => onClose()}/>
                </Stack>

                <Grid item style={{marginTop: "20px"}}>
                    <span className={"smaller-subtitles"}>Languages</span>
                    <Autocomplete
                        id="country-select-demo"
                        sx={{flex: 1, marginTop: "10px"}}
                        placeholder="Select survey languages"
                        options={countryCodesWithNativeLanguages}
                        onChange={(e, newValue) => addLanguage(e, newValue)}
                        getOptionLabel={(option) => option.Name}
                        autoComplete={false}
                        renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                {option.flag} {option.Name}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                autoComplete={'off'}
                                {...params}
                                inputProps={{
                                    // autoComplete: 'new-password',
                                    autoComplete: 'off',
                                    ...params.inputProps,
                                    form: {
                                        autoComplete: 'off',
                                    },
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item className={"grid-container-of-languages"}>
                    {surveyObject.languages.map((option, index) => {
                        return (
                            <Chip
                                label={option.Name}
                                disabled={false}
                                name={option.Name}
                                attr={option.Name}
                                sx={{
                                    margin: "1px",
                                    borderRadius: "5px",
                                    color: "#4F4F4F",
                                    "&:hover": {
                                        opacity: "0.8",
                                    },
                                }}
                                deleteIcon={<Close/>}
                                key={option.Name}
                                delete-key={option.Name}
                                onDelete={async (e) => {
                                    await deleteLanguage(option.Name);
                                }}
                            />
                        );
                    })}
                </Grid>
            </Grid>
            <Stack
                direction="column"
                alignItems="flex-start"
                justifyContent={"flex-start"}
                gap="10px"
            >
                <span className={"smaller-subtitles"}>Response Data</span>
                <Stack
                    direction={"row"}
                    alignItems="center"
                    style={{marginTop: "7px"}}
                >
                    <Switch
                        color="azure"
                        checked={surveyObject.getUserIPAddress}
                        onChange={() => {
                            surveyObject.getUserIPAddress = !surveyObject.getUserIPAddress;
                            updateSurvey(surveyObject);
                        }}
                    />
                    <Typography variant={"body1"} className="regular">
                        Collect respondents approximate geolocation
                    </Typography>
                </Stack>

                <Stack direction={"row"} alignItems="center">
                    <Switch
                        color="azure"
                        checked={surveyObject.saveAudioRecordings}
                        onChange={() => {
                            surveyObject.saveAudioRecordings =
                                !surveyObject.saveAudioRecordings;
                            updateSurvey(surveyObject);
                        }}
                    />{" "}
                    <Typography variant={"body1"} className="regular">
                        Save audio recordings of user voice responses
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    );
};
export default SurveySettings;
