import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import jsx from "react-syntax-highlighter/dist/cjs/languages/prism/jsx";
// import { style } from 'react-syntax-highlighter/dist/esm/styles/{hljs|prism}'
import { prism } from "react-syntax-highlighter/dist/esm/styles/prism";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }} className={"Muibox-overwrite-css"}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CodeSampleTabs({ apiKey, surveyUid }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{borderRadius: 2, bgcolor: "#FFF"}}
      className={"no-shadow"}
    >
      <Box
        sx={{
          borderBottom: 1,
          marginTop: "20px",
          borderColor: "white",
        }}
        className={"no-shadow"}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Axios" {...a11yProps(0)} />
          <Tab label="Postman" {...a11yProps(1)} />
          <Tab label="Python" {...a11yProps(2)} />
          {/*<Tab label="Fetch" {...a11yProps(1)} />*/}
          {/*<Tab label="Item Three" {...a11yProps(2)} />*/}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <CodeSamples apiKey={apiKey} surveyUid={surveyUid} language={"axios"} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CodeSamples
          apiKey={apiKey}
          surveyUid={surveyUid}
          language={"postman"}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <CodeSamples
          apiKey={apiKey}
          surveyUid={surveyUid}
          language={"python"}
        />
      </TabPanel>
    </Box>
  );
}

function CodeSamples({ language, apiKey, surveyUid }) {
  SyntaxHighlighter.registerLanguage("jsx", jsx);
  const axiosString =
    "" +
    "await axios({\n" +
    '      method: "POST",\n' +
    `      url: "${process.env.REACT_APP_SERVER_URL}getAnswers",\n` +
    `      data: {apiKey:"${apiKey}", surveyUid:"${surveyUid}"}\n` +
    "    })";
  const pythonString =
    "import requests\n" +
      "\n" +
      "data = {\n" +
      `    \"apiKey\": \"${apiKey}\",\n` +
      `    \"surveyUid\": \"${surveyUid}\"\n` +
      "}\n" +
      "\n" +
      `response = requests.post("${process.env.REACT_APP_SERVER_URL}",\n json=data)\n` +
      "print(response.json())  ";
  const postmanString =
    "Select POST\n" +
    `Paste ${process.env.REACT_APP_SERVER_URL}getAnswers\n` +
    "Select body\n" +
    "Select x-www-form-urlencoded\n" +
    `apiKey:${apiKey}\n` +
    `surveyUid:${surveyUid}`;

  function getLanguage() {
    switch (language) {
      case "axios":
        return axiosString;
      case "postman":
        return postmanString;
      case "python":
        return pythonString;
      default:
        throw new Error("Language not supported");
    }
  }

  return (
    <div>
      <SyntaxHighlighter
        language={"jsx"}
        showLineNumbers={true}
        style={prism}
        className={"syntax-code-block"}
        wrapLines={true}
      >
        {getLanguage()}
      </SyntaxHighlighter>
    </div>
  );
}
