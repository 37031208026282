export const stopwords = [
  "'cos",
  "'d",
  "'ll",
  "'m",
  "'re",
  "'ve",
  "a",
  "'s",
  "s",
  "",
  "about",
  "above",
  "after",
  "after",
  "again",
  "against",
  "ago",
  "ahead",
  "all",
  "almost",
  "almost",
  "along",
  "already",
  "also",
  "although",
  "always",
  "am",
  "among",
  "an",
  "and",
  "any",
  "are",
  "aren't",
  "around",
  "as",
  "at",
  "away",
  "backward",
  "backwards",
  "be",
  "because",
  "before",
  "behind",
  "below",
  "beneath",
  "beside",
  "between",
  "both",
  "but",
  "by",
  "can",
  "can't",
  "cannot",
  "cause",
  "could",
  "couldn't",
  "despite",
  "did",
  "didn't",
  "do",
  "does",
  "doesn't",
  "don't",
  "down",
  "during",
  "each",
  "either",
  "even",
  "ever",
  "every",
  "except",
  "for",
  "forward",
  "from",
  "had",
  "hadn't",
  "has",
  "hasn't",
  "have",
  "haven't",
  "he",
  "her",
  "here",
  "hers",
  "herself",
  "him",
  "himself",
  "his",
  "how",
  "however",
  "I",
  "if",
  "in",
  "inside",
  "inspite",
  "instead",
  "into",
  "is",
  "isn't",
  "it",
  "its",
  "itself",
  "just",
  "least",
  "less",
  "like",
  "many",
  "may",
  "mayn't",
  "me",
  "might",
  "mightn't",
  "mine",
  "more",
  "most",
  "much",
  "must",
  "mustn't",
  "my",
  "myself",
  "near",
  "need",
  "needn't",
  "needs",
  "neither",
  "never",
  "no",
  "none",
  "nor",
  "not",
  "now",
  "of",
  "off",
  "often",
  "on",
  "once",
  "only",
  "onto",
  "or",
  "ought",
  "oughtn't",
  "our",
  "ours",
  "ourselves",
  "out",
  "outside",
  "over",
  "past",
  "perhaps",
  "quite",
  "rather",
  "seldom",
  "several",
  "shall",
  "shan't",
  "she",
  "should",
  "shouldn't",
  "since",
  "so",
  "some",
  "sometimes",
  "soon",
  "than",
  "that",
  "the",
  "their",
  "theirs",
  "them",
  "themselves",
  "then",
  "there",
  "therefore",
  "these",
  "they",
  "this",
  "those",
  "though",
  "through",
  "thus",
  "till",
  "to",
  "together",
  "too",
  "towards",
  "under",
  "unless",
  "until",
  "up",
  "upon",
  "us",
  "used",
  "usedn't",
  "usen't",
  "usually",
  "very",
  "was",
  "wasn't",
  "we",
  "well",
  "were",
  "weren't",
  "what",
  "when",
  "where",
  "whether",
  "which",
  "while",
  "who",
  "whom",
  "whose",
  "why",
  "will",
  "with",
  "without",
  "won't",
  "would",
  "wouldn't",
  "yet",
  "you",
  "your",
  "yours",
  "yourself",
  "yourselves"]