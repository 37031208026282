import React, {useContext} from 'react';
import {FormControlLabel, Grid, RadioGroup} from "@mui/material";
import Radio from '@mui/material/Radio';
import {answerExists, createAnswerObject} from "../../../utils/sharedFunctions";
import {AppContext} from "../../../utils/AppContext";
import QuestionTitle from "./QuestionTitle";
import Box from "@mui/material/Box";

const MultichoiceQuestion = ({questionObject}) => {
  const {state: {respondentUid, answerObjects, surveyObject}, dispatch} = useContext(AppContext);

  function handleAnswerInput(e) {
    function checkOption(answerObject) {
      answerObject.options.forEach(option => {
        option.checked = option.option === e.target.value;
      })
    }

    if (!answerExists(answerObjects, questionObject.uid)) {
      let answerObject = createAnswerObject(questionObject, respondentUid)
      answerObject.text = e.target.value
      answerObject.options = questionObject.options
      checkOption(answerObject)
      answerObjects.push(answerObject)
    } else {
      answerObjects.forEach(answerObject => {
        if (answerObject.questionUid === questionObject.uid) {
          answerObject.text = e.target.value
          checkOption(answerObject)
        }
      })
    }
    dispatch({answerObjects: [...answerObjects]});
  }


  return (
    <Box sx={{display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'flex-start'}}>
      <QuestionTitle question={questionObject?.question} surveyObject={surveyObject}
                     orderNumber={questionObject?.orderNumber}/>
      <Grid container direction={"column"} alignItems={"start"} justifyContent={"center"}
            sx={{maxWidth: "500px", px: 2, width: "100%"}}>
        <Grid item xs={12}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
          >
            {questionObject?.options?.map(option => {
              return (
                <FormControlLabel value={option?.option}
                                  control={<Radio checked={option?.checked}/>}
                                  checked={option?.checked}
                                  label={option?.option}
                                  key={option.option}
                                  onChange={(e) => handleAnswerInput(e)}/>
              )
            })}
          </RadioGroup>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MultichoiceQuestion;
