import {Stack, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {CheckButton} from '../../../../components/shared-components/check-button/check-button';
import {ChipTags} from '../../../../components/shared-components/chip-tags/chip-tags';
import {IncreaseDecreaseValue} from '../../../../components/shared-components/increase-decrease-value/increase-decrease-value';
import {ToggleSwitchButton} from '../../../../components/shared-components/toggle-switch-button/toggle-switch-button';

export const SurveyVoiceQuestionType = ({setAdditionalFields, doNotShowOptions, questionObjectToEdit}) => {
  const [recordingTimeLimit, setRecordingTimeLimit] = useState(false);
  const [recordingMinLen, setRecordingMinLen] = useState(20);
  const [recordingMaxLen, setRecordingMaxLen] = useState(60);
  const [googleReview, setGoogleReview] = useState(false);
  const [googleReviewPositiveCheck, setGoogleReviewPositiveCheck] = useState(false);
  const [forwardNegative, setForwardNegative] = useState(false);
  const [addSuggestedTopicVisible, setAddSuggestedTopicVisible] = useState(false);

  useEffect(() => {
    if (questionObjectToEdit) {
      questionObjectToEdit.googleReview && setGoogleReview(questionObjectToEdit.googleReview)
      questionObjectToEdit.googleReviewPositiveCheck && setGoogleReviewPositiveCheck(questionObjectToEdit.googleReviewPositiveCheck)
      questionObjectToEdit.forwardNegative && setForwardNegative(questionObjectToEdit.forwardNegative)
      questionObjectToEdit.addSuggestedTopicVisible && setAddSuggestedTopicVisible(questionObjectToEdit.addSuggestedTopicVisible)
    }
  }, [questionObjectToEdit])

  useEffect(() => {
    setAdditionalFields({
      googleReview: googleReview,
      googleReviewPositiveCheck: googleReviewPositiveCheck,
      forwardNegative: forwardNegative,
      addSuggestedTopicVisible: addSuggestedTopicVisible,
    })
  }, [googleReview,
    googleReviewPositiveCheck,
    forwardNegative,
    addSuggestedTopicVisible])

  if (doNotShowOptions) return null
  return (
    <div className='survey-voice-question-type-wrap question-type-wrap'>
      <ToggleSwitchButton
        title="Recording Time Limit"
        onToggle={() => setRecordingTimeLimit(!recordingTimeLimit)}
        value={recordingTimeLimit}/>

      {recordingTimeLimit &&
      <div className='time-limit-options'>
        <Stack
          direction={'row'}
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}>

          <Typography variant={"body1"}>Maximum Length (seconds)</Typography>
          <IncreaseDecreaseValue value={20}/>
        </Stack>
        <Stack
          direction={'row'}
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}>

          <Typography variant={"body1"}>Minimum Length (seconds)</Typography>
          <IncreaseDecreaseValue value={60}/>
        </Stack>
      </div>}

      <ToggleSwitchButton
        title="Ask respondent to post their response to Google Reviews"
        onToggle={() => setGoogleReview(!googleReview)}
        value={googleReview}/>

      {googleReview && <CheckButton
        className={'move-right'}
        checked={googleReviewPositiveCheck}
        onUnCheck={() => setGoogleReviewPositiveCheck(false)}
        onCheck={() => setGoogleReviewPositiveCheck(true)}
        title="Only prompt users with positive sentiments in response"/>}

      <ToggleSwitchButton
        title="Forward negative responses to my e-mail"
        onToggle={() => setForwardNegative(!forwardNegative)}
        value={forwardNegative}/>

      <ToggleSwitchButton
        title="Add suggested topics list for users"
        onToggle={() => setAddSuggestedTopicVisible(!addSuggestedTopicVisible)}
        value={forwardNegative}/>
      {addSuggestedTopicVisible && <div className='survery-voice-question-chips'>
        <ChipTags inputActive placeholder="Enter suggested topic..." onDeleteSingle/>
      </div>}
    </div>
  )
}
