import React, {useEffect, useState} from "react";
import {Button, IconButton, Stack, TextField, Typography} from "@mui/material";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {Add, DragIndicator} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import {AddBox} from "../../../components/shared-components/button/add-box/add-box";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import {toast} from "react-toastify";

const MultipleOptionsDraggable = ({
                                      shouldDrag,
                                      setAdditionalFields,
                                      questionObjectToEdit,
                                  }) => {
    const [multichoice, setMultichoice] = useState([
        {option: "", checked: false},
    ]);
    const numberOfOptions = 25;

    useEffect(() => {
        if (questionObjectToEdit) {
            questionObjectToEdit.options &&
            setMultichoice(questionObjectToEdit.options);
        }
    }, [questionObjectToEdit]);
    useEffect(() => {
        setAdditionalFields({options: multichoice});
    }, [multichoice]);

    const moveQuestion = (fromIndex, toIndex) => {
        if (fromIndex === toIndex) {
            return;
        }
        reorder(multichoice, fromIndex, toIndex);
    };
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        setMultichoice(result);
        return result;
    };

    if (!shouldDrag)
        return (
            <div>
                {multichoice.map((option, index) => {
                    let value = option.option;
                    return (
                        <Stack
                            direction={"row"}
                            sx={{
                                display: "flex",
                                alignContent: "center",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginBottom: "10px",
                            }}
                        >
                            <PanoramaFishEyeIcon/>
                            <TextField
                                variant={"filled"}
                                value={value}
                                placeholder={`Option ${index + 1}`}
                                sx={{
                                    flex: 1,
                                    backgroundColor: "transparent",
                                    borderRadius: "5px",
                                    padding: "5px 10px",
                                }}
                                className="multiple-choice-option-input-field"
                                autoFocus={true}
                                onChange={(e) => {
                                    console.log("change");
                                    multichoice[index] = {
                                        option: e.target.value,
                                        checked: false,
                                    };
                                    console.log("multichoice");
                                    console.log(multichoice);
                                    value = e.target.value;
                                    setMultichoice([...multichoice]);
                                }}
                            />
                            <IconButton
                                onClick={() => {
                                    console.log(multichoice);
                                    const result1 = multichoice.filter(
                                        (el, filterIndex) => index !== filterIndex,
                                    );
                                    console.log(result1);
                                    setMultichoice([...result1]);
                                }}
                            >
                                <DeleteIcon/>
                            </IconButton>
                        </Stack>
                    );
                })}

                <Button
                    variant={'outlined'}
                    onClick={() => {
                        if (multichoice.length >= numberOfOptions) {
                            toast.error("cannot add more than " + numberOfOptions);
                        } else {
                            setMultichoice([
                                ...multichoice,
                                {
                                    option: "",
                                    checked: false,
                                },
                            ]);
                        }
                    }}
                    disabled={multichoice.length >= 6}
                >
                    <Add/> {multichoice && multichoice.length > 0 ? "Add another option" : "Add option"}
                </Button>

            </div>
        );

    return (
        <div>
            <Typography sx={{fontWeight: "bold", margin: "0 0 10px 0"}}>
                Choices
            </Typography>
            <DragDropContext
                onDragEnd={(result) =>
                    moveQuestion(result.source.index, result.destination.index)
                }
            >
                <Droppable droppableId="multichoice-question-drop">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {multichoice &&
                                multichoice.map((option, index) => {
                                    let value = option;
                                    return (
                                        <Draggable
                                            draggableId={`key-draggable-${index}`}
                                            index={index}
                                            key={`draggable-mch-${index}`}
                                        >
                                            {(provided) => (
                                                <Stack
                                                    direction={"row"}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    sx={{
                                                        display: "flex",
                                                        alignContent: "center",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                        marginBottom: "10px",
                                                    }}
                                                >
                                                    <DragIndicator/>
                                                    <TextField
                                                        variant={"filled"}
                                                        value={value}
                                                        sx={{
                                                            flex: 1,
                                                            backgroundColor: "transparent",
                                                            borderRadius: "5px",
                                                            padding: "5px 10px",
                                                        }}
                                                        className="multiple-choice-option-input-field"
                                                        onFocus={(e) => e.target.select()}
                                                        autoFocus={false}
                                                        onChange={(e) => {
                                                            multichoice[index] = {
                                                                option: e.target.value,
                                                                checked: false,
                                                            };
                                                            value = e.target.value;
                                                            setMultichoice([...multichoice]);
                                                        }}
                                                    />
                                                    <IconButton
                                                        onClick={() => {
                                                            const result1 = multichoice.filter(
                                                                (el, filterIndex) => index !== filterIndex,
                                                            );
                                                            setMultichoice([...result1]);
                                                        }}
                                                    >
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                </Stack>
                                            )}
                                        </Draggable>
                                    );
                                })}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <Button
                variant={'outlined'}
                onClick={() => {
                    setMultichoice([...multichoice, "Option"]);
                }}
                disabled={multichoice.length >= 6}
            >
                <Add/> {multichoice && multichoice.length > 0 ? "Add another option" : "Add option"}
            </Button>
        </div>
    );
};

export default MultipleOptionsDraggable;
