import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import {AppBar, Box, Button, ButtonBase, IconButton, Menu, Stack, Toolbar, Tooltip} from "@mui/material";
import logoImg from "../../assets/logo/Ella.svg";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import {auth} from "../../utils/firebaseApp";

export const Header = ({toggleMenu, Logout, open}) => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <AppBar
            position="fixed"
            color={"inherit"}
            elevation={0}
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                width: "100%",
                display: "block",
                height: "68px"
                // margin: "auto",
            }}
        >
            <Toolbar
                sx={{
                    // padding: "6px 0px 0px 0px",
                    py: 0.5,
                    maxWidth: "1800px",
                    display: "block",
                }}
            >
                <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    sx={{width: "100%"}}
                >
                    <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        // alignContent={"center"}
                        // sx={{visibility: {xs: 'hidden', md: 'visible'}}}
                    >
                        <img src={logoImg} alt={"Logo"} height={60}/>
                    </Stack>
                    {auth.currentUser ? <Box sx={{display: "grid"}}>
                        <Box sx={{m: "auto", display: {xs: "grid", md: "none"}}}>
                            <IconButton
                                sx={{padding: "8px 8px 0px 8px"}}
                                onClick={()=>toggleMenu(!open)}
                            >
                                <MenuIcon/>
                            </IconButton>
                        </Box>
                        <Button
                            component={Link}
                            to={"/create-survey"}
                            sx={{
                                padding: "6px 18px",
                                display: {xs: "none", md: "inline-flex"},
                            }}
                            variant={"contained"}
                            className={"gradient-btn"}
                        >
                            <AddIcon sx={{height: "18px"}}/>
                            <Typography sx={{fontSize: "1em"}}>Create Survey</Typography>
                        </Button>
                       <Tooltip title="Open settings">
                            <ButtonBase
                                onClick={handleOpenUserMenu}
                                sx={{p: 0, mx: 2}}
                            ></ButtonBase>
                        </Tooltip>
                        <Menu
                            sx={{mt: "50px"}}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        ></Menu>
                    </Box> : null}
                </Stack>
            </Toolbar>
        </AppBar>
    );
};


Header.propTypes = {
    logout: PropTypes.func,
    toggleMenu: PropTypes.func,
    open: PropTypes.bool,
};

