import {Chip, Grid, Stack} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {MainLayout} from "../../layout/main/MainLayout";
import {Map} from "react-map-gl";
import Box from "@mui/material/Box";
import {AppContext} from "../../utils/AppContext";
import {enums} from "../../utils/enums";
import PulsingDot from "../../assets/PulsingDot/PulsingDot";
import {CustomMarker} from "../../components/Map/CustomMarker";
import {colors} from "../../components/Map/mapIcons";
import SurveyQuestionSelectorDrodowns from "../../components/shared-components/SurveyQuestionSelectorDrodowns";
import {TitleDate} from "../../components/shared-components/text-formating/title-date/title-date";
import {Responses} from "../project-summaries-page/components/Responses";
import "./react-board.css";

const token = process.env.REACT_APP_MAPBOX_TOKEN;

export const AnalysisPage = () => {
    const { state: {surveys, surveyObjectToDisplay, questionObjects, answerObjects},dispatch} = useContext(AppContext);
    const [activeSurvey, setActiveSurvey] = useState(surveyObjectToDisplay ? surveyObjectToDisplay : surveys[0]);
    const [activeQuestion, setActiveQuestion] = useState(null);
    const [surveyQuestions, setSurveyQuestions] = useState([]);
    const [onlyOriginalQuestions, setOnlyOriginalQuestions] = useState([]);
    const [answersForActiveQuestion, setAnswersForActiveQuestion] = useState([]);
    const [viewport, setViewport] = useState({
        longitude: -74.006,
        latitude: 40.7128,
        zoom: 10,
    });

    useEffect(() => {
        if (!questionObjects) return
        if (!surveys) return

        const surveyQuestions = activeSurvey ? questionObjects?.filter(
            (question) => question?.surveyUid === activeSurvey?.uid
        ) : []
        const onlyOriginalQuestions = surveyQuestions.filter(
            (questionObject) =>
                questionObject?.originalReferenceUid === questionObject?.uid,
        );
        setOnlyOriginalQuestions(onlyOriginalQuestions);
        setActiveQuestion(onlyOriginalQuestions[0])
    }, [activeSurvey, questionObjects])

    // useEffect(() => {
    //     if (!questionObjects) return
    //
    //     if (questionObjects.length === 0) {
    //         dispatch({questionObjects: questionObjectsPersistent})
    //     }
    //
    //     if (!activeSurvey) setActiveSurvey(surveys[0])
    // })
    //
    // useEffect(() => {
    //     if (!activeSurvey) {
    //         setSurveyQuestions([]);
    //         return;
    //     }
    //     const surveyQuestions = questionObjects?.filter(
    //         (question) => question?.surveyUid === activeSurvey?.uid,
    //     );
    //     setSurveyQuestions(surveyQuestions);
    // }, [activeSurvey, questionObjects]);
    //
    // useEffect(() => {
    //     const onlyOriginalQuestions = surveyQuestions.filter(
    //         (questionObject) =>
    //             questionObject?.originalReferenceUid === questionObject?.uid,
    //     );
    //     setOnlyOriginalQuestions(onlyOriginalQuestions);
    //     setActiveQuestion(onlyOriginalQuestions[0])
    // }, [surveyQuestions]);
    //
    //
    useEffect(() => {
        if (!activeQuestion) return

        const answers =
            [...answerObjects.filter((answer) => answer.originalReferenceUid === activeQuestion.uid)]
        setAnswersForActiveQuestion(answers);
    }, [activeQuestion, answerObjects]);


    return (
        <MainLayout>

                <Stack
                  direction={"column"}
                  gap={2}
                  sx={{
                      // 260px is for sidebar drawer and 48 is padding
                      maxWidth: {xs: "calc(100vw - 48px)", md: "calc(100vw - 260px - 48px)"},
                      margin: "20px 0px",
                  }}
                >
                    <TitleDate title="Survey Responses" big/>

                    <SurveyQuestionSelectorDrodowns
                      surveys={surveys}
                      showQuestionSelect={true}
                      questionObjects={onlyOriginalQuestions}
                      setActiveSurvey={setActiveSurvey}
                      activeSurvey={activeSurvey}
                      setActiveQuestion={setActiveQuestion}
                      activeQuestion={activeQuestion}
                    />

                    {activeQuestion && activeQuestion.type === enums.MAPINPUT
                      && (<Box sx={{my: 2}}>
                            <Map
                              mapboxApiAccessToken={token}
                              mapboxAccessToken={token}
                              scrollZoom={true}
                              initialViewState={viewport}
                              style={{width: "100%", height: "auto", minHeight: "400px"}}
                              mapStyle={"mapbox://styles/urosran/clgxw85q1008801qt5gswf9la"}
                              attributionControl={false}
                              position={"top-right"}
                            >
                                {answersForActiveQuestion.length > 0 &&
                                  answersForActiveQuestion?.map((answerObject) =>
                                    answerObject.pois?.map((poi, index) => {
                                        return (
                                          <CustomMarker
                                            key={poi.coordinates.lng + poi.coordinates.lat}
                                            latitude={parseFloat(poi.coordinates.lat)}
                                            longitude={parseFloat(poi.coordinates.lng)}
                                            viewport={viewport}
                                            coords={poi.coordinates}
                                            setViewport={setViewport}
                                            icon={
                                                <PulsingDot
                                                  pulse={false}
                                                  fill={
                                                      colors[
                                                        activeQuestion.options.indexOf(poi.label)
                                                        ]
                                                  }
                                                />
                                            }
                                            locationObject={poi}
                                          />
                                        );
                                    }),
                                  )}
                            </Map>

                            {activeQuestion &&
                              activeQuestion.options.map((option, index) => {
                                  return (
                                    <Chip
                                      label={option}
                                      disabled={false}
                                      name={option}
                                      sx={{
                                          backgroundColor: colors[index],
                                          margin: 1,
                                          color: "white",
                                          // '&:hover': {
                                          //   backgroundColor: selectedChip === option ? colors[index] : "gray"
                                          // }
                                      }}
                                      key={option}
                                      // clickable
                                      // onClick={(e) => {
                                      //   setSelectedChip(e.currentTarget.getAttribute('name'))
                                      // }}
                                    />
                                  );
                              })}
                        </Box>
                      )}
                    <Responses
                      responses={answersForActiveQuestion}
                      question={activeQuestion}
                    />
                </Stack>
        </MainLayout>
    );
};
