import React, {useContext, useState} from "react";
import {Button, Stack,} from "@mui/material";
import Box from "@mui/material/Box";
import {getObjectFromArrayThatMatchesValue,} from "../../../utils/sharedFunctions";
import {enums} from "../../../utils/enums";
import {AppContext} from "../../../utils/AppContext";
import {useNavigate} from "react-router-dom";
import {ShareQrCodeModal} from "../../../components/survey-qrcode/ShareQrCodeModal";

export const Actions = ({ downloadAnswers, activeSurvey }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const {
    state: { surveys, surveyObject, answerObjects, questionObjects },
    dispatch,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const [openSurveyQrCode, setOpenSurveyQrCode] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleEdit(e) {
    console.log("handle edit");
    const surveyUid = e.currentTarget.getAttribute("survey-uid");
    dispatch({
      surveyObject: getObjectFromArrayThatMatchesValue(
        surveys,
        "uid",
        surveyUid,
      ),
    });
    dispatch({ viewToShow: enums.CREATESURVEY });
    dispatch({ edit: true });
    navigate("/");
  }

  return (
    <>
      <Box>
        {/*<Typography sx={{mb: 2, fontSize: {xs: "2em", md: "2.5em", lg: "3em"}}} fontWeight={"bold"}>Green Spaces*/}
        {/*    Committee</Typography>*/}
        {/*<Typography color={"#919191"} sx={{mb: 2}}>*/}
        {/*    Form description Form description Form description Form description Form description Form description Form description Form description Form description Form description Form description Form description*/}
        {/*</Typography>*/}
        <Stack
          direction={"row"}
          gap={2}
          flexWrap={"wrap"}
          justifyContent={"flex-start"}
        >
          {/*<Button variant={"contained"}>*/}
          {/*    View Project Summary*/}
          {/*</Button>*/}
          <Button
            color={"success"}
            disabled={!activeSurvey}
            sx={{
              bgColor: "#7a56ff",
            }}
            className={"button-clean"}
            variant={"contained"}
            survey-uid={activeSurvey.uid}
            onClick={(e) => navigate('/analysis')}
          >
            View Responses
          </Button>
          {/*<Button*/}
          {/*  color={"success"}*/}
          {/*  disabled={!activeSurvey}*/}
          {/*  sx={{*/}
          {/*    bgColor: "#7a56ff",*/}
          {/*  }}*/}
          {/*  className={"button-clean"}*/}
          {/*  variant={"contained"}*/}
          {/*  survey-uid={activeSurvey.uid}*/}
          {/*  onClick={(e) => handleEdit(e)}*/}
          {/*>*/}
          {/*  Edit*/}
          {/*</Button>*/}
          {/*<Button*/}
          {/*  color={"success"}*/}
          {/*  disabled={!activeSurvey}*/}
          {/*  sx={{ bgcolor: "#21DD3F" }}*/}
          {/*  variant={"contained"}*/}
          {/*  onClick={() => navigate("/analysis")}*/}
          {/*>*/}
          {/*  Analyze*/}
          {/*</Button>*/}
          <Button
            color={"primary"}
            disabled={!activeSurvey}
            onClick={() => setOpenSurveyQrCode(true)}
            sx={{ bgcolor: "#2AACB5" }}
            className={"button-clean"}
            variant={"contained"}
          >
            Generate QR & URL
          </Button>
          <Button
            color={"primary"}
            disabled={!activeSurvey}
            variant={"contained"}
            sx={{ bgcolor: "#7a56ff" }}
            className={"button-clean"}
            onClick={() => downloadAnswers()}
          >
            {/*<GetAppIcon/>*/}
            download responses
          </Button>
          {/*<Button color={"error"} disabled={!activeSurvey} variant={"outlined"} survey-uid={activeSurvey.uid}*/}
          {/*        onClick={(e) => deleteSurvey(e, answerObjects, questionObjects, surveys, dispatch, surveyObject)}>*/}
          {/*  delete*/}
          {/*</Button>*/}
        </Stack>
      </Box>

      <ShareQrCodeModal
        open={openSurveyQrCode}
        handleClose={() => setOpenSurveyQrCode(false)}
        urlToDisplay={`${window.location.origin}/survey/${activeSurvey?.uid}`}
        title={activeSurvey.name}
        subtitle={"By sharing this QR code, you are sharing the survey with the public."}
      />
    </>
  );
};
