import React, { useContext, useEffect, useState } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import { AppContext } from "../../utils/AppContext";
import { enums } from "../../utils/enums";
import MapIcon from "@mui/icons-material/Map";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import MicIcon from "@mui/icons-material/Mic";
import ListIcon from "@mui/icons-material/List";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import uuid from "react-uuid";
import _ from "lodash";
import {
  addUpdateQuestionToDb,
  getObjectFromArrayThatMatchesValue,
  sortArrayByNumericObjectProperty,
} from "../../utils/sharedFunctions";
import { toast } from "react-toastify";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../utils/firebaseApp";
import {
  ArrowDropDownCircleOutlined,
  CheckBox,
  DragIndicator,
  Edit,
  LinearScale,
  MyLocationOutlined,
} from "@mui/icons-material";
import { Draggable } from "react-beautiful-dnd";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ArticleIcon from "@mui/icons-material/Article";
import { confirm } from "react-confirm-box";
import { confirmDialogOptions } from "../../components/ConfirmDialog";
import DeleteIcon from "./../../assets/icons/trash.png";

const ListOfQuestions = ({ activeLanguage, setOpenAddQuestionModal }) => {
  const {state: { questionObjects, surveyObject }, dispatch,} = useContext(AppContext);
  const [currentSurveyQuestions, setCurrentSurveyQuestions] = useState([]);

  useEffect(() => {
    setCurrentSurveyQuestions(
      sortArrayByNumericObjectProperty(
        questionObjects.filter(
          (question) =>
            question.surveyUid === surveyObject.uid &&
            question.languageObject.Name === activeLanguage.Name,
        ),
        "orderNumber",
      ),
    );
  }, [questionObjects, activeLanguage]);

  useEffect(() => {
    questionObjects.filter((question) => {
      return (
        question.surveyUid === surveyObject.uid &&
        question.languageObject.Name === activeLanguage.Name
      );
    });
  }, [activeLanguage]);

  function deduceIcon(type) {
    switch (type) {
      case enums.TEXT:
        return (
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="flex-end"
            gap="10px"
            className="gray-text"
            sx={{ fontSize: "14px", minWidth: "130px" }}
          >
            {" "}
            <FormatColorTextIcon /> Text Entry
          </Stack>
        );
      case enums.VOICE:
        return (
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="flex-end"
            gap="10px"
            className="gray-text"
            sx={{ fontSize: "14px", minWidth: "130px" }}
          >
            {" "}
            <MicIcon /> Voice{" "}
          </Stack>
        );
      case enums.MULTICHOICE:
        return (
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="flex-end"
            gap="10px"
            className="gray-text"
            sx={{ fontSize: "14px", minWidth: "130px" }}
          >
            {" "}
            <ListIcon /> Multiple choice{" "}
          </Stack>
        );
      case enums.MAPINPUT:
        return (
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="flex-end"
            gap="10px"
            className="gray-text"
            sx={{ fontSize: "14px", minWidth: "130px" }}
          >
            {" "}
            <MapIcon /> Map Input{" "}
          </Stack>
        );
      case enums.IMAGE:
        return (
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="flex-end"
            gap="10px"
            className="gray-text"
            sx={{ fontSize: "14px", minWidth: "130px" }}
          >
            {" "}
            <AddPhotoAlternateIcon /> Image{" "}
          </Stack>
        );
      case enums.SECTION:
        return (
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="flex-end"
            gap="10px"
            className="gray-text"
            sx={{ fontSize: "14px", minWidth: "130px" }}
          >
            {" "}
            <ArticleIcon /> Section{" "}
          </Stack>
        );
      case enums.LOCATE_USER:
        return (
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="flex-end"
            gap="10px"
            className="gray-text"
            sx={{ fontSize: "14px", minWidth: "130px" }}
          >
            {" "}
            <MyLocationOutlined /> Geolocate user{" "}
          </Stack>
        );
      case enums.DROPDOWN:
        return (
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="flex-end"
            gap="10px"
            className="gray-text"
            sx={{ fontSize: "14px", minWidth: "130px" }}
          >
            {" "}
            <ArrowDropDownCircleOutlined /> Dropdown{" "}
          </Stack>
        );
      case enums.SLIDER:
        return (
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="flex-end"
            gap="10px"
            className="gray-text"
            sx={{ fontSize: "14px", minWidth: "130px" }}
          >
            {" "}
            <LinearScale /> Slider{" "}
          </Stack>
        );
      case enums.CHECKBOXES:
        return (
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="flex-end"
            gap="10px"
            className="gray-text"
            sx={{ fontSize: "14px", minWidth: "130px" }}
          >
            {" "}
            <CheckBox /> Checkboxes{" "}
          </Stack>
        );
      default:
        throw new Error("Do not have a type for this question type");
    }
  }

  async function duplicateQuestion(e) {
    let questionObjectNew = _.cloneDeep(
      questionObjects.filter(
        (question) =>
          question.uid === e.currentTarget.getAttribute("question-uid"),
      )[0],
    );
    const uid = uuid();
    questionObjectNew.uid = uid;
    questionObjectNew.originalReferenceUid = uid;
    questionObjectNew.orderNumber = questionObjects.filter(
      (question) => question.surveyUid === surveyObject.uid,
    ).length;
    try {
      await addUpdateQuestionToDb(questionObjectNew);
      dispatch({ questionObjects: [...questionObjects, questionObjectNew] });
      toast.success("Question copied!");
    } catch (e) {
      toast.error("Something went wrong!");
    }
  }

  function editQuestion(e) {
    const questionToEditUid = e.currentTarget.getAttribute("question-uid");
    dispatch({
      questionObjectToEdit: getObjectFromArrayThatMatchesValue(
        questionObjects,
        "uid",
        questionToEditUid,
      ),
    });
    setOpenAddQuestionModal(true);
  }

  async function deleteQuestion(e) {
    const questionUidToDelete = e.currentTarget.getAttribute("question-uid");
    const originalRefUid = e.currentTarget.getAttribute("original-ref-uid");
    const response = await confirm(
      {
        title: `Are you sure you want to delete this question?`,
        subtitle: `This action will delete all questions in other languages that were created as a translation of this one.`,
      },
      confirmDialogOptions,
    );
    if (response === false) {
      return;
    }
    const questionsToDelete = questionObjects.filter(
      (question) =>
        question.originalReferenceUid === questionUidToDelete ||
        question.uid === questionUidToDelete ||
        question.originalReferenceUid === originalRefUid,
    );
    const questionsTemp = questionObjects.filter(
      (question) => question.originalReferenceUid !== originalRefUid,
    );
    try {
      for (let questionsToDeleteElement of questionsToDelete) {
        await deleteDoc(doc(db, "questions", questionsToDeleteElement.uid));
      }
      dispatch({ questionObjects: [...questionsTemp] });
      toast.success("Question deleted!");
    } catch (e) {
      toast.error("Something went wrong!");
    }
  }

  return (
    <div
      id={"all-questions-container"}
      style={{
        gap: "15px",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        alignItems: "stretch",
      }}
    >
      {currentSurveyQuestions.map((questionObject, index) => {
        return (
          <Draggable
            draggableId={`key-draggable-${index}`}
            style={{ background: "#FFF", gap: "11px" }}
            index={index}
            key={`draggable-questions-${index}`}
          >
            {(provided) => (
              <Stack
                direction="row"
                alignItems={"center"}
                gap="20px"
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <DragIndicator />
                <Stack
                  id={"edit-this-for-each-question-block"}
                  className={"list-of-questions"}
                  direction={"column"}
                  gap="10px"
                  sx={{
                    flex: 1,
                    display: "flex",
                    alignContent: "center",
                    padding: "20px 30px",
                    border: "1px solid #FEFEFF",
                    borderRadius: "10px",
                    background: "#FEFEFF",
                    marginTop: "10px",
                    marginBottom: "10px",
                    // maxWidth: "800px",
                    width: "-webkit-fill-available",
                    boxShadow: "0 0px 7px rgb(0 0 0 / 0.08)",
                  }}
                  key={questionObject.uid}
                >
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    gap="20px"
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Typography className="number">
                        <span>{index + 1}</span>
                      </Typography>
                      <Typography className="question">
                        {questionObject.question}
                      </Typography>
                    </div>
                    {deduceIcon(questionObject.type)}
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    justifyContent={"flex-end"}
                  >
                    <IconButton
                      question-uid={questionObject.uid}
                      onClick={(e) => editQuestion(e)}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      question-uid={questionObject.uid}
                      onClick={(e) => duplicateQuestion(e)}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                    <IconButton
                      question-uid={questionObject.uid}
                      original-ref-uid={questionObject.originalReferenceUid}
                      onClick={(e) => deleteQuestion(e)}
                    >
                      <img
                        src={DeleteIcon}
                        alt={"delete"}
                        style={{ height: "22px" }}
                      />
                    </IconButton>
                  </Stack>
                  {/* <Divider sx={{margin: 0, marginTop: "10px"}}/> */}
                </Stack>
              </Stack>
            )}
          </Draggable>
        );
      })}
      {/*<AddQuestionToSurveyModal setOpenAddQuestionModal={setOpenAddQuestionModal}*/}
      {/*                          openModal={openAddQuestionModal}*/}
      {/*/>*/}
    </div>
  );
};

export default ListOfQuestions;
