import React, { createContext, useEffect, useState } from "react";

export const InterfaceContext = createContext();

const InterfaceContextProvider = ({ children }) => {
    const [responsiveNavVisible, setResponsiveNavVisible] = useState(false);

	return (
		<InterfaceContext.Provider value={{responsiveNavVisible, setResponsiveNavVisible}}>
			{ children }
		</InterfaceContext.Provider>
	)
}

export default InterfaceContextProvider;