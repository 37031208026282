import React, {useContext} from 'react';
import {AppContext} from "../../utils/AppContext";
import {enums} from "../../utils/enums";
import {AnalysisPage} from "../analysis-page/analysis-page";
import {ProjectSummariesPage} from "../project-summaries-page/project-summaries-page";
import {CreateSurvey} from "../create-project-page/create-survey";
import {DashboardPage} from "../snapshot-page/dashboard-page";
import {Surveys} from "../projects-list-page/surveys";
import config from "../../config";

export const MainPage = () => {

  const {state: {viewToShow}, dispatch} = useContext(AppContext);

  switch (viewToShow) {
    case enums.DASHBOARD:
      return <DashboardPage projectsList={config.projectsList}/>
    case enums.SURVEYS:
      return <Surveys/>
    case enums.SUMMARIES:
      return <ProjectSummariesPage/>
    case enums.ANALYSIS:
      return <AnalysisPage/>
    case enums.CREATESURVEY:
      return <CreateSurvey/>
    default:
      return <DashboardPage/>
  }
}
