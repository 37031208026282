import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {Avatar, Button, Stack, Typography} from "@mui/material";
import ReactApexChart from "react-apexcharts";
import ReactWordcloud from "react-wordcloud";
import {calculateWordFrequency, calculateWordFrequencyAndReturnAsObject} from "../../../../utils/sharedFunctions";
import {blue} from "@mui/material/colors";
import {CardItem} from "../../../../components/card/CardItem";
import {SwitchButtons} from "../../../../components/switch-buttons/SwitchButtons";
import {Link, useNavigate} from "react-router-dom";
import {ColumnChart} from "./ColumnChart";


const GraphTypes = {WORDCLOUD: "wordcloud", BAR: "bar"}

const AutoType = {
    AUTO: "word cloud",
    MANUAL: "topics"
}

export const VoiceQuestionGraph = ({
                                       question,
                                       answers,
                                       title,
                                       subtitle,
                                       index,
                                       setOpenTopicsModal,
                                       setActiveQuestion,
                                       secondaryAction
                                   }) => {
    const [type, setType] = useState(GraphTypes.WORDCLOUD);
    const [autoType, setAutoType] = useState(AutoType.AUTO);
    const [topicsCounts, setTopicsCounts] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        if (!answers) return
        console.log('answers')
        console.log(answers)
        const topicsCounts = {}
        answers.forEach(answer => {
            if (answer.topics) {
                answer.topics.forEach(topic => {
                        topicsCounts[topic] = topicsCounts[topic] ? topicsCounts[topic] + 1 : 1
                    }
                )
            }
        })
        console.log('topicsCounts')
        console.log(topicsCounts)
        setTopicsCounts(topicsCounts)
    }, [answers, question])

    return (
        <CardItem
            title={<Typography fontSize={16} fontWeight={"bold"}>{title}</Typography>}
            subheader={<Typography fontSize={12} variant={"secondary2"}>{subtitle}</Typography>}
            key={question.uid}
            secondary={secondaryAction ? secondaryAction(question.uid) : null}
        >
            <Stack sx={{flexDirection: {xs: "column", md: "row"}, my: 1, height: "100%"}} justifyContent={"center"}
                   alignItems={"start"}
                   gap={2}>
                <Stack direction={"column"} gap={2} sx={{minWidth: "250px"}}>
                    <SwitchButtons
                        title={'Classification'}
                        firstType={AutoType.AUTO}
                        secondType={AutoType.MANUAL}
                        onChange={(newType) => setAutoType(newType)}
                        selectedType={autoType}
                    />
                </Stack>
                <Box sx={{flex: 1}}>
                    {(type === GraphTypes.WORDCLOUD
                            && autoType === AutoType.AUTO)
                        && <WordCloudChart question={question} answers={answers}/>
                    }

                    {autoType === AutoType.MANUAL && !topicsCounts &&
                        <Button
                            onClick={() => {
                                navigate('analysis-page')
                            }}
                            sx={{textAlign: 'center', width: '100%', display: 'block'}} variant={'text'}>
                            Set the topics with AI
                        </Button>
                    }
                    {autoType === AutoType.MANUAL
                        && topicsCounts
                        && <ColumnChart open={autoType === AutoType.MANUAL} options={topicsCounts}/>
                    }
                </Box>
            </Stack>
        </CardItem>
    )
}

const WordCloudChart = ({answers, question}) => {
    const [words, setWords] = useState([])

    useEffect(() => {
      if (answers && answers?.length && question) {
        const sausageString = answers.map(answer => answer.text).join(' ')
        const wordFrequencies = calculateWordFrequencyAndReturnAsObject(sausageString)
        setWords(wordFrequencies)
      }
    }, [answers, question]);
    // map over array of answer objects and join them into a string based on text key

    return (
        <ReactWordcloud
            words={words}
            options={{
                rotations: 0,
                fontSizes: [10, 60],
            }}
        />
    )
}
