import {RecordVoiceOver, Stop} from '@mui/icons-material';
import React, {useState} from 'react';
import {ColoredButton} from '../../../../components/shared-components/colored-button/colored-button';
import VoiceActive from '../../../../assets/recording/recording.gif';
import VoiceInactive from '../../../../assets/recording/notRecording.gif';
import Checkmark from '../../../../assets/recording/checkmark.png';

export const AudioQuestionForm = ({number, questionText}) => {
  const [voiceRecordingActive, setVoiceRecordingActive] = useState(false);
  const [doneRecording, setDoneRecording] = useState(false);
  return (
    <div className='audio-question-form flex column a-center jc-sb'>
      <div className='audio-question-question'>
        <p><span className='accent'>{number}.</span>{questionText}</p>
      </div>
      <div className="audio-question-toolbar">
        <div className='audio-question-mic-wrap flex row a-center jc-center'>
          <div className='audio-question-mic'>
            {voiceRecordingActive ?
              <img src={VoiceActive} alt='voice-active'/> :
              doneRecording ? <img src={Checkmark} alt="done-recording"/> :
                <img src={VoiceInactive} alt='voice-notactive'/>
            }
          </div>
        </div>
        <div className='audio-question-toolbar-buttons flex row a-center jc-sb'>
          <ColoredButton
            icon={<RecordVoiceOver/>}
            color="#3DE76D"
            width="115px"
            height="40px"
            onClick={() => setVoiceRecordingActive(true)}/>
          <ColoredButton
            disabled={!voiceRecordingActive}
            icon={<Stop/>}
            color="#656565"
            width="98px"
            height="40px"
            onClick={() => {
              setVoiceRecordingActive(false);
              setDoneRecording(true);
              console.log(doneRecording)
            }}/>
        </div>
      </div>
    </div>
  )
}
