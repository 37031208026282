import React from 'react';
import {Grid, Typography} from "@mui/material";

const ThankYouPage = () => {
  return (
    <Grid container direction={'column'} sx={{minHeight: '100vh', height: '100vh'}} justifyItems={'center'}
          justifyContent={'center'} alignItems={'center'} alignContent={'center'}>
      <Grid item>
        <Typography variant={'h2'}>The survey has been submitted! Thank you!</Typography>
      </Grid>
    </Grid>
  );
};

export default ThankYouPage;
