import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import React from 'react';


export const CheckButton = ({checked, onUnCheck, onCheck, className, title}) => {

    return(
        <Stack direction={'row'}  
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        className={`check-button ${className && className}`}>

            {checked ?  
                <CheckBox className='clickable' onClick={() => onUnCheck()}/> : 
                <CheckBoxOutlineBlank  className='clickable' onClick={() => onCheck()}/>
            }
            <Typography variant={"body1"}>
                {title}
            </Typography>
            
        </Stack>
    )
}