import {enums} from "./enums";
import {
  ArrowDropDownCircleOutlined,
  CheckBox,
  ControlPointDuplicate,
  Image,
  Input, MyLocationOutlined,
  KeyboardVoice,
  LinearScale,
  LocationOn, ViewStream
} from "@mui/icons-material";


export const questionItems = [
  {
    value: enums.TEXT,
    name: "Text",
    icon: Input
  },
  {
    value: enums.VOICE,
    name: "Voice",
    icon: KeyboardVoice
  },
  {
    value: enums.MULTICHOICE,
    name: "Multiple Choice",
    icon: ControlPointDuplicate
  },
  {
    value: enums.CHECKBOXES,
    name: "Checkboxes",
    icon: CheckBox
  },
  {
    value: enums.SLIDER,
    name: "Slider",
    icon: LinearScale
  },
  {
    value: enums.DROPDOWN,
    name: "Dropdown",
    icon: ArrowDropDownCircleOutlined
  },
  {
    value: enums.MAPINPUT,
    name: "Map Input",
    icon: LocationOn
  },
  {
    value: enums.IMAGE,
    name: "Image Answer",
    icon: Image
  },
  {
    value: enums.SECTION,
    name: "Section",
    icon: ViewStream
  },
  {
    value: enums.LOCATE_USER,
    name: "Geolocate User",
    icon: MyLocationOutlined
  }
];
