import React, { useContext, useEffect, useState } from "react";
import { TitleDate } from "../../components/shared-components/text-formating/title-date/title-date";
import { AppContext } from "../../utils/AppContext";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { MainLayout } from "../../layout/main/MainLayout";
import Box from "@mui/material/Box";
import { ProjectItem } from "./components/project-list-item/ProjectItem";
import { green } from "@mui/material/colors";
import {
  getQuestionsFromDB,
  sortArrayByNumericObjectProperty,
} from "../../utils/sharedFunctions";
import { auth } from "../../utils/firebaseApp";
import { useLocation } from "react-router-dom";
import SurveyList from "../../components/shared-components/SurveyList";

export const Surveys = () => {
  const [viewAllProjects, setViewAllProjects] = useState(false);
  const {
    state: { surveys },
    dispatch,
  } = useContext(AppContext);
  const shortProjectsList = surveys.slice(0, 10);
  const location = useLocation();

  useEffect(() => {
    getQuestionsFromDB(auth.currentUser).then((questionObjects) => {
      dispatch({ questionObjects: questionObjects });
    });
  }, [location.pathname]);

  return (
    <MainLayout>
      <Grid container sx={{ paddingBottom: "100px" }}>
        <Grid xs={12} md={12} lg={10} sx={{ mx: "auto" }}>
          <SurveyList
            surveys={surveys}
            viewAllProjects={viewAllProjects}
            setViewAllProjects={setViewAllProjects}
          />
        </Grid>
      </Grid>
    </MainLayout>
  );
};
