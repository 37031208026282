import {Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {TitleDate} from "../../components/shared-components/text-formating/title-date/title-date";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PropTypes from "prop-types";
import {AppContext} from "../../utils/AppContext";
import {useLocation, useNavigate} from "react-router-dom";
import {MainLayout} from "../../layout/main/MainLayout";
import {TotalParticipantChart} from "../project-summaries-page/components/TotalParticipantChart";
import {countDistinctObjectsByDay, getQuestionsFromDB,} from "../../utils/sharedFunctions";
import Grid from "@mui/material/Grid";
import {auth} from "../../utils/firebaseApp";
import SurveyList from "../../components/shared-components/SurveyList";

export const DashboardPage = () => {
  const {
    state: { answerObjects, surveys, viewToShow },
    dispatch,
  } = useContext(AppContext);
  const archivedProjects = surveys.filter(
    (project) => project.archived === true,
  );
  const archivedProjectsShort = surveys
    .filter((survey) => survey.archived)
    .slice(0, 2);
  const activeProjects = surveys.filter(
    (project) => project.archived === false,
  );
  const activeProjectsShort = activeProjects.slice(0, 1);
  const [viewAllArchived, setViewAllArchived] = useState(false);
  const [viewAllActive, setViewAllActive] = useState(false);
  const navigate = useNavigate();
  console.log(answerObjects);

  const [viewAllProjects, setViewAllProjects] = useState(false);
  const shortProjectsList = surveys.slice(0, 10);
  const location = useLocation();

  useEffect(() => {
    getQuestionsFromDB(auth.currentUser).then((questionObjects) => {
      dispatch({ questionObjects: questionObjects });
    });
  }, [location.pathname]);

  return (
    <MainLayout>
      <Grid
        container
        id={"snapshot-page-container"}
        sx={{ paddingBottom: "100px" }}
      >
        <Grid xs={12} md={12} lg={10} sx={{ mx: "auto" }}>
          <TitleDate title="Snapshot" date={Date.now()} big={true} />
          {/*<TotalParticipantChart name={"Total # of respondents for all surveys"}*/}
          {/*                       xAxisLabels={Object.keys(countDistinctObjectsByDay(answerObjects, 'timestamp'))}*/}
          {/*                       xAxisData={Object.values(countDistinctObjectsByDay(answerObjects, 'timestamp'))}/>*/}
          <Typography variant={"h4"} sx={{ margin: "45px 0px 24px 0px" }}>
            Total Participants Over Time
          </Typography>
          <TotalParticipantChart
            xAxisLabels={Object.keys(
              countDistinctObjectsByDay(
                [
                  ...new Map(
                    answerObjects
                      .sort((a, b) => a.timestamp - b.timestamp)
                      .map((item) => [item["respondentUid"], item]),
                  ).values(),
                ],
                "timestamp",
              ),
            )}
            xAxisData={Object.values(
              countDistinctObjectsByDay(
                [
                  ...new Map(
                    answerObjects
                      .sort((a, b) => a.timestamp - b.timestamp)
                      .map((item) => [item["respondentUid"], item]),
                  ).values(),
                ],
                "timestamp",
              ),
            )}
          />
          <Grid sx={{ marginTop: "90px" }}>
            <SurveyList
              surveys={surveys}
              viewAllProjects={viewAllProjects}
              setViewAllProjects={setViewAllProjects}
            />
          </Grid>
          {/*<TitleInfo*/}
          {/*  title="Currently Open Surveys"*/}
          {/*  info="Currently open surveys"*/}
          {/*/>*/}
          {/*{viewAllActive ? (*/}
          {/*  <div className="scrollable-projects-box">*/}
          {/*    {surveys.map((survey, index) => (*/}
          {/*      <ProjectInfoBox*/}
          {/*        key={`archived-project-${index}`}*/}
          {/*        project={survey}*/}
          {/*      />*/}
          {/*    ))}*/}
          {/*  </div>*/}
          {/*) : (*/}
          {/*  activeProjectsShort.map((project, index) => (*/}
          {/*    <ProjectInfoBox*/}
          {/*      key={`archived-project-${index}`}*/}
          {/*      project={project}*/}
          {/*    />*/}
          {/*  ))*/}
          {/*)}*/}
          {/*<div className="center-button flex row a-center jc-center">*/}
          {/*  {!viewAllActive && surveys.length > 4 && (*/}
          {/*    <Button*/}
          {/*      className="view-more"*/}
          {/*      variant="contained"*/}
          {/*      onClick={() => setViewAllActive(true)}*/}
          {/*    >*/}
          {/*      View More*/}
          {/*    </Button>*/}
          {/*  )}*/}
          {/*  {viewAllActive && (*/}
          {/*    <Button*/}
          {/*      className="view-more"*/}
          {/*      variant="contained"*/}
          {/*      onClick={() => setViewAllActive(false)}*/}
          {/*    >*/}
          {/*      View Less*/}
          {/*    </Button>*/}
          {/*  )}*/}
          {/*</div>*/}
          {/*<DoughnutChart title='Responses All Time'*/}
          {/*               data={[projectsOverview.completeResponses, projectsOverview.incompleteResponses]}/>*/}
          {/*<div>*/}
          {/*    <TitleInfo title='Archived Projects' info="Archived Projects"/>*/}

          {/*    {viewAllArchived ?*/}
          {/*        <div className='scrollable-projects-box'>*/}
          {/*            {archivedProjects.map((project, index) => (*/}
          {/*                <ProjectInfoBox key={`archived-project-${index}`} project={project}/>*/}
          {/*            ))}*/}
          {/*        </div>*/}
          {/*        :*/}
          {/*        archivedProjectsShort.map((project, index) => (*/}
          {/*            <ProjectInfoBox key={`archived-project-${index}`} project={project}/>*/}
          {/*        ))}*/}
          {/*</div>*/}
          {/*<div className='center-button flex row a-center jc-center'>*/}
          {/*    {!viewAllArchived &&*/}
          {/*        <Button className='view-more' variant="contained"*/}
          {/*                onClick={() => setViewAllArchived(true)}>View*/}
          {/*            More</Button>}*/}
          {/*    {viewAllArchived &&*/}
          {/*        <Button className='view-more' variant="contained"*/}
          {/*                onClick={() => setViewAllArchived(false)}>View*/}
          {/*            Less</Button>}*/}
          {/*</div>*/}
        </Grid>
      </Grid>
    </MainLayout>
  );
};

DashboardPage.propTypes = {
  projectsList: PropTypes.any,
};
