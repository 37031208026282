import {Add, Close} from '@mui/icons-material';
import {Button, Chip, Grid, Input, Stack} from '@mui/material';
import React, {useRef, useState} from 'react';


export const ChipTags = ({tags, clickable, inputActive, onAddChip, onDeleteSingle, onDeleteAll, onDeleteCustom, placeholder}) => {
    const [selectedChip, setSelectedChip] = useState(null);
    const [chipTagsCreated, setChipTagsCreated] = useState(tags || []);
    const inputRef = useRef(null);

    return(
      <div className='chip-tags-wrap'>
        {inputActive && <Stack direction="row" alignItems='center' justifyContent={"flex-start"}>
            <Input className='chip-input' inputRef={inputRef} onChange={(e) => inputRef.current =  e.target.value} placeholder={placeholder}/> 
            <Button className='chip-add-button' onClick={() =>{setChipTagsCreated([...chipTagsCreated,inputRef.current])}}><Add className='chip-add-icon'/> Add</Button>
        </Stack>
        }
       <Grid item sx={{display: 'flex', flexDirection: "row", flexWrap: 'wrap'}} xs={1}>
       {chipTagsCreated && chipTagsCreated.length > 0 && chipTagsCreated.map((option, index) => {
        console.log(option);
        return <Chip 
                    label={option} 
                    disabled={false} 
                    name={option}
                     sx={{
                       backgroundColor: selectedChip === option ? "#ECECEF" : "whitesmoke",
                       margin: 1,
                       color: 'gray'
                     }}
                     key={option}
                     clickable={clickable && clickable}
                     onClick={(e) => {
                       setSelectedChip(e.currentTarget.getAttribute('name'))
                     }}
                     deleteIcon={<Close />}
                     onDelete={onDeleteSingle ? () =>  setChipTagsCreated(chipTagsCreated.filter((item, i) => i !== index)) : 
                              onDeleteAll ? () =>  setChipTagsCreated(chipTagsCreated.filter((item) => item !== option)) :
                              onDeleteCustom && onDeleteCustom() }
                     />
      })}
      </Grid>
      </div>
    )
}