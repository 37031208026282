import React from 'react';
import './style.css'

const PulsingDot = ({fill, pulse}) => {
  return (
    <svg width="15" height="15" viewBox="0 0 30 30">
      {pulse ?
        <>
          <circle id="ring" r="12" cx="15" cy="15" fill="#03b1fc"/>
          <circle id="dot" r="12" cx="15" cy="15" fill={fill}/>
        </>
        :
        <circle id="dot-no-pulse" r="12" cx="15" cy="15" fill={fill}/>
      }
    </svg>
  );
};

export default PulsingDot;
