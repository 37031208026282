import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme} from "@mui/material";

export const confirmDialogOptions = {
    render: (message, onConfirm, onCancel) => {
        return (
            <Dialog
                open={message}
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant={'h5'}>{message.title}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant={'body1'}>{message?.subtitle}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant={"outlined"}  onClick={onCancel}>Close</Button>
                    <Button variant={"contained"} sx={{backgroundColor: '#09D92A'}} onClick={onConfirm} autoFocus>Submit</Button>
                </DialogActions>
            </Dialog>
        );
    }
}
