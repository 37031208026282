import {Button} from '@mui/material';
import React from 'react';


export const ColoredButton = ({label, props, color, width, height, fontSize, icon, onClick, disabled = false, maxWidth}) => {

  return (
    <Button
      className={`colored-button-wrap`}
      variant="contained"
      style={{backgroundColor: `${color}`, width: `${width}`, height: `${height}`, fontSize: `${fontSize}`, maxWidth: `${maxWidth}`}}
      onClick={(e) => onClick(e)}
      disabled={disabled}
      {...props}
    >
      {label}{icon && icon}
    </Button>
  )
}
