export const enums = {
  DASHBOARD: "dashboard",
  SURVEYS: "surveys",
  SUMMARIES: "summaries",
  ANALYSIS: "analysis",
  CREATESURVEY: "createSurvey",
  serverUrl: "",
  YES: "YES",
  NO: "NO",
  TEXT: 'text',
  VOICE: 'voice',
  MULTICHOICE: 'multichoice',
  MAPINPUT: 'mapInput',
  IMAGE: "image",
  SECTION: "section",
  LOCATE_USER: 'locateuser',
  DROPDOWN: 'dropdown',
  CHECKBOXES: 'checkboxes',
  SLIDER: 'slider',
  LANGUAGESELECT: 'languageselect',
  LANETITLE: "Unclassified Words"
}
