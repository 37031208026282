import { useEffect } from 'react';

const useImagePreloader = (imagePaths) => {
    useEffect(() => {
        const preloadImages = () => {
            imagePaths.forEach((path) => {
                const img = new Image();
                img.src = path;
            });
        };

        preloadImages();
    }, [imagePaths]);
};

export default useImagePreloader;
